// import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'styled-components';
import { Amplify } from '@aws-amplify/core';

// aws export
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import awsExports from './aws-exports.js';

// css imports
// TODO: remove this and use styled components
import './assets/css/fonts.css';
import './assets/css/index.css';
import './assets/css/App.css';

import APP_CONSTANTS from './utils/constants/app.constants';
import { GlobalTheme, GlobalStyle } from './Styles';
import './config/i18n';
import { store } from './store/Store';
import App from './App';
import { Auth } from 'aws-amplify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { IsLocalHost } from './utils/helpers/isLocalHost';
import { RecoilRoot } from 'recoil';

// --------------------- sentry ------------------------
if (!IsLocalHost()) {
  Sentry.init({
    dsn: 'https://630c9fcda3834c8bab3d1869900cd527@o1072557.ingest.sentry.io/6071599',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    environment: APP_CONSTANTS.ENVIRONNEMENT,
    // eslint-disable-next-line no-undef
    release: import.meta.env.VITE_VERSION,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.2,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
}

// --------------------- amplify ------------------------
// ALLOW local mocking of subscriptions to work (see: https://github.com/aws-amplify/amplify-category-api/issues/122)
if (import.meta.env.VITE_USE_API_MOCK == 'true') {
  console.warn('/// Using API mock server ///');
  awsExports.aws_appsync_graphqlEndpoint =
    'http://localhost:3333/api_local/graphql';
}

Amplify.configure(awsExports);

// ---------------------token refresh-------------------
Auth.configure({
  oauth: {
    scope: ['email', 'openid'],
    refreshHandlers: {
      // Automatically refresh the token 5 minutes before it expires
      [awsExports.oauth.redirectSignIn]: async () => {
        console.log('refreshing token ?');
        const refreshedSession = await Auth.currentSession();
        return {
          accessToken: refreshedSession.getAccessToken().getJwtToken(),
          idToken: refreshedSession.getIdToken().getJwtToken(),
          refreshToken: refreshedSession.getRefreshToken().getToken(),
          expiresIn: refreshedSession.getAccessToken().payload.exp,
        };
      },
    },
  },
});

const queryClient = new QueryClient();

// --------------------- render ------------------------

createRoot(document.getElementById('root') as HTMLElement).render(
  // TODO: strictmode must be disabled to avoid warnings from Antd Animation.
  // We must wait for antd to upgrade and remove the use of "findByNode", or we can migrate all antd components to another library.
  // <StrictMode>
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <GlobalStyle />
        <ThemeProvider theme={GlobalTheme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  </RecoilRoot>
  // </StrictMode>
);
