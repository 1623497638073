import useOnScreen from '@/utils/hooks/useOnScreen';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Flex, useCounter } from '@chakra-ui/react';
import { useRef, useEffect } from 'react';

const buttonSlides = Array.from({ length: 100 }, (_, i) => i + 1);
const isInViewMode = false;
const hasPatientCase = true;

export default function TestPage() {
  const counter = useCounter({
    defaultValue: 0,
  });
  const slideIndex = counter.value as number;

  const containerRef = useRef<HTMLDivElement>(null);
  const firstSlideBtnRef = useRef<HTMLButtonElement>(null);
  const lastSlideBtnRef = useRef<HTMLButtonElement>(null);
  const isFirstSlideBtnVisible = useOnScreen(firstSlideBtnRef);
  const isLastSlideBtnVisible = useOnScreen(lastSlideBtnRef);

  const onHomeClick = () => {
    counter.setValue(0);
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      //NOTE: Button width + the gap between buttons
      const buttonWidth = 50 + 8;

      const containerWidth = containerRef.current.offsetWidth;
      const offset = containerWidth / 2 - buttonWidth / 2;
      const target = slideIndex * buttonWidth - offset;

      containerRef.current.scrollTo({
        left: target,
        behavior: 'smooth',
      });
    }
  }, [slideIndex]);

  return (
    <Flex
      alignItems="center"
      overflowX="auto"
      width="full"
      maxWidth="100%"
      bg="red"
    >
      <Flex alignItems="center" overflow="clip">
        <Flex gap="8px">
          {!isInViewMode && (
            <Button
              variant="slideNavigator"
              onClick={() => {
                onHomeClick();
              }}
            >
              <HomeOutlined />
            </Button>
          )}
          {hasPatientCase && (
            <Button
              variant={
                slideIndex === 0 ? 'slideNavigatorSelected' : 'slideNavigator'
              }
              flex="1 1 auto"
              data-test="nav_item_patient_case"
              onClick={() => {
                counter.setValue(0);
              }}
              minWidth="50px"
            >
              <UserOutlined />
            </Button>
          )}
        </Flex>
        <Flex position="relative" overflowX="hidden">
          <Flex
            ref={containerRef}
            padding="10px"
            alignItems="center"
            gap="8px"
            overflowX="hidden"
            overflowY="hidden"
            data-test="slide-navigator"
            w="full"
            sx={{
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                bottom: 0,
                width: 10,
                zIndex: 1,
                backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.0) 100%)`,
                left: 0,
                pointerEvents: 'none',
                opacity: isFirstSlideBtnVisible ? 0 : 1,
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                width: 10,
                zIndex: 1,
                backgroundImage: `linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.0) 100%)`,
                pointerEvents: 'none',
                opacity: isLastSlideBtnVisible ? 0 : 1,
              },
            }}
          >
            {buttonSlides.map((buttonName, index) => {
              if (hasPatientCase && index === 0) return null;
              return (
                <Button
                  ref={
                    index === (hasPatientCase ? 1 : 0)
                      ? firstSlideBtnRef
                      : index === buttonSlides.length - 1
                      ? lastSlideBtnRef
                      : null
                  }
                  key={`${buttonName}_${
                    index === slideIndex ? 'selected' : ''
                  }`}
                  variant={
                    index === slideIndex
                      ? 'slideNavigatorSelected'
                      : 'slideNavigator'
                  }
                  flex="1 1 auto"
                  data-test={`nav_item_${index}`}
                  onClick={() => {
                    counter.setValue(index);
                  }}
                  minWidth="50px"
                >
                  {hasPatientCase && index !== 0 && index}
                  {!hasPatientCase && index + 1}
                </Button>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
      <Flex paddingX="10px">
        <Button variant="primary">Example</Button>
        <Button variant="primary">Example</Button>
      </Flex>
    </Flex>
  );
}
