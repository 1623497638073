import { createGlobalStyle } from 'styled-components';

// media queries for devices
// see: https://jsramblings.com/how-to-use-media-queries-with-styled-components/
// as we are desiging for desktop first (no mobile first in this project)
// we use the max-width instead of the min-width
export const MediaDevice = {
  mobile: '(max-width: 768px)',
  tablet: '(max-width: 1024px)',
  laptop: '(max-width: 1440px)',
  desktop: '(max-width: 6000px})',
};

// --------------------- Theme values ------------------------

export const GlobalColors = {
  orange: '#FF7F68',
  purple_light: '#F3F5FF',
  dark_grey: '#404040',
  purple_grey: '#7A7687',
  selection: '#FF7F68',
  dark_bg: 'grey',
  blue: '#5863ED',
  blue_title: '#4E3D81',
  green: '#219653',
};

export const GlobalTheme = {
  // fonts
  fontDefault: 'nunito-bold',
  fontTitle: 'poppins-bold',
  fontButton: 'poppins-bold',
  fontDescription: 'nunito-bold',
  bg: 'white',

  components: {
    button: {
      color: '#000000',
      bgColor: GlobalColors.orange,
      borderRadius: '5px',
      _hover: {
        bgColor: GlobalColors.blue,
      },
    },
  },

  // colors
  // TODO: clean this and put in Chakra Theme!
  color_purple_light: '#F3F5FF',
  color_dark_grey: '#404040',
  color_purple_grey: '#7A7687',
  color_selection: '#FF7F68',
  color_dark_bg: 'grey',
  color_description: '#7A7687',
  color_title_blue: '#4E3D81',
  color_button_orange: '#FF7F68',
  color_green: '#219653',

  liveMeetingBarHeight: '50px',
  liveMeetingBarHostColor: 'rgb(214, 218, 255)',
};

// --------------------- Global style ------------------------

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
 
  #root {
    margin: 0 auto;
  }
 
  html {
    
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;    
  }

  .crispImage{
    image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering:   -o-crisp-edges;         /* Opera */
    image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

  .noclick {
    pointer-events: none;
  }

  .nodrag {
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }


  .blueshadow {
    -webkit-box-shadow: 0px 2px 10px 2px rgba(0, 4, 255, 0.05);  
     -moz-box-shadow: 0px 2px 10px 2px rgba(0, 4, 255, 0.05);  
          box-shadow: 0px 2px 10px 2px rgba(0, 4, 255, 0.05);  
  }

  .lightshadow {
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
     -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
`;
