import { SlideItemType } from '@/utils/types/enums';
import { z } from 'zod';

export const textAnswerSlideItemSchema = z.object({
  id: z.string(),
  type: z.literal(SlideItemType.ANSWER_TEXT),
  title: z.string(),
  placeholder: z.string(),
  maxChars: z.number().positive(),
  assetRefs: z.array(z.string()),

  //Optional
  description: z.string().optional(),
});

export type TextAnswerSlideItemType = z.infer<typeof textAnswerSlideItemSchema>;
