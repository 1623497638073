import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from '@/ui/Layout/PageLayout';
import { ROUTES } from '@/routes/Routes';
import UserListView from './UserListView';
import CaseListView from './CaseListView';
import MeetingCreationView from './AdminMeetingListPage';
import CaseClusterListView from './CaseClusterListView';
import Sidebar from '@/pages/admin/components/sidebar';
import CaseEditPage from './CaseEditPage';
import { PageLoading } from '@/ui/PageLoading';
import ClientsView from './ClientsView';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';

const AdminPage = () => {
  const { user } = useUserAuthenticationContext();

  // loading user
  if (!user) {
    return <PageLoading />;
  }

  return (
    <Layout>
      <Sidebar>
        <Routes>
          <Route
            path={ROUTES.ADMIN_USER_LIST.replace('admin/', '')}
            element={<UserListView />}
          />
          <Route
            path={ROUTES.ADMIN_CLIENTS.replace('admin/', '')}
            element={<ClientsView />}
          />
          <Route
            path={ROUTES.ADMIN_CASE_LIST.replace('admin/', '')}
            element={<CaseListView />}
          />
          <Route
            path={ROUTES.ADMIN_CASE_EDIT.replace('admin/', '')}
            element={<CaseEditPage />}
          />
          <Route
            path={ROUTES.ADMIN_MEETING_LIST.replace('admin/', '')}
            element={<MeetingCreationView />}
          />
          <Route
            path={ROUTES.ADMIN_CASE_CLUSTER_LIST.replace('admin/', '')}
            element={<CaseClusterListView />}
          />
          {/* handle default index => users */}
          <Route index element={<Navigate to={ROUTES.ADMIN_MEETING_LIST} />} />
        </Routes>
      </Sidebar>
    </Layout>
  );
};

export default AdminPage;
