import { CaptureSentryMessage } from '@/utils/helpers/CaptureSentryException';
import { NewIAsset } from '@/utils/types/zod/assetSchema';

export function getAssetByRefOrThrow(
  assetID: string,
  assets: NewIAsset[]
): NewIAsset {
  const asset = assets.find((asset) => asset.id === assetID);
  if (!asset) {
    CaptureSentryMessage(
      `getAssetByRefOrThrow: Asset with ID ${assetID} not found`
    );
    throw new Error(`Asset with ID ${assetID} not found`);
  }
  return asset;
}

export function getAssetListByRefOrThrow(
  assetIDList: string[],
  assets: NewIAsset[]
): NewIAsset[] {
  const assetList = assets.filter((asset) => assetIDList.includes(asset.id));
  //check if all assets are found
  if (assetList.length !== assetIDList.length) {
    CaptureSentryMessage(`getAssetListByRefOrThrow: Not all assets found`);
    throw new Error(`Not all assets found`);
  }
  assetList.sort(
    (a, b) => assetIDList.indexOf(a.id) - assetIDList.indexOf(b.id)
  );
  return assetList;
}
