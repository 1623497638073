import APP_CONSTANTS from '@/utils/constants/app.constants';
import { ZodError } from 'zod';
import { AssetType } from '../types/enums';
import { NewIAsset } from '../types/zod/assetSchema';
import {
  NewIPatientCase,
  newIPatientCaseSchema,
} from '../types/zod/patientCaseSchema';
import { CaptureSentryMessage } from './CaptureSentryException';

export const parseCaseAssets = (
  caseContent: NewIPatientCase
): NewIPatientCase => {
  const caseAssetsMap: { [assetId: string]: NewIAsset } = {};

  caseContent.assets.forEach((asset) => {
    const casepath = APP_CONSTANTS.PATIENT_CASES_REPO_PATH + caseContent.path;

    asset.url = casepath + asset.path;

    switch (asset.type) {
      case AssetType.IMAGE:
        asset.thumbUrl = asset.url;
        break;
      case AssetType.VIDEO:
        asset.thumbUrl = casepath + asset.thumbPath;
        break;
    }
    if (asset.thumbUrl.includes('mp4')) {
      console.warn(
        `WARN: (${caseContent.id}) Asset with "${asset.id}" has a thumb with .mp4 extension, please use .png or .jpg`
      );
    }
    caseAssetsMap[asset.id] = { ...asset };
  });

  /*
  NOTE: validate if the case is well formatted
  */
  try {
    newIPatientCaseSchema.parse(caseContent);
  } catch (err) {
    if (err instanceof ZodError) {
      CaptureSentryMessage(
        'PatientCase validation failed while fetching caseData'
      );
      console.error(`PatientCase validation failed: ${caseContent.id} ${err}`);
    }
  }
  return caseContent;
};
