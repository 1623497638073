import { SlideItemType } from '@/utils/types/enums';
import { z } from 'zod';

export const infoSlideItemSchema = z.object({
  type: z.literal(SlideItemType.INFO),
  assetRefs: z.array(z.string()),

  //Optional
  //Note: title is optional because of case 0010_IPSEN_MELIANTHE_20230531T082413Z Slide 4
  title: z.string().optional(),
  description: z.string().optional(),
});

export type InfoSlideItemType = z.infer<typeof infoSlideItemSchema>;
