import { useEffect, useMemo, useState } from 'react';
import { userMeetingAccessByMeetingID } from '@/services/graphql/queries';
import {
  createUserMeetingAccess,
  deleteUserMeetingAccess,
} from '@/services/graphql/mutations';
import {
  onCreateUserMeetingAccess,
  onDeleteUserMeetingAccess,
} from '@/services/graphql/subscriptions';
import {
  CreateUserMeetingAccessInput,
  DeleteUserMeetingAccessInput,
  OnCreateUserMeetingAccessSubscription,
  OnCreateUserMeetingAccessSubscriptionVariables,
  OnDeleteUserMeetingAccessSubscription,
  UserMeetingAccess,
  UserMeetingAccessByMeetingIDQuery,
  UserMeetingAccessByMeetingIDQueryVariables,
} from '@/services/API';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import useNewSubscriptions from '../subscriptions/useNewSubscriptions';
import { CaptureSentryException } from '@/utils/helpers/CaptureSentryException';

const useMeetingUserAccessForMeeting = (meetingId: string | undefined) => {
  // --------------------- state ------------------------
  const [isLoading, setIsLoading] = useState(true);
  const [userAccessList, setUserAccessList] = useState<UserMeetingAccess[]>([]);

  // --------------------- handlers ------------------------

  const refetch = async () => {
    if (!meetingId) return;
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      const variables: UserMeetingAccessByMeetingIDQueryVariables = {
        meetingID: meetingId,
      };
      const result = await callGraphQLApi<
        GraphQLResult<UserMeetingAccessByMeetingIDQuery>
      >(userMeetingAccessByMeetingID, variables);
      const userAccessList = result.data?.userMeetingAccessByMeetingID?.items;
      if (userAccessList) {
        setUserAccessList(userAccessList as UserMeetingAccess[]);
      }
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  const getUserMeetingAccessId = (userId: string) => {
    return userAccessList?.find((item) => item.userID == userId)?.id;
  };

  const updateUserAccess = async (userId: string, value: boolean) => {
    if (!meetingId) {
      CaptureSentryException('meetingId is undefined in updateUserAccess');
      return;
    }
    // case give access
    if (value) {
      const input: CreateUserMeetingAccessInput = {
        meetingID: meetingId,
        userID: userId,
      };
      try {
        await callGraphQLApi(createUserMeetingAccess, {
          input,
        });
      } catch (error) {
        console.warn(error);
      }
    }
    // case remove access
    else {
      const userMeetingAccessId = getUserMeetingAccessId(userId);
      if (userMeetingAccessId) {
        const input: DeleteUserMeetingAccessInput = {
          id: userMeetingAccessId,
        };
        try {
          await callGraphQLApi(deleteUserMeetingAccess, {
            input,
          });
        } catch (error) {
          console.warn(error);
        }
      }
    }
  };

  // --------------------- effect ------------------------

  const subscriptions = useMemo(() => {
    if (!meetingId) return [];
    const onCreateVariables: OnCreateUserMeetingAccessSubscriptionVariables = {
      // filter: {
      //   meetingID: {
      //     eq: meetingId,
      //   },
      // },
    };
    return [
      {
        query: onCreateUserMeetingAccess,
        variables: onCreateVariables,
        callback: (response: OnCreateUserMeetingAccessSubscription) => {
          const item = response.onCreateUserMeetingAccess;
          if (item && item.meetingID === meetingId) {
            setUserAccessList((prev) => [...prev, item as UserMeetingAccess]);
          }
        },
      },
      {
        query: onDeleteUserMeetingAccess,
        //NOTE: I'm just using the same variable
        variables: onCreateVariables,
        callback: (response: OnDeleteUserMeetingAccessSubscription) => {
          const item = response.onDeleteUserMeetingAccess;
          if (item && item.meetingID === meetingId) {
            setUserAccessList((prev) =>
              prev.filter((elem) => elem.id !== item.id)
            );
          }
        },
      },
    ];
  }, [meetingId]);

  useNewSubscriptions(subscriptions, 'useMeetingUserAccessForMeeting');

  useEffect(() => {
    setIsLoading(true);
    refetch();
  }, [meetingId]);

  // --------------------- return ------------------------

  return { isLoading, userAccessList, updateUserAccess };
};

export default useMeetingUserAccessForMeeting;
