import { DisclaimerType } from '@/utils/types/enums';
import { IDisclaimer } from '@/utils/types/zod/disclaimerTypes/disclaimerSchema';
import { Flex } from '@chakra-ui/layout';
import PoweredByDisclaimer from './PoweredByDisclaimer';
import ImageDisclaimer from './ImageDisclaimer';

type Props = {
  assetPath: string;
  disclaimer: IDisclaimer;
};

function CaseDisclaimerView({ disclaimer, assetPath }: Props) {
  // image disclaimer will take full screen size
  if (disclaimer.type === DisclaimerType.IMAGE) {
    return (
      <Flex
        top="0"
        left="0"
        right="0"
        position="fixed"
        zIndex="100"
        background="white"
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <ImageDisclaimer disclaimer={disclaimer} assetPath={assetPath} />
      </Flex>
    );
  }

  // other type of disclaimer (powered by, etc.)
  return (
    <Flex
      position="relative"
      background="white"
      height="100%"
      width="100%"
      padding="50px 30px"
      alignItems="center"
    >
      <Flex
        position="relative"
        width="100%"
        height="100%"
        flexDirection="column"
        className="content"
        alignItems="center"
      >
        {disclaimer.type === DisclaimerType.POWERED_BY && (
          <PoweredByDisclaimer disclaimer={disclaimer} assetPath={assetPath} />
        )}
      </Flex>
    </Flex>
  );
}

export default CaseDisclaimerView;
