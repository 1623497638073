import { AppDispatch } from '@/store/Store';
import { UpdateMeetingInput } from '@/services/API';
import { actions } from '@/store/slices/meeting';
import { updateMeeting } from '@/services/graphql/mutations';
import { callGraphQLApi } from '@/utils/graphQLAPI';

export function updateAMeeting(input: UpdateMeetingInput) {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setStatus('updating current slide'));
    try {
      await callGraphQLApi(updateMeeting, { input });
    } catch (error) {
      console.error(`error at updateAMeeting:`);
      dispatch(actions.setError(error));
    }
  };
}
