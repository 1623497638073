import { DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import useCaseClusterList from '@/features/caseCluster/hooks/useCaseClusterList';
import getNonDeletableMeetings from '@/features/meeting/helpers/getNonDeletableMeetings';
import { MeetingSelectors } from '@/store/slices/meeting';
import useMeetingSubscription from '@/services/subscriptions/useMeetingSubscription';
import CreateMeetingForm from '@/pages/admin/components/CreateMeetingForm/CreateMeetingForm';
import { deleteAMeeting } from '@/store/thunk/meeting';

import {
  sortAdminMeetingList,
  sortingOptionsData,
} from '@/utils/helpers/admin/sortAdminMeetingList';
import {
  Button as ChakraButton,
  chakra,
  Modal,
  Grid,
  GridItem,
  Flex,
  Container,
  HStack,
  Input,
  Heading,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  Box,
  Switch,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/store/StoreHooks';
import MeetingList from '@/pages/admin/components/MeetingList/MeetingList';
import { Meeting } from '@/services/API';
import DropDownButton from '@/ui/DropDownButton';
import UserAccessList from '@/pages/admin/components/UserAccessList/UserAccessList';
import { useUserList } from '@/services/hooks/useUserListByMeeting';
import PopConfirm from '@/ui/PopConfirm/PopConfirm';
import { PageLoading } from '@/ui/PageLoading';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { ROUTES } from '@/routes/Routes';
import { useNavigate } from 'react-router-dom';
import useDoStatsExistForMeetings from '@/features/meeting/answer/hooks/useDoStatsExistForMeetings';

type sortingOptionsType =
  | 'caseClusterAscending'
  | 'caseClusterDescending'
  | 'hostAscending'
  | 'hostDescending'
  | 'meetingAscending'
  | 'meetingDescending'
  | 'dateDescending';

function MeetingCreationView() {
  const navigate = useNavigate();
  let meetingList = useAppSelector(MeetingSelectors.getMeetingList);
  const caseClusterlist = useCaseClusterList();
  const userList = useUserList();
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const { user } = useUserAuthenticationContext();
  const dispatch = useAppDispatch();

  const nonDeletableMeetings = getNonDeletableMeetings(meetingList);
  const { t } = useTranslation();
  const [showMeetingForm, setShowMeetingForm] = useState<boolean>(false);

  const [isDupingMeeting, setIsDupingMeeting] = useState<boolean>(false);
  const [meetingData, setMeetingData] = useState<Meeting | undefined>(
    undefined
  );
  const [searchFilter, setSearchFilter] = useState<string | undefined>(
    undefined
  );

  const [sortingType, setSortingType] =
    useState<sortingOptionsType>('dateDescending');
  const [accessFormMeetingID, setAccessFormMeetingID] = useState<
    Meeting | undefined
  >(undefined);

  // usesubscription to be sure everything is updated once updated on backend
  useMeetingSubscription();

  const statsExistForMeeting = useDoStatsExistForMeetings(meetingList);

  //archiving
  if (!showArchived) {
    meetingList = meetingList.filter((meeting) => !meeting.isArchived);
  }

  // filter + sort meeting
  const searchFilterMeetingList = searchFilter
    ? meetingList.filter((item) =>
        item.name.toLowerCase().includes(searchFilter.toLowerCase())
      )
    : meetingList;
  const sortMeetinglist = sortAdminMeetingList(sortingType, [
    ...searchFilterMeetingList,
  ]);

  // --------------------- handlers ------------------------

  const handleDeleteMeeting = (meetingID: string) => {
    dispatch(deleteAMeeting(meetingID));
  };

  const handleFilterValue = (value: sortingOptionsType) => {
    setSortingType(value);
  };

  const handleDupeMeeting = (meeting: Meeting) => {
    setShowMeetingForm(true);
    setMeetingData(meeting);
    setIsDupingMeeting(true);
  };

  const handleEditMeeting = (data: Meeting) => {
    setShowMeetingForm(true);
    setMeetingData(data);
  };

  const handleCloseForm = () => {
    setMeetingData(undefined);
    setIsDupingMeeting(false);
    setShowMeetingForm(false);
  };

  const handleMeetingStats = (meetingID: string) => {
    navigate(`${ROUTES.MEETING_STATS}/${meetingID}`);
  };

  // --------------------- render ------------------------

  if (!user || !caseClusterlist) {
    return <PageLoading />;
  }
  return (
    <Grid templateColumns="repeat(1, 1fr)" gap={1}>
      <GridItem w="100%">
        <Flex justifyContent="space-between" margin="10px 10px">
          <chakra.h3 fontSize="2xl" fontWeight="bold" textAlign="center">
            Meetings
          </chakra.h3>

          <ChakraButton
            background="blue.400"
            color="white"
            size="md"
            data-test="create-meeting-button-popUp"
            onClick={() => setShowMeetingForm(true)}
          >
            {t('admin.meeting.button.createnewMeeting')}
          </ChakraButton>

          <Modal isOpen={showMeetingForm} onClose={() => handleCloseForm()}>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalBody>
                <Box p="20px" gap="10px">
                  <Heading size="sm" pb="5">
                    {t(
                      meetingData?.name
                        ? isDupingMeeting
                          ? 'admin.meeting.button.duplicateMeeting'
                          : 'admin.meeting.button.updateMeeting'
                        : 'admin.meeting.button.createnewMeeting'
                    )}
                  </Heading>

                  <CreateMeetingForm
                    user={user}
                    userList={userList}
                    caseClusterlist={caseClusterlist}
                    closeMeetingForm={() => handleCloseForm()}
                    meeting={meetingData}
                    isDuplicateMeeting={isDupingMeeting}
                  />
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Flex>

        <HStack>
          <DropDownButton
            title="Sort Meetings"
            value={sortingType}
            options={sortingOptionsData}
            handleFilterValue={handleFilterValue}
          />
          <Input
            w="50%"
            placeholder="Search meeting..."
            size="sm"
            onChange={(target) => setSearchFilter(target.currentTarget.value)}
          />
          <Flex
            direction="row"
            userSelect="none"
            bgColor="gray.200"
            p="10px"
            borderRadius="20px"
            align="center"
            gap="5px"
            h="fit-content"
            whiteSpace="nowrap"
          >
            <Switch
              size="lg"
              defaultChecked={showArchived}
              checked={showArchived}
              onChange={(e) => setShowArchived(e.currentTarget.checked)}
            />
            <Text display="inline-block">Show Archived meetings</Text>
          </Flex>
        </HStack>

        <UserAccessList
          meeting={accessFormMeetingID}
          onClose={() => {
            setAccessFormMeetingID(undefined);
          }}
        />

        {meetingList && meetingList.length > 0 && (
          <Container maxW="full" p={{ base: 3, md: 3 }}>
            <MeetingList
              showMailDomain
              showGuestUrl
              showPreviewCode
              showID
              data={sortMeetinglist}
              actions={[
                {
                  render: (meeting: Meeting) => (
                    <>
                      {statsExistForMeeting[meeting.id] && (
                        <ChakraButton
                          variant="blue"
                          size="sm"
                          title="View meeting stats"
                          onClick={() => handleMeetingStats(meeting.id)}
                        >
                          {t('admin.meetings.list.viewStats')}
                        </ChakraButton>
                      )}

                      <ChakraButton
                        variant="blue"
                        size="sm"
                        title="Duplicate meeting"
                        onClick={() => handleDupeMeeting(meeting)}
                      >
                        {t('admin.meetings.list.duplicate')}
                      </ChakraButton>
                      <ChakraButton
                        variant="solid"
                        size="sm"
                        rightIcon={<UserOutlined />}
                        title="Edit user access"
                        onClick={() => setAccessFormMeetingID(meeting)}
                        isDisabled={meeting.isArchived}
                      >
                        {t('admin.meetings.list.userAccess')}
                      </ChakraButton>
                      <ChakraButton
                        variant="light"
                        size="sm"
                        rightIcon={<EditOutlined />}
                        title="Edit"
                        onClick={() => handleEditMeeting(meeting)}
                        isDisabled={meeting.isArchived}
                      >
                        {t('common.edit')}
                      </ChakraButton>
                      <PopConfirm
                        title={
                          <div>
                            {t('admin.meeting.delete.confirmText')}
                            <br />
                            {t('meeting.header.confirmRestart')}
                          </div>
                        }
                        onConfirm={() => handleDeleteMeeting(meeting.id)}
                        okText={t('common.yes') as string}
                        cancelText={t('common.no') as string}
                      >
                        <ChakraButton
                          variant="danger"
                          key="delete"
                          size="sm"
                          isDisabled={nonDeletableMeetings.includes(meeting.id)}
                          rightIcon={<DeleteOutlined />}
                        >
                          {t('common.delete')}
                        </ChakraButton>
                      </PopConfirm>
                    </>
                  ),
                },
              ]}
            />
          </Container>
        )}
      </GridItem>
    </Grid>
  );
}

export default MeetingCreationView;
