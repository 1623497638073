import InfoSlide from './InfoSlide';
import AnamneseSlide from './AnamneseSlide';
import isSlideQuestionSlide from '@/utils/types/type-validation/isSlideQuestionSlide';
import QuestionSlide from './QuestionSlide/QuestionSlide';
import QuestionContextProvider from './QuestionSlide/context/QuestionContext';
import { NewISlide } from '@/utils/types/zod/slideTypes/slideSchema';
import { Box, Text } from '@chakra-ui/react';
import { CustomSlides, SlideType } from '@/utils/types/enums';
import useSlideVariations from './hooks/useSlideVariation';
import { AppSpinner } from '@/ui/AppSpinner';
import LorealSkinExpertSlide from '@/customSlides/loreal/SkinExpertSlide/LorealSkinExpertSlide';
import CustomSlide2 from '@/customSlides/loreal/CustomSlide2/CustomSlide2';
import CustomSlide3 from '@/customSlides/loreal/CustomSlide3/CustomSlide3';
import LorealPromoterStats from '@/customSlides/loreal/LorealPromoterStats/LorealPromoterStats';
import ImageSlide from './ImageSlide';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';

type Props = {
  caseIndex: number;
  isHost: boolean;
  slide: NewISlide;
  slideIndex: number;
  onShowAssets: (assetRefs: string[]) => void;
  isInViewMode?: boolean;
};

const Container = ({ children }: { children: React.ReactNode }) => (
  <Box
    height="100%"
    overflow="hidden"
    backgroundColor="transparent"
    position="relative"
  >
    {children}
  </Box>
);

const CaseSlide = ({
  slide,
  caseIndex,
  onShowAssets,
  isHost,
  isInViewMode,
  slideIndex,
}: Props) => {
  const { slideHasVariations, isLoading, slideVariation } =
    useSlideVariations(slide);
  const { currentCase } = useCurrentMeetingDataContext();

  if (slideHasVariations && isLoading) {
    console.log('Loading slide variations');
    return <AppSpinner />;
  }

  if (slideHasVariations && !isLoading && slideVariation) {
    return (
      <CaseSlide
        key={slideVariation.variationId}
        isHost={isHost}
        caseIndex={caseIndex}
        slide={slideVariation.variation}
        slideIndex={slideIndex}
        onShowAssets={onShowAssets}
        isInViewMode={isInViewMode}
      />
    );
  }

  if (!slide) return null;
  if (!currentCase) {
    console.warn('CaseSlide: currentCase is undefined');
    return null;
  }
  return (
    <Container>
      {slide.type === SlideType.NORMAL && (
        <InfoSlide slide={slide} onShowAssets={onShowAssets} />
      )}
      {slide.type === SlideType.ANAMNESE && (
        <AnamneseSlide slide={slide} caseIndex={caseIndex} />
      )}
      {slide.type === SlideType.IMAGE && (
        <ImageSlide slide={slide} casePath={currentCase?.path} />
      )}
      {/* Question & Custom slides */}
      {(isSlideQuestionSlide(slide) || slide.type === SlideType.CUSTOM) && (
        <QuestionContextProvider
          slideIndex={slideIndex}
          isPreview={isInViewMode ?? false}
          slide={slide}
        >
          {isSlideQuestionSlide(slide) && (
            <QuestionSlide
              slide={slide}
              isPreview={isInViewMode ?? false}
              isHost={isHost}
              onShowAssets={onShowAssets}
            />
          )}
          {slide.type === SlideType.CUSTOM &&
            slide.customSlide === CustomSlides.LOREAL_DRAG_AND_DROP && (
              <Container>
                <LorealSkinExpertSlide slide={slide} />
              </Container>
            )}
          {slide.type === SlideType.CUSTOM &&
            slide.customSlide === CustomSlides.LOREAL_CUSTOM_2 && (
              <Container>
                <CustomSlide2 slide={slide} />
              </Container>
            )}
          {slide.type === SlideType.CUSTOM &&
            slide.customSlide === CustomSlides.LOREAL_CUSTOM_3 && (
              <Container>
                <CustomSlide3 slide={slide} idx={0} />
              </Container>
            )}
          {slide.type === SlideType.CUSTOM &&
            slide.customSlide === CustomSlides.LOREAL_CUSTOM_4 && (
              <Container>
                <CustomSlide3 slide={slide} idx={1} />
              </Container>
            )}
          {slide.type === SlideType.CUSTOM &&
            slide.customSlide === CustomSlides.LOREAL_CUSTOM_5 && (
              <Container>
                <CustomSlide3 slide={slide} idx={2} />
              </Container>
            )}
          {slide.type === SlideType.CUSTOM &&
            slide.customSlide === CustomSlides.LOREAL_AS_PROMOTER_STATS && (
              <Container>
                <LorealPromoterStats />
              </Container>
            )}
        </QuestionContextProvider>
      )}

      <Text
        position="absolute"
        bottom={1}
        right={2}
        fontSize="xs"
        color="gray.300"
      >
        {slide.id}
      </Text>
    </Container>
  );
};

export default CaseSlide;
