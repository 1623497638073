import { z } from 'zod';
import { topRightObjectSchema } from '../topRightObjectSchema';

export const baseSlideSchema = z.object({
  id: z.string(),
  title: z.string(),
  libraryRefs: z.array(z.string()),
  variationIds: z.array(z.string()),

  numCols: z.number().int().positive().optional(),
  topRight: topRightObjectSchema.optional(),
  hidePatientProfile: z.boolean().optional(),
  hideSupportedDocuments: z.boolean().optional(),
});
