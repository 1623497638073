import { UnloadedIPatientCase } from '@/utils/types/zod/patientCaseSchema';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import useCasePreviewLinks from './hooks/useCasePreviewLinks';
import { AppSpinner } from '@/ui/AppSpinner';
import { useForm } from 'react-hook-form';
import createNewPreviewCaseAccessLink from './utils/createNewPreviewCaseAccessLink';
import Table from '@/ui/Table';
import { Column } from 'react-table';
import { AccessLink } from '@/services/API';
import { PreviewCaseAccessLinkPayload } from '@/utils/types/types';
import { FiMoreVertical } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import { FaCopy } from 'react-icons/fa';
import { ROUTES } from '@/routes/Routes';
import { useTranslation } from 'react-i18next';
import mutationDeleteAccessLink from './utils/mutationDeleteAccesslink';

type Props = {
  disclosure: ReturnType<typeof useDisclosure>;
  patientCase: UnloadedIPatientCase;
};

type FormData = {
  validFor: number;
};

function formatTimeLeft(targetDate: Date): string {
  if (targetDate.getTime() === 0) {
    return 'Indefinite';
  }

  const currentDate = new Date();
  const timeDifference = targetDate.getTime() - currentDate.getTime();

  if (timeDifference <= 0) {
    return 'Expired';
  }

  // Calculate days, hours, and minutes
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  // Format the result
  let result = '';
  if (days > 0) {
    result += `${days} day${days > 1 ? 's' : ''}, `;
  }
  if (hours > 0) {
    result += `${hours} hour${hours > 1 ? 's' : ''}, `;
  }
  if (minutes > 0) {
    result += `${minutes} minute${minutes > 1 ? 's' : ''}`;
  }

  return result;
}

export default function CasePreviewLinkModal({
  disclosure,
  patientCase,
}: Props) {
  const links = useCasePreviewLinks(patientCase.id);
  const numberOfLinks = links.data?.length || 0;
  const toast = useToast();
  const { t } = useTranslation();

  const { register, handleSubmit, formState } = useForm<FormData>();

  const handleCopy = (link: AccessLink) => {
    const text = `${window.location.origin}${ROUTES.PREVIEW_CASE}/${link.accessCode}`;
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: t('textCopier.title'),
        description: t('textCopier.description').replace('{text}', text),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    });
  };

  const handleDelete = (link: AccessLink) => {
    mutationDeleteAccessLink(link.id)
      .then(() => {
        links.refetch();
        toast({
          title: 'Link deleted',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: 'Error deleting link',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const onSubmit = async (values: FormData) => {
    const payload = {
      caseId: patientCase.id,
      validUntil:
        values.validFor == 0
          ? 0
          : new Date().getTime() + values.validFor * 1000,
    };

    createNewPreviewCaseAccessLink(payload)
      .then(() => {
        links.refetch();
        toast({
          title: 'Link created',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: 'Error creating link',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const columns: Column<AccessLink>[] = [
    {
      Header: 'Valid for',
      accessor: (row) => {
        const data: PreviewCaseAccessLinkPayload = JSON.parse(row.data || '{}');
        return <Text>{formatTimeLeft(new Date(data.validUntil))}</Text>;
      },
    },
    {
      Header: 'Manage',
      accessor: (row) => {
        return (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<FiMoreVertical />}
              variant="outline"
            />
            <MenuList>
              <MenuItem
                icon={<FaCopy size="12px" />}
                onClick={() => handleCopy(row)}
              >
                {' '}
                Copy
              </MenuItem>
              <MenuDivider />
              <MenuItem
                icon={<MdDelete size="16px" />}
                onClick={() => {
                  handleDelete(row);
                }}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        );
      },
    },
  ];

  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Preview links</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody pb={6}>
            <VStack gap="10px" pb="10px">
              <FormControl>
                <FormLabel>Case</FormLabel>
                <Select disabled={true}>
                  <option value={patientCase.id}>{patientCase.id}</option>
                </Select>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={Boolean(formState.errors.validFor)}
              >
                <FormLabel>Valid for</FormLabel>
                <Select
                  {...register('validFor', { required: false })}
                  defaultValue={86400}
                >
                  <option value={86400}>24h</option>
                  <option value={172800}>48h</option>
                  <option value={259200}>72h</option>
                  <option value={604800}>1w</option>
                  <option value={0}>Indefinite</option>
                </Select>
              </FormControl>
            </VStack>

            <Divider my={4} />

            <FormLabel>Links</FormLabel>

            <Box maxHeight="200px" overflow="auto">
              {links.isLoading && <AppSpinner />}
              {!links.isLoading && numberOfLinks === 0 && (
                <Text width="100%" textAlign="center">
                  No links found
                </Text>
              )}
              {!links.isLoading && numberOfLinks > 0 && (
                <Table columns={columns} data={links.data ?? []} size="sm" />
              )}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="primary"
              mr={3}
              type="submit"
              disabled={!formState.isValid}
            >
              Create
            </Button>
            <Button onClick={disclosure.onClose}>Exit</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
