import PageLayout from '@/ui/Layout/PageLayout';
import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import clearAllMockingData from '@/features/meeting-stats/mocking-tool/general/clearAllMockingData';
import { startMock } from '@/features/meeting-stats/mocking-tool';

function AdminTestPage() {
  return (
    <PageLayout>
      <Flex alignItems="center" justifyContent="center" gap="20px">
        <VStack maxWidth="300px">
          <Text fontSize="30px" color="livelinx.purple200">
            Meeting mocking
          </Text>
          <Button
            width="full"
            variant="primary"
            onClick={() => startMock(true)}
          >
            Create a new mock meeting
          </Button>

          <Button width="full" variant="primary" onClick={clearAllMockingData}>
            Delete mocking data
          </Button>
        </VStack>
      </Flex>
    </PageLayout>
  );
}

export default AdminTestPage;
