/* eslint-disable @typescript-eslint/no-explicit-any */

import { User } from '@/services/API';
import { useTranslation } from 'react-i18next';
import { useUserList } from '../../services/hooks/useUserListByMeeting';
import Table from '@/ui/Table';
import { Column, Row } from 'react-table';
import {
  Button as ChakraButton,
  Tag,
  Text,
  Input,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Heading,
  Flex,
  Switch,
  Divider,
} from '@chakra-ui/react';
import { useState } from 'react';
import { parseDate } from '@/utils/helpers/parseDate';
import { EditOutlined } from '@ant-design/icons';
import ManageUserClientsView from './components/MangeUserClientsView/ManageUserClientsView';
import ClientTag from '@/components/ClientTag/ClientTag';
import useUserListClients from '@/services/hooks/useUserListClients';
import { NULL_ID_VALUE } from '@/utils/constants/app.constants';
import UserMeetingAccessView from './components/UserMeetingAccessView/UserMeetingAccessView';

import { MOCK_USER_PREFIX } from '@/features/meeting-stats/mocking-tool/mockUser/users.create';

function UserListView() {
  const { t } = useTranslation();
  const [showTempUser, setShowTempUser] = useState(false);

  const userList: User[] = useUserList()
    .filter((el) => !el.email.startsWith(MOCK_USER_PREFIX))
    .filter((el) => !(!showTempUser && el.isTemporary))
    .map((el) => {
      return {
        ...el,
        last_seen: el.last_seen ?? el.createdAt,
      };
    });

  const [searchQuery, setSearchQuery] = useState<string>('');
  const searchResults = searchQuery
    ? userList.filter((item) =>
        item.firstname.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : userList;
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { userListToClientNames } = useUserListClients();

  // --------------------- handlers ------------------------

  const handleDateSort = (
    firstRow: Row<User>,
    secondRow: Row<User>,
    key: 'last_seen' | 'createdAt'
  ): number => {
    const firstRowLastSeen = new Date(
      firstRow.original[key] as string
    ).getTime();
    const secondRowLastSeen = new Date(
      secondRow.original[key] as string
    ).getTime();
    if (firstRowLastSeen < secondRowLastSeen) return 1;
    else if (firstRowLastSeen > secondRowLastSeen) return -1;
    return 0;
  };

  const handleActiveMeetingsSort = (
    firstRow: Row<User>,
    secondRow: Row<User>
  ) => {
    const firstRowActiveMeeting = firstRow.original.currentMeetingID;
    const secondRowActiveMeeting = secondRow.original.currentMeetingID;
    if (firstRowActiveMeeting == null) return 1;
    if (secondRowActiveMeeting == null) return -1;
    return firstRowActiveMeeting.localeCompare(secondRowActiveMeeting);
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setSearchQuery(event.target.value);

  // --------------------- columns ------------------------

  const columns: Column<User>[] = [
    {
      Header: 'email',
      accessor: 'email',
    },
    {
      Header: 'clients',
      disableSortBy: true,
      accessor: (item: User) => {
        return (
          <Flex flexWrap="wrap" gap="2px">
            {userListToClientNames[item.id]?.map((c) => (
              <ClientTag key={c} client={c} />
            ))}
          </Flex>
        );
      },
    },
    {
      Header: t('admin.users.table.runningMeeting.columnTitle') as string,

      accessor: (item: User) => {
        if (item.currentMeetingID && item.currentMeetingID != NULL_ID_VALUE)
          return (
            <Tag bgColor="primary" color="white">
              {item.currentMeeting?.name || item.currentMeetingID}
            </Tag>
          );
        return <Text>no active meeting</Text>;
      },
      sortType: handleActiveMeetingsSort,
    },
    {
      Header: t('admin.users.table.lastConnection.columnTitle') as string,
      accessor: (item: User) => (
        <Text>{parseDate(item.last_seen as string)}</Text>
      ),
      sortType: (rowA, rowB) => handleDateSort(rowA, rowB, 'last_seen'),
    },
    {
      Header: 'Id',
      accessor: 'id',
      disableSortBy: true,
    },
    {
      Header: 'temp',
      accessor: 'isTemporary',
      Cell: ({ cell: { value } }) => (
        <Text color={value ? 'red' : 'black'}>{value ? 'yes' : 'no'}</Text>
      ),
    },
    {
      Header: t('admin.users.table.joined.columnTitle') as string,
      accessor: (item: User) => (
        <Text>
          {item.createdAt
            ? new Date(item.createdAt).toLocaleString('fr-BE')
            : '??'}
        </Text>
      ),
      sortType: (rowA, rowB) => handleDateSort(rowA, rowB, 'createdAt'),
    },
    {
      Header: 'Actions',
      accessor: (item: User) => (
        <>
          <ChakraButton
            background="blue.400"
            color="white"
            size="sm"
            rightIcon={<EditOutlined />}
            title="Manage"
            onClick={() => setSelectedUser(item)}
          >
            {t('common.manage')}
          </ChakraButton>
        </>
      ),
      disableSortBy: true,
    },
  ];

  return (
    <Box maxW="full" p={{ base: 3, md: 3 }}>
      <Flex gap="20px">
        <Input
          mb="4"
          background="white"
          value={searchQuery}
          onChange={handleSearchInputChange}
          placeholder={t('admin.users.searchInput.placeholder') || undefined}
        />
        <Flex
          direction="row"
          userSelect="none"
          bgColor="gray.200"
          p="10px"
          borderRadius="20px"
          align="center"
          gap="5px"
          h="fit-content"
          whiteSpace="nowrap"
        >
          <Switch
            size="lg"
            defaultChecked={showTempUser}
            checked={showTempUser}
            onChange={(e) => setShowTempUser(e.currentTarget.checked)}
          />
          <Text display="inline-block">Show Temporary users</Text>
        </Flex>
      </Flex>
      <Table
        size="sm"
        columns={columns}
        data={searchResults}
        useSort
        disableSortRemove
      />
      <Modal
        isOpen={selectedUser ? true : false}
        onClose={() => {
          setSelectedUser(null);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box p="20px" gap="10px">
              <Heading size="sm" pb="5">
                Manage {selectedUser?.email}
              </Heading>

              {selectedUser && <ManageUserClientsView user={selectedUser} />}
              <Divider marginY="10px" />
              {selectedUser && <UserMeetingAccessView user={selectedUser} />}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default UserListView;
