import styled, { keyframes } from 'styled-components';
import { zoomIn } from 'react-animations';
import { GlobalTheme, MediaDevice } from '@/Styles';
import bookmarkSVG from '@/assets/images/bookmark_icon_on.svg?url';

const startAnimation = keyframes`${zoomIn}`;

/**
 * INFO ITEM
 */
export const InfoItemStyled = styled.div<any>`
  animation: 0.5s ${startAnimation};
  height: 100%;
  /* height: auto; */
  max-width: 40vw;
  max-height: 50vh;
  /* max-height: 80%; */

  /* width: 200px;
  height: 200px;
  @media ${MediaDevice.tablet} {
    width: 200px;
    height: 200px;
  }
  @media ${MediaDevice.mobile} {
    width: 150px;
    /* height: 150px; */
  /* }  */

  padding: 20px;
  /* border-radius: 10px; */
  cursor: pointer;
  padding: 0px;
  /* transition: all 0.2s ease-out; */
  /* overflow: hidden; */
  position: relative;

  :hover {
    /* transform: scale(); */
    /* transition: all 0.2s ease-out; */
    .thumbContainer {
      /* border: solid 4px ${(props) => props.theme.color_selection}; */
      outline: 1px solid ${(props) => props.theme.color_selection};
      /* transition: all 0.1s ease-out; */
    }
    .onverInfo {
      visibility: visible;
      opacity: 1;
    }
  }

  .bookmark {
    background-image: url(${bookmarkSVG});
    background-size: 100%;
    position: absolute;
    right: 15px;
    margin-top: 3px;
    /* bottom: 7%; */
    width: 12px;
    height: 18px;
  }

  .onverInfo {
    visibility: hidden;
    transition: all 0.3s ease-out;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    color: ${GlobalTheme.color_selection};
    background-color: #ffffff84;
    opacity: 0;
    border-radius: 10px;
  }

  .thumbContainer {
    width: 100%;
    height: 80%;
    border-radius: 10px;
    border: solid 1px #d8d8d8;
    overflow: hidden;
    background-color: white;
    display: flex;
    justify-content: center; // align image on the left...
    align-items: center;

    /* width: 100%;
    height: 80%; */

    /* width: 100%; */
    /* flex:auto; */
  }
  .thumb {
    max-width: 100%;
    max-height: 100%;
    /* image-rendering: pixelated;*/
    /* width: auto; */
    /* height: 90%; */
    /* background-size: cover; */

    /* border-radius: 20px 20px 20px 20px; */
    /* background-image: url(${(props) => props.item.asset?.url}); */
    /* background-size: cover; */
    /* width: 100%; */
    /* flex: auto; */
  }

  .title_noThumb {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .content {
      display: block;
      color: ${(props) => props.theme.color_title_blue};
      /* color:var(--blue-text-title); */
      font-family: 'poppins-bold';
      border-radius: 20px;
      height: auto;

      /* flex: 1 0 20px; */

      // padding: 5px 5px 0px 5px;
      padding: 20px 20px 20px 20px;
      font-size: 1.5em;
      text-align: center;

      border: solid 1px #d8d8d8;
      overflow: hidden;
      background-color: #ffffff84;

      &.arrow {
        background-color: red;
      }
    }
  }
`;
