import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { AssetType } from '@/utils/types/enums';
import Modal from 'react-modal';
import {
  Box,
  Text,
  Flex,
  Image,
  Fade,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import { IsMobile } from '@/utils/helpers/browser.helper';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { CloseCircleOutlined } from '@ant-design/icons';
import { NewIAsset } from '@/utils/types/zod/assetSchema';

type Props = {
  assetList: NewIAsset[]; // list of availabla assets
  index?: number; // optional current index in list of assets
  onModalClose: () => void;
  onAssetChange?: (oldAsset: NewIAsset, newAsset: NewIAsset) => void;
};

const AssetModal = ({
  assetList,
  index = 0,
  onModalClose,
  onAssetChange,
}: Props) => {
  const [currentIndex, setCurrentIndex] = useState(index);
  const [assetScale, setAssetScale] = useState(1);
  const [startPanTouch, setStartPanTouch] = useState(0);
  const showNavigation = assetList && assetList.length > 1;
  const totalAssets = assetList && assetList.length;
  const currentAsset: NewIAsset = assetList[currentIndex];

  const [imgSrc, setSrc] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentAsset.type === AssetType.IMAGE) {
      const onLoad = () => {
        setSrc(currentAsset.url || '');
        setIsLoading(false);
      };
      const img = document.createElement('img');
      setIsLoading(true);
      img.src = currentAsset.url || '';
      img.addEventListener('load', onLoad);
      return () => {
        img.removeEventListener('load', onLoad);
      };
    } else setIsLoading(false);
  }, [currentAsset.url, currentAsset.type]);

  const nextAssetName =
    assetList &&
    assetList[(currentIndex + assetList.length + 1) % assetList.length].title;
  const previousAssetName =
    assetList &&
    assetList[(currentIndex + assetList.length - 1) % assetList.length].title;

  if (!currentAsset) {
    alert(`No asset found:${currentIndex} in ${JSON.stringify(assetList)}`);
  }

  // handlers
  const nextAsset = () => {
    changeIndex((currentIndex + assetList.length + 1) % assetList.length);
  };
  const previousAsset = () => {
    changeIndex((currentIndex + assetList.length - 1) % assetList.length);
  };
  const changeIndex = (newIndex: number) => {
    if (currentIndex !== newIndex) {
      setCurrentIndex(newIndex);
      onAssetChange?.(assetList[currentIndex], assetList[newIndex]);
    }
  };

  return (
    <Modal
      isOpen
      ariaHideApp={false}
      onRequestClose={onModalClose}
      style={{
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bottom: 'auto',
          right: currentAsset.type === AssetType.IMAGE ? 'auto' : '0',
          padding: 0,
          border: 'none',
          backgroundColor: 'black',
          borderRadius: '20px',
          transition: 'all 0.3s',
          width: 'fit-content',
          height: 'fit-content',
        },
        overlay: {
          backgroundColor: 'rgb(0,0,0,0.8)',
          zIndex: 1000,
        },
      }}
    >
      <Box backgroundColor="black" p="30px" position="relative">
        <Fade
          key={imgSrc} // use the url as key to force a re-render of the fade
          in={!isLoading}
          transition={{ enter: { duration: 0.3 } }}
        >
          {currentAsset && currentAsset.type === AssetType.IMAGE && (
            <TransformWrapper
              disabled={!IsMobile()}
              centerZoomedOut={true}
              limitToBounds={assetScale > 0.9}
              velocityAnimation={{ equalToMove: true }}
              onZoom={(ref) => setAssetScale(ref.state.scale)}
              onPanningStart={(touch) => {
                if (touch.instance.lastMousePosition)
                  setStartPanTouch(touch.instance.lastMousePosition.x); // we need to keep previous touch position, otherwise the panning stop is keeps the previous pan amplitude.
              }}
              onPanningStop={(touch) => {
                if (
                  touch.state.scale == 1 &&
                  assetList.length > 0 &&
                  touch.instance.lastMousePosition &&
                  startPanTouch
                ) {
                  const swipeAmplitude = touch.instance.lastMousePosition.x; // lastMousePosition correspond to swipe amplitude.. not well documented.
                  const swipeTriggerOffset = window.screen.width / 5;
                  if (
                    swipeAmplitude !== startPanTouch &&
                    Math.abs(swipeAmplitude) > swipeTriggerOffset
                  ) {
                    if (swipeAmplitude > 0) {
                      // swipe right
                      nextAsset();
                    } else {
                      //swipe left
                      previousAsset();
                    }
                  }
                }
                // reset pan
                setStartPanTouch(0);
              }}
            >
              <TransformComponent
                wrapperStyle={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Image
                  alt={currentAsset.title}
                  maxW="80vw"
                  maxH="70vh"
                  src={imgSrc}
                />
              </TransformComponent>
            </TransformWrapper>
          )}

          {currentAsset && currentAsset.type === AssetType.VIDEO && (
            <ReactPlayer
              controls={true}
              url={currentAsset.url}
              playing={true}
              loop
              config={{
                file: {
                  attributes: {
                    controlsList: 'nodownload',
                  },
                },
              }}
            />
          )}
        </Fade>

        <IconButton
          role="button"
          h="60px"
          w="60px"
          pt="10px"
          backgroundColor="blackAlpha.700"
          borderRadius="full"
          position="absolute"
          right="0"
          top="0"
          fontSize="35px"
          color="white"
          aria-label="close"
          variant="unstyled"
          as={CloseCircleOutlined}
          onClick={onModalClose}
        />

        <Text
          position="absolute"
          bottom={1}
          right={2}
          color="gray.600"
          fontSize="xs"
        >
          {currentAsset.id}
        </Text>
      </Box>

      {/* FOOTER */}
      <Box backgroundColor="white" p="20px" pt="10px" w="100%">
        {showNavigation && (
          <Flex justifyContent="space-between" alignItems="center">
            <HStack
              data-test="footerButton.left"
              width="25%"
              maxWidth="25vw"
              tabIndex={0}
              role="button"
              onClick={() => previousAsset()}
            >
              <IconButton
                size="sm"
                aria-label="previous-asset"
                rounded="full"
                icon={<BsChevronLeft />}
              />
              <Text fontSize="sm" textDecor="underline">
                {previousAssetName}
              </Text>
            </HStack>
            <Box
              className="footerDescription"
              textAlign="center"
              gap="0"
              maxW="40vw"
            >
              <Text fontSize="120%">{currentAsset.title}</Text>
              <Text className="footerPagination" fontSize="90%">
                {currentIndex + 1}/{totalAssets}
              </Text>
            </Box>
            <HStack
              data-test="footerButton.right"
              justifyContent="right"
              alignItems="center"
              tabIndex={0}
              width="25%"
              maxW="25vw"
              role="button"
              onClick={() => nextAsset()}
            >
              <Text textAlign="right" fontSize="sm" textDecor="underline">
                {nextAssetName}
              </Text>
              <IconButton
                size="sm"
                aria-label="next-asset"
                rounded="full"
                icon={<BsChevronRight />}
              />
            </HStack>
          </Flex>
        )}

        {!showNavigation && (
          <Box w="full" textAlign="center" className="footerDescription nonav">
            {currentAsset.title}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AssetModal;
