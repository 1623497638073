import { SlideItemType } from '@/utils/types/enums';
import { z } from 'zod';

export const imageSlideItemSchema = z.object({
  type: z.literal(SlideItemType.IMAGE),
  url: z.string(),
  alt: z.string(),
});

export type ImageSlideItemType = z.infer<typeof imageSlideItemSchema>;
