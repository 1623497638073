import pureRestartMeeting from '@/backend/pureRestartMeeting';
import {
  Meeting,
  UpdateMeetingInput,
  UpdateMeetingMutation,
  UpdateMeetingMutationVariables,
} from '@/services/API';
import { updateMeeting } from '@/services/graphql/mutations';
import { AppDispatch } from '@/store/Store';
import { useAppDispatch } from '@/store/StoreHooks';
import { actions } from '@/store/slices/meeting';
import { pureJoinAMeeting } from '@/store/thunk/meeting';
import { NULL_ID_VALUE } from '@/utils/constants/app.constants';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import {
  CaptureSentryException,
  CaptureSentryMessage,
} from '@/utils/helpers/CaptureSentryException';
import { CustomSlideIndex } from '@/utils/types/enums';
import { GraphQLResult } from '@aws-amplify/api-graphql';

export default function useMeetingInteractionFunctions(
  meeting: Meeting | undefined,
  isPreview: boolean
) {
  const dispatch = useAppDispatch();

  const updateCurrentCase = async (
    meetingID: string | undefined,
    caseID: string | undefined
  ) => {
    if (isPreview) {
      if (!meeting) {
        CaptureSentryMessage(
          'error at updateCurrentCase (preview): meeting is undefined'
        );
        console.error(
          'error at updateCurrentCase (preview): meeting is undefined'
        );
        return;
      }
      dispatch(
        actions.receivedMeetingUpdate({
          ...meeting,
          currentCase: caseID ?? NULL_ID_VALUE,
        })
      );
      return;
    }
    if (!meetingID) {
      CaptureSentryMessage(
        `error at updateCurrentCase: meetingID is undefined`
      );
      console.error(`error at updateCurrentCase: meetingID is undefined`);
      return;
    }
    const variables: UpdateMeetingMutationVariables = {
      input: {
        id: meetingID,
        currentCase: caseID ?? NULL_ID_VALUE,
        currentSlide: 0,
      },
    };
    try {
      await callGraphQLApi(updateMeeting, variables);
    } catch (error) {
      CaptureSentryException(error);
      console.error(`error at updateCurrentCase:`);
      console.error(error);
    }
  };

  const updateCurrentSlide = async (
    meetingID: string | undefined,
    slideIndex: number
  ) => {
    if (isPreview) {
      if (!meeting) {
        CaptureSentryMessage(
          'error at updateCurrentSlide (preview): meeting is undefined'
        );
        console.error(
          'error at updateCurrentSlide (preview): meeting is undefined'
        );
        return;
      }
      dispatch(
        actions.receivedMeetingUpdate({
          ...meeting,
          currentSlide: slideIndex,
        })
      );
      return;
    }
    if (!meetingID) {
      CaptureSentryMessage(
        `error at updateCurrentSlide: meetingID is undefined`
      );
      console.error(`error at updateCurrentSlide: meetingID is undefined`);
      return;
    }
    const input = {
      id: meetingID,
      currentSlide: slideIndex,
    };
    try {
      await callGraphQLApi(updateMeeting, { input });
    } catch (error) {
      CaptureSentryException(error);
      console.error(`error at updateCurrentSlide:`);
      console.error(error);
    }
  };

  const restartMeeting = async (meetingID: string) => {
    try {
      if (isPreview) {
        if (!meeting) {
          CaptureSentryMessage(
            'error at restartMeeting (preview): meeting is undefined'
          );
          console.error(
            'error at restartMeeting (preview): meeting is undefined'
          );
          return;
        }
        dispatch(
          actions.receivedMeetingUpdate({
            ...meeting,
            currentSlide: 0,
            currentCase: NULL_ID_VALUE,
          })
        );
        return;
      }
      await pureRestartMeeting(meetingID);
    } catch (error) {
      CaptureSentryException(error);
      console.error(`error at restartMeeting:`);
      console.error(error);
    }
  };

  const joinAMeeting = (
    userID: string,
    meetingID: string,
    forceRestart: boolean
  ) => {
    return async (dispatch: AppDispatch) => {
      try {
        if (isPreview) {
          //NOTE: we don't join the meeting if it's a preview
          if (forceRestart) await restartMeeting(meetingID);
          return;
        }
        await pureJoinAMeeting(userID, meetingID, forceRestart);
        dispatch(actions.updateCurrentMeetingInfo(meetingID));
      } catch (error) {
        CaptureSentryException(error);
        console.error(`error at joinAMeeting:`);
        console.error(error);
      }
    };
  };

  /*
  NOTE: Called once the meeting has loaded and the user has already called joinAMeeting (otherwise the meeting would not be loaded)
  */
  const postJoinAMeeting = async (userID: string) => {
    if (isPreview) {
      return;
    }
    const testMode = new URLSearchParams(window.location.search).get(
      'testMode'
    );
    //remove testing mode if the user is the host & he did not just press the testing button
    if (userID === meeting?.hostID && !testMode && meeting.isInTesting) {
      const variables: UpdateMeetingMutationVariables = {
        input: {
          id: meeting.id,
          isInTesting: false,
        },
      };

      await callGraphQLApi<GraphQLResult<UpdateMeetingMutation>>(
        updateMeeting,
        variables
      );
      await restartMeeting(meeting.id);
    }
  };

  const showNPSScoreView = async (meeting: Meeting, caseId: string) => {
    const input: UpdateMeetingInput = {
      id: meeting.id,
      currentCase: caseId,
      currentSlide: CustomSlideIndex.NPS_SCORE,
    };
    if (!meeting.isDemo) {
      input.endDate = new Date(new Date().getTime() + 5 * 60000).toISOString();
      input.isArchived = true;
    }
    try {
      if (isPreview) {
        if (!meeting) {
          CaptureSentryMessage(
            'error at showNPSScoreView (preview): meeting is undefined'
          );
          console.error(
            'error at showNPSScoreView (preview): meeting is undefined'
          );
          return;
        }
        dispatch(
          actions.receivedMeetingUpdate({
            ...meeting,
            ...(input as Meeting),
          })
        );
        return;
      }
      await callGraphQLApi(updateMeeting, { input });
    } catch (error) {
      CaptureSentryException(error);
      console.error(`error at showNPSScoreView:`);
      console.error(error);
    }
  };

  return {
    updateCurrentCase,
    updateCurrentSlide,
    restartMeeting,
    joinAMeeting,
    postJoinAMeeting,
    showNPSScoreView,
  };
}
