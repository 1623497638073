import { SlideItemType } from '@/utils/types/enums';
import { z } from 'zod';

export const selectableAnswerSlideItemSchema = z.object({
  id: z.string(),
  type: z.literal(SlideItemType.ANSWER_SELECTABLE),
  title: z.string(),
  assetRefs: z.array(z.string()),

  //Optional
  description: z.string().optional(),
});

export type SelectableAnswerSlideItemType = z.infer<
  typeof selectableAnswerSlideItemSchema
>;
