import { useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import useCasePreviewLinks from '../admin/components/CaseTable/CasePreviewLinkModal/hooks/useCasePreviewLinks';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import CaseView from '../admin/CaseView';
import { PreviewCaseAccessLinkPayload } from '@/utils/types/types';
import useCaseList from '@/features/caseCluster/hooks/useCaseList';
import { PageLoading } from '@/ui/PageLoading';
import EmptyPageWithTitle from '../page404/EmptyPageWithTitle.tsx';
import { useTranslation } from 'react-i18next';

export default function PreviewCasePermissions() {
  const [isLoading, setIsLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);
  const { accessCode } = useParams();
  const links = useCasePreviewLinks(null);
  const { isAdmin } = useUserAuthenticationContext();
  //NOTE: this state comes from the admin view
  const { state } = useLocation();
  const [unloadedPatientCase, setUnloadedPatientCase] = useState(state);
  const caseList = useCaseList();
  const { t } = useTranslation();

  useEffect(() => {
    if (links.isLoading || !caseList || caseList.length === 0) return;
    if (!accessCode) {
      setIsLoading(false);
      return;
    }
    //need to get the access links, check if the access code matches any links
    const link = links.data?.find((elem) => elem.accessCode === accessCode);

    if (!link) {
      setIsLoading(false);
      return;
    }
    //Now we need to retrieve the unloaded patient case from the link
    const data: PreviewCaseAccessLinkPayload = JSON.parse(link.data || '{}');
    const isLinkStillValid =
      data.validUntil === 0 || new Date(data.validUntil) > new Date();

    if (!isLinkStillValid) {
      setIsLoading(false);
      return;
    }
    const linkCase = caseList.find((elem) => elem.id === data.caseId);

    if (!linkCase) {
      setIsLoading(false);
      return;
    }
    setUnloadedPatientCase(linkCase);
    setHasPermission(true);
    setIsLoading(false);
  }, [unloadedPatientCase, accessCode, links.isLoading, links.data, caseList]);

  if (isAdmin && unloadedPatientCase) {
    return <CaseView unloadedPatientCase={unloadedPatientCase} />;
  }
  if (!isLoading && !hasPermission) {
    return (
      <EmptyPageWithTitle
        title="Unauthorized"
        subtitle={[
          t('pages.unauthorized.title'),
          'Maybe your link has expired ?',
        ]}
      />
    );
  }
  if (isLoading) {
    return <PageLoading />;
  }
  if (!isLoading && hasPermission) {
    return <CaseView unloadedPatientCase={unloadedPatientCase} />;
  }
  return <Navigate to="/404" />;
}
