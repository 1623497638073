import { ConsensusResult } from '../utils/calculateConsensusRate';
import { useMemo } from 'react';
import { CaseMap } from '@/features/caseCluster/caseCluster.types';
import { ParsedAssetViewsPerCase } from '../utils/parseAssetViewsPerCase';
import { ParsedSlideStats } from '../utils/parseSlideStatsPerCase';
import calculateVotingSlideRatio from '../utils/engagementScore/calculateVotingSlideRatio';
import calculateVoteRate from '../utils/engagementScore/calculateVoteRate';
import calculateCaseDurationRatio from '../utils/engagementScore/calculateCaseDurationRatio';
import calculateTimePassedOnSlides from '../utils/engagementScore/calculateTimePassedOnSlides';
import calculateConsistencyRate from '../utils/engagementScore/calculateConsistencyRate';
import { User } from '@/services/API';
import calculateAssetViewStats, {
  CaseAssetViewStats,
} from '../utils/engagementScore/calculateAssetViewStats';
import calculateAssetOpenRate from '../utils/engagementScore/calculateAssetOpenRate';
import calculateAssetDuration from '../utils/engagementScore/calculateAssetDuration';
import { CaptureSentryMessage } from '@/utils/helpers/CaptureSentryException';

type CaseToScore = Record<string, number>;

export type CaseLLXScoreDetailsRow = {
  title: string;
  score: number;
  weight: number;
  tooltip?: string;
};

export default function useMeetingEngagementScore(
  caseIds: string[],
  parsedSlideStats: Record<string, ParsedSlideStats[]> | undefined,
  parsedAssetViews: ParsedAssetViewsPerCase | undefined,
  caseMap: CaseMap,
  isCaseLoading: boolean,
  consensusResultsPerCase: Record<string, ConsensusResult>,
  participantList: User[],
  uniqueCasesPresented: string[]
) {
  const caseDurationRatioPerCase = useMemo(() => {
    if (caseIds.length === 0 || !parsedSlideStats) return {};
    // console.log('Recomputing caseDurationRatioPerCase');
    return caseIds.reduce((acc, curr) => {
      acc[curr] = calculateCaseDurationRatio(parsedSlideStats[curr]);
      if (acc[curr] === undefined || isNaN(acc[curr])) {
        CaptureSentryMessage(
          'useMeetingEngagementScore: caseDurationRatioPerCase is NaN or undefined',
          {
            caseId: curr,
            parsedSlideStats: parsedSlideStats[curr],
          }
        );
      }
      return acc;
    }, {} as CaseToScore);
  }, [caseIds, parsedSlideStats]);

  const votingSlideRatioPerCase = useMemo(() => {
    if (isCaseLoading || !caseMap || caseIds.length === 0) return {};
    // console.log('Recomputing votingSlideRatioPerCase');
    return caseIds.reduce((acc, curr) => {
      const caseSlides = caseMap[curr].slides;
      if (!caseSlides) {
        acc[curr] = 0;
        CaptureSentryMessage(
          'useMeetingEngagementScore: caseSlides is undefined'
        );
        return acc;
      }
      acc[curr] = calculateVotingSlideRatio(caseSlides);
      if (acc[curr] === undefined || isNaN(acc[curr])) {
        CaptureSentryMessage(
          'useMeetingEngagementScore: votingSlideRatioPerCase is NaN or undefined',
          {
            caseId: curr,
            caseSlides: caseSlides,
          }
        );
      }
      return acc;
    }, {} as CaseToScore);
  }, [caseIds, isCaseLoading, caseMap]);

  const timePassedOnSlidesPerCase = useMemo(() => {
    if (caseIds.length === 0 || !parsedSlideStats) return {};
    // console.log('Recomputing timePassedOnSlidesPerCase');
    return caseIds.reduce((acc, curr) => {
      acc[curr] = calculateTimePassedOnSlides(parsedSlideStats[curr]);
      if (acc[curr] === undefined || isNaN(acc[curr])) {
        CaptureSentryMessage(
          'useMeetingEngagementScore: timePassedOnSlidesPerCase is NaN or undefined',
          {
            caseId: curr,
            parsedSlideStats: parsedSlideStats[curr],
          }
        );
      }
      return acc;
    }, {} as CaseToScore);
  }, [caseIds, parsedSlideStats]);

  const voteRatePerCase = useMemo(() => {
    // console.log('Recomputing voteRatePerCase');
    return caseIds.reduce((acc, curr) => {
      acc[curr] = calculateVoteRate(
        participantList.length,
        consensusResultsPerCase[curr]
      );
      if (acc[curr] === undefined || isNaN(acc[curr])) {
        CaptureSentryMessage(
          'useMeetingEngagementScore: voteRatePerCase is NaN or undefined',
          {
            caseId: curr,
            consensusResultsPerCase: consensusResultsPerCase[curr],
          }
        );
      }
      return acc;
    }, {} as CaseToScore);
  }, [caseIds, participantList.length, consensusResultsPerCase]);

  const consistencyRatePerCase = useMemo(() => {
    if (caseIds.length === 0 || !parsedSlideStats) return {};
    // console.log('Recomputing consistencyRatePerCase');
    return caseIds.reduce((acc, curr) => {
      acc[curr] = calculateConsistencyRate(parsedSlideStats[curr]);
      if (acc[curr] === undefined || isNaN(acc[curr])) {
        CaptureSentryMessage(
          'useMeetingEngagementScore: consistencyRatePerCase is NaN or undefined',
          {
            caseId: curr,
            parsedSlideStats: parsedSlideStats[curr],
          }
        );
      }
      return acc;
    }, {} as CaseToScore);
  }, [caseIds, parsedSlideStats]);

  const assetViewStatsPerCase = useMemo(() => {
    if (caseIds.length === 0 || !parsedAssetViews || isCaseLoading || !caseMap)
      return {};
    // console.log('Recomputing assetOpenRatePerCase');
    const participantIDs = participantList.map((p) => p.id);
    return caseIds.reduce((acc, curr) => {
      const caseSlides = caseMap[curr].slides;
      if (!caseSlides) {
        CaptureSentryMessage(
          'useMeetingEngagementScore: caseSlides is undefined in (assetViewStatsPerCase)'
        );
        return acc;
      }
      acc[curr] = calculateAssetViewStats(
        participantIDs,
        caseSlides,
        parsedAssetViews[curr]
      );
      if (acc[curr] === undefined) {
        CaptureSentryMessage(
          'useMeetingEngagementScore: assetViewStatsPerCase is NaN or undefined',
          {
            caseId: curr,
            parsedAssetViews: parsedAssetViews[curr],
          }
        );
      }
      return acc;
    }, {} as Record<string, CaseAssetViewStats>);
  }, [caseIds, isCaseLoading, caseMap, parsedAssetViews, participantList]);

  const assetOpenRatePerCase = useMemo(() => {
    if (caseIds.length === 0 || !assetViewStatsPerCase) return {};
    // console.log('Recomputing assetOpenRatePerCase');
    return caseIds.reduce((acc, curr) => {
      acc[curr] = calculateAssetOpenRate(assetViewStatsPerCase[curr]);
      if (acc[curr] === undefined || isNaN(acc[curr])) {
        CaptureSentryMessage(
          'useMeetingEngagementScore: assetOpenRatePerCase is NaN or undefined',
          {
            caseId: curr,
            assetViewStatsPerCase: assetViewStatsPerCase[curr],
          }
        );
      }
      return acc;
    }, {} as CaseToScore);
  }, [caseIds, assetViewStatsPerCase]);

  const assetDurationScorePerCase = useMemo(() => {
    if (caseIds.length === 0 || !assetViewStatsPerCase) return {};
    // console.log('Recomputing assetDurationScorePerCase');
    return caseIds.reduce((acc, curr) => {
      acc[curr] = calculateAssetDuration(assetViewStatsPerCase[curr]);
      if (acc[curr] === undefined || isNaN(acc[curr])) {
        CaptureSentryMessage(
          'useMeetingEngagementScore: assetDurationScorePerCase is NaN or undefined',
          {
            caseId: curr,
            assetViewStatsPerCase: assetViewStatsPerCase[curr],
          }
        );
      }
      return acc;
    }, {} as CaseToScore);
  }, [caseIds, assetViewStatsPerCase]);

  const caseIdToLLXRatingDetails = useMemo(() => {
    return caseIds.reduce((acc, curr) => {
      const detail: CaseLLXScoreDetailsRow[] = [
        {
          title: 'Case Duration Ratio',
          score: caseDurationRatioPerCase[curr],
          weight: 0.15,
          tooltip:
            'The total time of a case should be 4.5mins * the amount of slides. The closer you are to this ratio, the better.',
        },
        {
          title: 'Voting Slide Ratio',
          score: votingSlideRatioPerCase[curr],
          weight: 0.15,
          tooltip:
            'Ideally 45% of slides should be voting slides. The closer you are to this ratio, the better.',
        },
        {
          title: 'Time Passed on Slides',
          score: timePassedOnSlidesPerCase[curr],
          weight: 0.1,
          tooltip:
            'The host should spend between 4m15s and 4m45s on each slide to have a perfect score. The further you are from this range, the worse.',
        },
        {
          title: 'Vote Rate',
          score: voteRatePerCase[curr],
          weight: 0.25,
          tooltip:
            'Calculated by the total amount of votes divided by: the amount of participants * the amount of votable slides. The closer you are to 100%, the better.',
        },
        {
          title: 'Consistency Rate',
          score: consistencyRatePerCase[curr],
          weight: 0.05,
          tooltip:
            'The host should spend the same amount of time on each slide. Calculated by the sum of each slide absolute difference between its time spent on the slide and the mean time spent on all slides.',
        },
        {
          title: 'Asset Open Rate',
          score: assetOpenRatePerCase[curr],
          weight: 0.15,
          tooltip:
            'The more assets opened by users, the better. If there are no assets, this score will be 0%.',
        },
        {
          title: 'Asset Duration Score',
          score: assetDurationScorePerCase[curr],
          weight: 0.15,
          tooltip:
            'The average time a user spends on a single asset. The closer this average comes to 9.0s (or more), the better.',
        },
      ];
      acc.set(curr, detail);
      return acc;
    }, new Map<string, CaseLLXScoreDetailsRow[]>());
  }, [
    caseIds,
    caseDurationRatioPerCase,
    votingSlideRatioPerCase,
    timePassedOnSlidesPerCase,
    voteRatePerCase,
    consistencyRatePerCase,
    assetOpenRatePerCase,
    assetDurationScorePerCase,
  ]);

  const caseIdToLLXRating = useMemo(() => {
    console.log('Recomputing caseIdToLLXRating');
    return caseIds.reduce((acc, curr) => {
      const score =
        caseDurationRatioPerCase[curr] * 0.15 +
        votingSlideRatioPerCase[curr] * 0.15 +
        timePassedOnSlidesPerCase[curr] * 0.1 +
        voteRatePerCase[curr] * 0.25 +
        consistencyRatePerCase[curr] * 0.05 +
        assetOpenRatePerCase[curr] * 0.15 +
        assetDurationScorePerCase[curr] * 0.15;
      acc.set(curr, score ? score : 0);
      return acc;
    }, new Map<string, number>());
  }, [
    caseIds,
    caseDurationRatioPerCase,
    votingSlideRatioPerCase,
    timePassedOnSlidesPerCase,
    voteRatePerCase,
    consistencyRatePerCase,
    assetOpenRatePerCase,
    assetDurationScorePerCase,
  ]);

  const engagementScore = useMemo(() => {
    if (caseIds.length === 0) return 0;
    if (!caseIdToLLXRating) return 0;
    if (caseIdToLLXRating.size === 0) return 0;
    console.log('Recomputing engagementScore', caseIdToLLXRating);
    let totalScore = 0;
    const amtCasesPresented = Math.max(1, uniqueCasesPresented.length);
    for (const caseId of uniqueCasesPresented) {
      if (!caseIdToLLXRating.has(caseId)) {
        CaptureSentryMessage(
          'useMeetingEngagementScore: caseIdToLLXRating does not have caseId',
          {
            caseId: caseId,
            caseIdToLLXRating: caseIdToLLXRating,
            uniqueCasesPresented: uniqueCasesPresented,
          }
        );
      }
      totalScore += caseIdToLLXRating.get(caseId) || 0;
    }
    totalScore = totalScore / amtCasesPresented;
    return totalScore ? totalScore : 0;
  }, [caseIds, caseIdToLLXRating, uniqueCasesPresented]);

  // useEffect(() => {
  //   console.log('caseIdToLLXRating value changed: ');
  //   console.log(caseIdToLLXRating);
  //   console.log(caseDurationRatioPerCase);
  //   console.log(votingSlideRatioPerCase);
  //   console.log(timePassedOnSlidesPerCase);
  //   console.log(voteRatePerCase);
  //   console.log(consistencyRatePerCase);
  //   console.log(assetOpenRatePerCase);
  //   console.log(assetDurationScorePerCase);
  // }, [caseIdToLLXRating]);

  return {
    engagementScore,
    caseIdToLLXRating,
    caseIdToLLXRatingDetails,
  };
}
