import useCaseList from '../../../../features/caseCluster/hooks/useCaseList';
import { FiEdit, FiEye, FiMoreVertical } from 'react-icons/fi';
import { MdOutlineAutorenew } from 'react-icons/md';
import {
  Box,
  chakra,
  Flex,
  Divider,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Button,
  Text,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/Routes';
import useCaseContent from '@/features/caseCluster/hooks/useCaseClusterContent';
import { UnloadedIPatientCase } from '@/utils/types/zod/patientCaseSchema';
import CasePreviewLinkModal from './CasePreviewLinkModal/CasePreviewLinkModal';
import { useState } from 'react';

const CaseTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const caseList = useCaseList();

  const { isCaseLoading, caseMap } = useCaseContent(
    caseList?.map((elem) => elem.id) || []
  );
  const handleViewCase = async (patientCase: UnloadedIPatientCase) => {
    navigate(`${ROUTES.PREVIEW_CASE}/admin`, { state: patientCase });
  };
  const handleEditCase = async (patientCase: UnloadedIPatientCase) => {
    navigate(`${ROUTES.ADMIN_CASE_EDIT}?id=${patientCase.id}`);
  };

  const previewLinkModal = useDisclosure();
  const [previewLinkModalCase, setPreviewLinkModalCase] =
    useState<UnloadedIPatientCase | null>(null);

  return (
    <>
      {previewLinkModalCase && (
        <CasePreviewLinkModal
          disclosure={previewLinkModal}
          patientCase={previewLinkModalCase}
        />
      )}
      <Box width="full" py={10} px={4}>
        <Box
          border="1px solid"
          borderColor="gray.200"
          rounded="md"
          boxShadow="lg"
          overflow="hidden"
          style={{ background: 'white' }}
        >
          <Flex justify="left" p={5}>
            <chakra.h3 fontSize="xl" fontWeight="bold" textAlign="center">
              Cases{' '}
            </chakra.h3>
          </Flex>
          <Divider />
          <TableContainer>
            <Table size="md">
              <Thead>
                <Tr fontWeight="900">
                  <Th>Id</Th>
                  <Th>Name</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {caseList &&
                  !isCaseLoading &&
                  caseMap &&
                  caseList.map((data, index: number) => {
                    return (
                      <Tr key={index}>
                        <Td fontSize="sm" whiteSpace="pre-line">
                          {data.id}
                        </Td>
                        <Td fontSize="sm" whiteSpace="pre-line">
                          {caseMap[data.id].displayName}
                        </Td>
                        <Td>
                          <HStack>
                            <Button
                              onClick={() => handleViewCase(data)}
                              variant="secondary"
                            >
                              <FiEye />
                              <Text ml="5px">
                                {t('admin.cases.table.viewCase.button')}
                              </Text>
                            </Button>
                            <Menu>
                              <MenuButton
                                as={IconButton}
                                aria-label="Options"
                                icon={<FiMoreVertical />}
                                variant="outline"
                              />
                              <MenuList>
                                {/* <MenuItem icon={<FiPlus />}>
                            {t('admin.cases.table.addCase.button')}
                          </MenuItem> */}
                                {/* <MenuItem
                              icon={<FiEye />}
                              onClick={() => handleViewCase(data)}
                            >
                              {' '}
                              {t('admin.cases.table.viewCase.button')}
                            </MenuItem> */}
                                <MenuItem
                                  icon={<MdOutlineAutorenew />}
                                  onClick={() => {
                                    setPreviewLinkModalCase(data);
                                    previewLinkModal.onOpen();
                                  }}
                                >
                                  {t(
                                    'admin.cases.table.actions.generatePreview.button'
                                  )}
                                </MenuItem>
                                <MenuItem
                                  icon={<FiEdit />}
                                  onClick={() => handleEditCase(data)}
                                >
                                  {' '}
                                  {t('common.edit')}
                                </MenuItem>
                                {/* <MenuItem icon={<FiTrash />}>
                            {' '}
                            {t('admin.cases.table.deleteCase.button')}
                          </MenuItem> */}
                              </MenuList>
                            </Menu>
                          </HStack>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default CaseTable;
