import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    primary: '#FF7F68',
    livelinx: {
      text: '#4E3D81',
      black100: '#7A7687',

      darkgrey: '#404040',
      purple50: '#f3f8ff',
      purple100: '#6971FE',
      purple200: '#4E3D81',
      purple400: '#34334F',
      red: '#ff0000',
      lightgray: '#BFCEFF',
      lightgray100: '#e9e9eb',
      blue50: '#e6f4ff',
      blue100: '#c5d4ff',
      blue150: '#91caff',
      blue200: '#87b5ff',
      blue300: '#4B75FF',
      blue400: '#0958d9',

      grey100: '#f5f5f5',
      grey200: '#7A7687',
      grey300: '#E4EFFF',

      green: '#219653',
      green50: '#f6ffed',
      green100: '#b7eb8f',
      green200: '#389e0d',

      yellow200: '#FCBD53',

      orange200: '#FF7F68',
    },
  },

  shadows: {
    smallTop: '0 -3px 5px 0px rgba(159, 122, 234, 0.2)',
    white: '0px 0px 50px 0px #5A6CEA14',
  },

  fonts: {
    heading: 'poppins-bold',
    body: 'nunito-bold',
  },

  components: {
    Text: {
      variants: {
        caseTitle: {
          color: 'livelinx.purple200',
          fontSize: '17px',
          fontFamily: 'poppins-bold',
        },
        meetingReportChunkTitle: {
          color: 'livelinx.purple200',
          fontSize: '22px',
          fontFamily: 'poppins-bold',
        },
        purpleTitleBold: {
          color: 'livelinx.purple200',
          fontFamily: 'poppins-bold',
        },
        purpleTitle: {
          color: 'livelinx.purple200',
          fontFamily: 'poppins-regular',
        },
        graySubTitle: {
          color: 'livelinx.grey200',
          fontFamily: 'nunito-regular',
          fontSize: '17px',
        },
      },
    },
    Button: {
      // baseStyle: {},
      variants: {
        primary: {
          boxShadow: '0 2px #0000000b',
          background: 'primary',
          color: 'white',
          border: '1px solid',
          borderRadius: '10px',
          fontFamily: 'heading',
          fontWeight: 'bold',
          transition: '0.3s all',
          _hover: {
            color: 'primary',
            background: 'white',
            borderColor: 'primary',
          },
        },

        secondary: {
          background: 'white',
          fontFamily: 'heading',
          color: 'livelinx.purple200',
          border: '1px solid',
          borderRadius: '10px',
          borderColor: 'livelinx.lightgray',
          fontWeight: 'bold',
          _hover: {
            background: 'livelinx.purple200',
            color: 'white',
          },
        },
        blue: {
          background: 'blue.500',
          color: 'white',
          border: '1px solid',
          borderRadius: '5px',
          borderColor: 'blue.500',
          _hover: {
            background: 'blue.600',
            color: 'white',
          },
        },

        slideNavigator: {
          backgroundColor: 'livelinx.blue100',
          color: 'livelinx.purple200',
          height: '50px',
          width: '100%',
          fontSize: '20px',
          borderRadius: '9px',
          _hover: {
            background: 'livelinx.purple200',
            color: 'white',
          },
        },
        slideNavigatorSelected: {
          backgroundColor: 'livelinx.purple200',
          color: 'white',
          height: '50px',
          width: '100%',
          fontSize: '20px',
          borderRadius: '9px',
          _hover: {
            background: 'livelinx.purple200',
            color: 'white',
          },
        },

        meetingStats: {
          backgroundColor: 'livelinx.blue300',
          borderRadius: '100px',
          _hover: {
            backgroundColor: 'livelinx.blue200',
          },
        },

        danger: () => ({
          borderColor: '#ff4d4f',
          background: '#ff4d4f',
          boxShadow: '0 2px #0000000b',
          textShadow: '0 -1px 0 rgb(0 0 0 / 12%)',
          color: '#fff',
        }),

        light: () => ({
          border: '1px',
          borderColor: 'gray.200',
          background: '#fff',
        }),
      },
    },

    Progress: {
      baseStyle: {
        filledTrack: {
          bg: 'livelinx.purple200',
        },
      },
      variants: {
        answers: {
          filledTrack: {
            bg: 'livelinx.purple100',
          },
        },
      },
    },
  },
});
