import {
  Box,
  List,
  ListItem,
  Icon,
  SlideFade,
  Text,
  BoxProps,
} from '@chakra-ui/react';
import avatarManIcon from '@/assets/images/genderMaleIcon.svg';
import avatarWomanIcon from '@/assets/images/genderFemaleIcon.svg';
import { useTranslation } from 'react-i18next';
import useDeviceSize from '@/services/hooks/useDeviceSize';

type Props = {
  title: string;
  subtitle: string;
  descriptionList: string[];
  size?: 'small' | 'medium' | 'large';
  isWomanCase: boolean;
  color?: string;
  isGridRowItem?: boolean;
  onClick?: () => void;
};

type AnamneseWrapperProps = {
  children: React.ReactNode;
  onClick?: () => void;
} & BoxProps;

export const AnamneseItemWrapper = ({
  children,
  onClick,
  ...rest
}: AnamneseWrapperProps) => {
  return (
    <Box
      h="100%"
      padding="20px"
      position="relative"
      userSelect="none"
      cursor="pointer"
      onClick={onClick}
      bgColor="white"
      borderRadius="15px"
      display="flex"
      flexDirection="column"
      {...rest}
    >
      {children}
    </Box>
  );
};

const AnamneseItem = ({
  title,
  subtitle,
  descriptionList,
  isWomanCase,
  isGridRowItem,
  onClick,
}: Props) => {
  const { t } = useTranslation();
  const { isDesktopOrLaptop } = useDeviceSize();
  const smallHeader = !isDesktopOrLaptop || isGridRowItem;

  return (
    <SlideFade
      in={true}
      style={{ height: isGridRowItem ? '100%' : 'auto' }}
      offsetY={-450}
      transition={{
        enter: {
          transition: { duration: 3 },
          delay: 0.2,
        },
      }}
    >
      {/* This Box is a wrapper for to make the whole item Clickable */}
      <AnamneseItemWrapper onClick={onClick}>
        {/* This is the Header for each case with Icon and title */}
        <Box w="80%" display="flex" flexDirection="row">
          <Icon
            boxSize={smallHeader ? '50px' : '60px'}
            as={isWomanCase ? avatarWomanIcon : avatarManIcon}
          />
          {/* Wrapper for title and subtitle */}
          <Box
            marginLeft="20px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Text variant="caseTitle">{title}</Text>
            <Text
              whiteSpace="nowrap"
              overflow="hidden"
              // textOverflow="ellipsis"
              paddingRight="20px"
            >
              {subtitle}
            </Text>
          </Box>
        </Box>
        {/* This is the description of each case */}
        <List
          w="100%"
          style={{ marginTop: '20px', marginLeft: '8px' }}
          pb="20px"
        >
          {descriptionList.map((val: string, i, arr) => {
            // if (shouldCutDescription) {
            //   const cutOff = isDesktopOrLaptop ? 6 : 3;
            //   if (i === cutOff)
            //     return (
            //       <ListItem key={val}>
            //         {t('meeting.anamnese.clickForMoreDetails.cta')}
            //       </ListItem>
            //     );
            //   if (i > cutOff) return null;
            // }

            const previousWasBold =
              i !== 0 ? arr[i - 1].includes('<b>') : false;
            const thisLineIsBold = val.includes('<b>');
            if (thisLineIsBold) {
              val = val.replace('<b>', '').replace('</b>', '');
              return (
                <ListItem
                  key={val}
                  color="livelinx.purple200"
                  fontFamily="poppins-bold"
                  mt={previousWasBold ? '-8px' : '5px'}
                  pb="8px"
                >
                  {val}
                </ListItem>
              );
            } else
              return (
                <ListItem key={val} lineHeight="110%" pb="2px">
                  {val}
                </ListItem>
              );
          })}
        </List>

        {/* MORE DETAILS area */}
        {isGridRowItem && (
          <Text
            position="absolute"
            bottom="0px"
            left="0px"
            bgColor="white"
            h="45px"
            w="100%"
            pt="10px"
            textAlign="center"
            boxShadow="0 -20px 20px 0px rgba(255, 255, 255, 1)"
            borderRadius="0px 0px 15px 15px"
          >
            <u>{t('meeting.anamnese.clickForMoreDetails.cta')}</u>
          </Text>
        )}
      </AnamneseItemWrapper>
    </SlideFade>
  );
};

export default AnamneseItem;
