import { SimpleContentModal } from '@/ui/SimpleContentModal';
import { IsMobile } from '@/utils/helpers/browser.helper';
import { SlideItemType } from '@/utils/types/enums';
import { AnamneseSlideType } from '@/utils/types/zod/slideTypes/anamneseSlideSchema';
import { AnamneseSlideItemType } from '@/utils/types/zod/slideTypes/slideItemTypes/anamneseItemSchema';
import React, { useState } from 'react';
import ItemContainer from './ItemContainer';
import ItemListContainer from './ItemListContainer';
import AnamneseItem from './items/AnamneseItem';

type Props = {
  caseIndex: number;
  slide: AnamneseSlideType;
};

function AnamneseSlide({ slide, caseIndex }: Props) {
  const [anamenseModal, setAnamenseModal] = useState<
    AnamneseSlideItemType | undefined
  >(undefined);

  return (
    <>
      {anamenseModal && (
        <SimpleContentModal
          isOpen={true}
          onClose={() => setAnamenseModal(undefined)}
          allowZoom={IsMobile()}
          content={
            <AnamneseItem
              title={`CASUS ${caseIndex + 1}`}
              isWomanCase={anamenseModal.gender === 'woman'}
              subtitle={anamenseModal.title}
              descriptionList={anamenseModal.descriptionList}
            />
          }
        />
      )}
      <ItemListContainer>
        {slide.items.map((item: AnamneseSlideItemType, itemIndex: number) => (
          <ItemContainer key={itemIndex} slide={slide}>
            {item.type === SlideItemType.ANAMNESE && (
              <AnamneseItem
                title={`CASUS ${caseIndex + 1}`}
                isWomanCase={item.gender === 'woman'}
                subtitle={item.title}
                descriptionList={item.descriptionList}
                size="large"
                onClick={() => setAnamenseModal(item)}
              />
            )}
          </ItemContainer>
        ))}
      </ItemListContainer>
    </>
  );
}

export default AnamneseSlide;
