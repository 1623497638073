import { Divider, Flex, IconButton, Tooltip } from '@chakra-ui/react';
import IconChatOff from '@/assets/images/icon_chat_off.svg';
import IconUsersOff from '@/assets/images/icon_users_off.svg';
import IconVideoOff from '@/assets/images/icon_video_off.svg';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import shouldActivateChatFeature from '@/utils/helpers/meeting/shouldActivateChatFeature';
import shouldActivatePIPFeature from '@/utils/helpers/meeting/shouldActivatePIPFeature';
import shouldActivateUserListFeature from '@/utils/helpers/meeting/shouldActivateUserListFeature';
import { Fragment, useEffect, useState } from 'react';
import useConditionalTimeout from '@/services/hooks/useConditionalTimeout';

export type MeetingRightSidebarProps = {
  onParticipantsClick: () => void;
  onChatClick: () => void;
  onVideoClick: () => void;
  amtUnreadMessages: number;
};

export default function MeetingRightSideBar(props: MeetingRightSidebarProps) {
  const { meeting } = useCurrentMeetingDataContext();
  const { email } = useUserAuthenticationContext();
  const [showUnreadNotification, setShowUnreadNotification] = useState(false);

  // NOTE: shows the unread messages notification after 500ms
  useConditionalTimeout(
    () => {
      setShowUnreadNotification(true);
    },
    500,
    props.amtUnreadMessages !== 0
  );

  useEffect(() => {
    if (props.amtUnreadMessages === 0) {
      setShowUnreadNotification(false);
    }
  }, [props.amtUnreadMessages]);

  console.log('DEBUG >> showUnreadNotif', showUnreadNotification);

  const elements = [
    {
      conditionToShow: shouldActivateUserListFeature(meeting?.id, email),
      toolTip: 'Participants',
      icon: (
        <IconUsersOff
          style={{ fontSize: '80px', right: '-8px', position: 'relative' }}
        />
      ),
      onClick: props.onParticipantsClick,
    },
    {
      conditionToShow: shouldActivateChatFeature(meeting?.id, email),
      toolTip: 'Chat',
      icon: (
        <Flex position="relative">
          <IconChatOff style={{ fontSize: '80px' }} />
          {showUnreadNotification && props.amtUnreadMessages !== 0 && (
            <Flex
              position="absolute"
              top={4}
              right={4}
              bg="red"
              borderRadius="50%"
              boxSize={4}
              justifyContent="center"
              alignItems="center"
              color="white"
              fontSize="10px"
              zIndex={5}
            >
              {props.amtUnreadMessages}
            </Flex>
          )}
        </Flex>
      ),
      onClick: props.onChatClick,
    },
    {
      conditionToShow: shouldActivatePIPFeature(meeting?.id, email),
      toolTip: 'Live Video',
      icon: <IconVideoOff style={{ fontSize: '80px' }} />,
      onClick: props.onVideoClick,
    },
  ];

  return (
    <Flex
      width={16}
      position="absolute"
      right="5px"
      bg="white"
      height="fit-content"
      flexDir="column"
      top="50%"
      borderRadius="10px"
      transform="translateY(-50%)"
      boxShadow="white"
      overflow="hidden"
    >
      {elements.map((elem, idx) => {
        if (!elem.conditionToShow) {
          return null;
        }
        return (
          <Fragment key={elem.toolTip}>
            <Tooltip label={elem.toolTip}>
              <IconButton
                boxSize={16}
                onClick={elem.onClick}
                aria-label={elem.toolTip}
                icon={elem.icon}
                bg="transparent"
                _hover={{ bg: 'transparent' }}
              />
            </Tooltip>
            {idx !== elements.length - 1 && (
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Divider width="80%" />
              </Flex>
            )}
          </Fragment>
        );
      })}
    </Flex>
  );
}
