import { Meeting } from '@/services/API';
type sortingOptionsType =
  | 'caseClusterAscending'
  | 'caseClusterDescending'
  | 'hostAscending'
  | 'hostDescending'
  | 'meetingAscending'
  | 'meetingDescending'
  | 'dateDescending';

type options = {
  value: string;
  optionLabel: string;
};
type sortingData = {
  label: string;
  options: Array<options>;
};
export const sortAdminMeetingList = (
  sortingType: sortingOptionsType,
  meetingList: Array<Meeting>
) => {
  return meetingList.sort((a: Meeting, b: Meeting) => {
    switch (sortingType) {
      case 'caseClusterAscending':
        return a?.caseCluster?.name
          ? a.caseCluster.name
              .toLowerCase()
              .localeCompare(b.caseCluster?.name?.toLowerCase() as string)
          : 0;
      case 'caseClusterDescending':
        return (
          -1 *
          (a?.caseCluster?.name
            ? a.caseCluster.name
                .toLowerCase()
                .localeCompare(b.caseCluster?.name?.toLowerCase() as string)
            : 0)
        );
      case 'hostAscending':
        return a?.host?.firstname
          ? a.host?.firstname
              .toLowerCase()
              .localeCompare(b.host?.firstname?.toLowerCase() as string)
          : 0;
      case 'hostDescending':
        return (
          -1 *
          (a?.host?.firstname
            ? a.host.firstname
                .toLowerCase()
                .localeCompare(b.host?.firstname.toLowerCase() as string)
            : 0)
        );

      case 'meetingAscending':
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());

      case 'meetingDescending':
        return -1 * a.name.toLowerCase().localeCompare(b.name.toLowerCase());

      case 'dateDescending': {
        const result =
          new Date(b.createdAt as string).getTime() -
          new Date(a.createdAt as string).getTime();
        return result;
      }
      default:
        return 0;
    }
  });
};

export const sortingOptionsData: sortingData[] = [
  {
    label: 'Meeting Name',
    options: [
      { value: 'meetingAscending', optionLabel: 'Meeting Name Ascending' },
      { value: 'meetingDescending', optionLabel: 'Meeting Name Descending' },
    ],
  },
  {
    label: 'CaseCluster Name',
    options: [
      {
        value: 'caseClusterAscending',
        optionLabel: 'CaseCluster Name Ascending',
      },
      {
        value: 'caseClusterDescending',
        optionLabel: 'CaseCluster Name Descending',
      },
    ],
  },
  {
    label: 'Host',
    options: [
      {
        value: 'hostAscending',
        optionLabel: 'Host Ascending',
      },
      {
        value: 'hostDescending',
        optionLabel: 'Host Descending',
      },
    ],
  },
  {
    label: 'Date',
    options: [
      {
        value: 'dateDescending',
        optionLabel: 'Sort By date',
      },
    ],
  },
];
