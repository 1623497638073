import BestAsset from '@/features/meeting-stats/components/BestAsset';
import BestSlide from '@/features/meeting-stats/components/BestSlide';
import CaseHeader from '@/features/meeting-stats/components/CaseHeader/CaseHeader';
import { ChunkContainerLoading } from '@/features/meeting-stats/components/ChunkContainer';
import EngagementContributors from '@/features/meeting-stats/components/EngagementContributors';
import Host from '@/features/meeting-stats/components/Host';
import PercentageChunk from '@/features/meeting-stats/components/PercentageChunk';
import { MeetingStatsViewContext } from '@/features/meeting-stats/context/MeetingStatsViewContext';
import { ParsedAssetViews } from '@/features/meeting-stats/utils/parseAssetViewsPerCase';
import { ParsedSlideStats } from '@/features/meeting-stats/utils/parseSlideStatsPerCase';
import useDeviceSize from '@/services/hooks/useDeviceSize';
import { Flex, GridItem, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer';
import ScoreDetailModal from '../MeetingStatsView/ScoreDetailModal';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { Column } from 'react-table';
import { CaseLLXScoreDetailsRow } from '@/features/meeting-stats/hooks/useMeetingEngagementScore';
import { InfoIcon } from '@chakra-ui/icons';

type Props = {
  caseToTop4Slides: Record<string, ParsedSlideStats[]> | undefined;
  bestAssetPerCase: Map<string, ParsedAssetViews> | undefined;
};

function SpecificCaseStatsView({ caseToTop4Slides, bestAssetPerCase }: Props) {
  const { isDesktopOrLaptop } = useDeviceSize();
  const { t } = useTranslation();
  const {
    isOpen: isLLXScoreDetailOpen,
    onOpen: onLLXScoreDetailOpen,
    onClose: onLLXScoreDetailClose,
  } = useDisclosure();
  const {
    caseTitles,
    currentCase,
    caseIdToLLXRating,
    caseIdToLLXRatingDetails,
    consensusRatePerCase,
    durationPerCaseInSeconds,
    longestSlidePerCaseInSeconds,
    caseIds,
  } = useContext(MeetingStatsViewContext);

  const { isAdmin } = useUserAuthenticationContext();

  const columns: Column<CaseLLXScoreDetailsRow>[] = [
    {
      Header: 'Title',
      accessor: (row) => (
        <Flex justifyContent="space-between">
          <Text>{row.title}</Text>
          {row.tooltip && (
            <Tooltip label={row.tooltip}>
              <InfoIcon color="gray" />
            </Tooltip>
          )}
        </Flex>
      ),
    },
    {
      Header: 'Score',
      accessor: (row) => `${(row.score * 100).toFixed(2)}`,
    },
    {
      Header: 'Weight',
      accessor: (row) => `${(row.weight * 100).toFixed(2)}%`,
    },
  ];

  const data = useMemo(() => {
    const llxScore = caseIdToLLXRatingDetails
      .get(currentCase)!
      .reduce((acc, curr) => acc + curr.score * curr.weight, 0);
    console.log('TEST');
    return [
      ...caseIdToLLXRatingDetails.get(currentCase)!,
      {
        title: 'Total',
        score: llxScore,
        weight: 1,
      },
    ];
  }, [caseIdToLLXRatingDetails]);

  return (
    <>
      <ScoreDetailModal
        isOpen={isLLXScoreDetailOpen}
        onClose={onLLXScoreDetailClose}
        columns={columns}
        data={data}
      />
      <GridItem colSpan={isDesktopOrLaptop ? 2 : 1}>
        <CaseHeader />
      </GridItem>
      <GridItem colSpan={isDesktopOrLaptop ? 2 : 1}>
        <Host />
      </GridItem>
      <GridItem>
        {caseIdToLLXRating ? (
          <PercentageChunk
            percentage={Math.floor(
              (caseIdToLLXRating.get(currentCase) || 0.0) * 100
            )}
            title={t('meetings.stats.cases.llxRating.title')}
            onClick={isAdmin ? () => onLLXScoreDetailOpen() : undefined}
          />
        ) : (
          <ChunkContainerLoading />
        )}
      </GridItem>
      <GridItem>
        {caseToTop4Slides ? (
          <BestSlide
            caseIds={caseIds}
            caseTitles={caseTitles}
            caseToTop4Slides={caseToTop4Slides}
            currentCase={currentCase}
          />
        ) : (
          <ChunkContainerLoading />
        )}
      </GridItem>
      <GridItem>
        {bestAssetPerCase ? (
          <BestAsset
            caseTitle={caseTitles[currentCase]}
            asset={bestAssetPerCase.get(currentCase)}
          />
        ) : (
          <ChunkContainerLoading />
        )}
      </GridItem>
      <GridItem>
        <EngagementContributors
          options={[
            {
              title: 'Case duration',
              value: Math.ceil(
                (durationPerCaseInSeconds
                  ? durationPerCaseInSeconds[currentCase]
                  : 0) / 60
              ),
              maxValue: Math.ceil(
                (durationPerCaseInSeconds
                  ? durationPerCaseInSeconds[currentCase]
                  : 0) / 60
              ),
              textOnTheRight: `${Math.ceil(
                (durationPerCaseInSeconds
                  ? durationPerCaseInSeconds[currentCase]
                  : 0) / 60
              )} min.`,
            },
            {
              title: 'Longest slide',
              value: longestSlidePerCaseInSeconds
                ? longestSlidePerCaseInSeconds[currentCase]
                : 0,
              maxValue: durationPerCaseInSeconds
                ? durationPerCaseInSeconds[currentCase]
                : 1,
              textOnTheRight: `${
                longestSlidePerCaseInSeconds
                  ? longestSlidePerCaseInSeconds[currentCase]
                  : 0
              } secs.`,
            },
            {
              title: 'Consensus rate',
              value: Math.floor(consensusRatePerCase[currentCase] * 100),
              maxValue: 100,
              textOnTheRight: `${Math.floor(
                consensusRatePerCase[currentCase] * 100
              )}%`,
            },
          ]}
        />
      </GridItem>
      <GridItem colSpan={isDesktopOrLaptop ? 2 : 1}>
        <Footer />
      </GridItem>
    </>
  );
}

export default SpecificCaseStatsView;
