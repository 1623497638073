import { SlideItemType } from '@/utils/types/enums';
import { InfoSlideType } from '@/utils/types/zod/slideTypes/infoSlideSchema';
import ItemContainer from './ItemContainer';
import ItemListContainer from './ItemListContainer';
import InfoItem from './items/InfoItem';

type Props = {
  slide: InfoSlideType;
  onShowAssets: (assetRefs: string[]) => void;
};

function InfoSlide({ slide, onShowAssets }: Props) {
  return (
    <>
      <ItemListContainer>
        {slide.items.map((item, itemIndex: number) => (
          <ItemContainer key={itemIndex} slide={slide}>
            {item.type === SlideItemType.INFO && (
              <InfoItem
                onMoreInfoCallback={() => {
                  if (item.assetRefs.length > 0) {
                    onShowAssets(item.assetRefs);
                  }
                }}
                item={item}
              />
            )}
          </ItemContainer>
        ))}
      </ItemListContainer>
    </>
  );
}

export default InfoSlide;
