import { Fragment, ReactNode } from 'react';
import {
  Box,
  chakra,
  Flex,
  Stack,
  VStack,
  Divider,
  useColorModeValue,
  Avatar,
  Text,
  Tag,
  HStack,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

import { Meeting, UpdateMeetingMutationVariables } from '@/services/API';
import { FaCopy } from 'react-icons/fa';
import PopConfirm from '@/ui/PopConfirm/PopConfirm';
import { useTranslation } from 'react-i18next';
import { updateMeeting } from '@/services/graphql/mutations';
import { getClientNameFromID } from '@/utils/helpers/getClientNameFromID';
import ClientTag from '@/components/ClientTag/ClientTag';
import useAllClientList from '@/features/clients/hooks/useAllClientList';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { ROUTES } from '@/routes/Routes';

type CopyButtonProps = {
  text: string;
};

function CopyButton({ text }: CopyButtonProps) {
  const toast = useToast();
  const { t } = useTranslation();

  function handleCopy() {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: t('textCopier.title'),
        description: t('textCopier.description').replace('{text}', text),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    });
  }

  return (
    <IconButton
      icon={<FaCopy />}
      aria-label="Copy text"
      size="sm"
      onClick={handleCopy}
      colorScheme="gray"
    />
  );
}

type ListProps = {
  showID?: boolean;
  showMailDomain?: boolean;
  showGuestUrl?: boolean;
  showPreviewCode?: boolean;
  data: Meeting[];
  actions?: Array<{
    render: (data: Meeting) => ReactNode;
  }>;
  title?: string;
  hideDate?: boolean;
};

const MeetingList = ({
  data,
  showID,
  showGuestUrl,
  showPreviewCode,
  showMailDomain,
  actions,
  title,
  hideDate,
}: ListProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const backgroundColor = useColorModeValue('gray.200', 'gray.700');
  const grayColor = useColorModeValue('gray.600', 'gray.300');
  const allClients = useAllClientList();

  const deleteGuestUrlCode = async (meeting: Meeting) => {
    try {
      const variables: UpdateMeetingMutationVariables = {
        input: {
          id: meeting.id,
          guestUrlCode: '',
        },
      };
      await callGraphQLApi(updateMeeting, variables);
    } catch (error) {
      toast({
        status: 'error',
        title: t('common.error'),
        position: 'top',
      });
      console.error(error);
    }
  };

  const deletePreviewCode = async (meeting: Meeting) => {
    try {
      const variables: UpdateMeetingMutationVariables = {
        input: {
          id: meeting.id,
          previewCode: '',
        },
      };
      await callGraphQLApi(updateMeeting, variables);
    } catch (error) {
      toast({
        status: 'error',
        title: t('common.error'),
        position: 'top',
      });
      console.error(error);
    }
  };

  return (
    <>
      {title && (
        <Flex justify="left" mb={3}>
          <chakra.h3 fontSize="2xl" fontWeight="bold" textAlign="center">
            {title}
          </chakra.h3>
        </Flex>
      )}
      <VStack
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        overflow="hidden"
        spacing={0}
        background="white"
      >
        {data.map((el, index) => {
          const archivedProps = !el.isArchived
            ? {}
            : {
                bg: 'gray.200',
                opacity: '0.5',
                _hover: {
                  opacity: '1.0',
                },
              };

          return (
            <Fragment key={index}>
              <Stack
                w="full"
                direction={{ base: 'column', md: 'row' }}
                p={{ base: 4, md: 4 }}
                gap={3}
                justifyContent="space-between"
                bg={el.isDemo ? 'blue.100' : 'white'}
                _hover={{ bg: backgroundColor }}
                position="relative"
                {...archivedProps}
              >
                {el.isArchived && (
                  <Text
                    position="absolute"
                    left="0"
                    right="0"
                    textAlign="center"
                    color="livelinx.red"
                    opacity="1.0"
                  >
                    ARCHIVED
                  </Text>
                )}
                <Box gridColumnEnd={{ base: 'span 2', md: 'unset' }}>
                  <Flex alignItems="center">
                    <Avatar
                      name={el.host?.firstname || 'unknown'}
                      color="white"
                      size="xs"
                    />

                    <Text pl={4} pr={2}>
                      {el.name}
                    </Text>
                    <Tag ml={2} colorScheme="red">
                      {el.caseCluster?.name}
                    </Tag>
                    {el.clientIDs &&
                      allClients &&
                      el.clientIDs.length !== 0 && (
                        <ClientTag
                          client={el.clientIDs
                            .map((clientID) =>
                              getClientNameFromID(allClients, clientID)
                            )
                            .join(', ')}
                        />
                      )}
                  </Flex>

                  <Box ml={5} pl={5}>
                    <HStack>
                      <Text>Host : </Text>
                      <chakra.p
                        fontWeight="medium"
                        fontSize="sm"
                        color={grayColor}
                      >
                        {showMailDomain
                          ? el.host?.firstname
                          : el.host?.firstname?.split('@')[0]}
                      </chakra.p>
                    </HStack>
                    {showID && (
                      <HStack>
                        <Text>ID : </Text>
                        <chakra.p
                          fontWeight="medium"
                          fontSize="sm"
                          color={grayColor}
                        >
                          {el.id}
                        </chakra.p>
                      </HStack>
                    )}
                    {showGuestUrl && el.guestUrlCode && (
                      <HStack>
                        <Text>Guest invitation code : </Text>
                        <chakra.p
                          fontWeight="medium"
                          fontSize="sm"
                          color={grayColor}
                        >
                          {el.guestUrlCode}
                        </chakra.p>
                        <CopyButton
                          text={`${window.location.origin}${ROUTES.LIVE_MEETING}?meeting=${el.id}&guestUrlCode=${el.guestUrlCode}`}
                        />
                        <PopConfirm
                          title={
                            <div>
                              {t('admin.meeting.guestUrl.delete.confirmText')}
                              <br />
                              {t('meeting.header.confirmRestart')}
                            </div>
                          }
                          onConfirm={() => deleteGuestUrlCode(el)}
                          okText={t('common.yes') as string}
                          cancelText={t('common.no') as string}
                        >
                          <IconButton
                            icon={<DeleteIcon />}
                            aria-label="Delete url"
                            size="sm"
                            colorScheme="red"
                          />
                        </PopConfirm>
                      </HStack>
                    )}
                    {showPreviewCode && el.previewCode && (
                      <HStack>
                        <Text>Preview code : </Text>
                        <chakra.p
                          fontWeight="medium"
                          fontSize="sm"
                          color={grayColor}
                        >
                          {el.previewCode}
                        </chakra.p>
                        <CopyButton
                          text={`${window.location.origin}${ROUTES.PREVIEW_MEETING}?meeting=${el.id}&previewCode=${el.previewCode}`}
                        />
                        <PopConfirm
                          title={
                            <div>
                              {t(
                                'admin.meeting.previewCode.delete.confirmText'
                              )}
                              <br />
                              {t('meeting.header.confirmRestart')}
                            </div>
                          }
                          onConfirm={() => deletePreviewCode(el)}
                          okText={t('common.yes') as string}
                          cancelText={t('common.no') as string}
                        >
                          <IconButton
                            icon={<DeleteIcon />}
                            aria-label="Delete url"
                            size="sm"
                            colorScheme="red"
                          />
                        </PopConfirm>
                      </HStack>
                    )}
                    {el.eventDate && (
                      <HStack>
                        <Text>Event date : </Text>
                        <chakra.p
                          fontWeight="medium"
                          fontSize="sm"
                          color={grayColor}
                        >
                          {new Date(el.eventDate).toLocaleString()}{' '}
                        </chakra.p>
                      </HStack>
                    )}
                    {el.createdAt && hideDate && (
                      <HStack>
                        <Text> CREATION DATE :</Text>
                        <chakra.p
                          fontWeight="medium"
                          fontSize="sm"
                          color={grayColor}
                        >
                          {new Date(el.createdAt).toLocaleString()}
                        </chakra.p>
                      </HStack>
                    )}
                  </Box>
                </Box>

                <Stack
                  spacing={2}
                  direction="row"
                  fontSize={{ base: 'sm' }}
                  justifySelf="flex-end"
                  alignItems="right"
                >
                  {actions &&
                    actions.map((component, index: number) => (
                      <Fragment key={index}>{component.render(el)}</Fragment>
                    ))}
                </Stack>
              </Stack>
              {data.length - 1 !== index && <Divider m={0} />}
            </Fragment>
          );
        })}
      </VStack>
    </>
  );
};

export default MeetingList;
