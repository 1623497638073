import { CaseMap } from '@/features/caseCluster/caseCluster.types';
import { GetCaseIndexByCaseID } from '@/features/caseCluster/helpers/caseCluster.helpers';
import useCaseContent from '@/features/caseCluster/hooks/useCaseClusterContent';
import useMeetingStatsData from '@/features/meeting-stats/hooks/useMeetingStatsData';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { CaseCluster, Meeting, User } from '@/services/API';
import { useUserListByMeeting } from '@/services/hooks/useUserListByMeeting';
import useMeetingSubscription from '@/services/subscriptions/useMeetingSubscription';
import { MeetingSelectors } from '@/store/slices/meeting';
import { useAppSelector } from '@/store/StoreHooks';
import { NewIAsset } from '@/utils/types/zod/assetSchema';
import { NewIPatientCase } from '@/utils/types/zod/patientCaseSchema';
import { Variation } from '@/utils/types/zod/variationSchema';
import { createContext, useContext, useMemo } from 'react';
import useMeetingDebugger from '../answer/hooks/useMeetingDebugger';
import useMeetingInteractionFunctions from '../hooks/useMeetingInteractionFunctions';

type Props = {
  children: React.ReactNode;
  forcedPatientCase?: NewIPatientCase;
  isPreview?: boolean;
};

type CurrentMeetingDataContextType = {
  meeting: Meeting | undefined;
  user: User | null | undefined;
  hostID: string | undefined;
  host: User | null | undefined;
  caseCluster: CaseCluster | null | undefined;
  caseClusterName: string | undefined;
  caseMap: CaseMap;
  currentCase: NewIPatientCase | undefined;
  currentCaseId: string | null | undefined;
  currentCaseIndex: number;
  isCaseLoading: boolean;
  slideIndex: number | null | undefined;
  slideID: string | null | undefined;
  slideName: string | undefined;
  isUserHost: boolean;
  meetingStatsData: ReturnType<typeof useMeetingStatsData>;
  assetList: NewIAsset[];
  variationList: Variation[];
  connectedUserList: ReturnType<typeof useUserListByMeeting>;
  meetingInteractions: ReturnType<typeof useMeetingInteractionFunctions>;
  isPreview: boolean;
};

export const CurrentMeetingDataContext = createContext<
  CurrentMeetingDataContextType | undefined
>(undefined);

export default function CurrentMeetingDataContextProvider({
  children,
  forcedPatientCase,
  isPreview,
}: Props) {
  const { user: currentUser } = useUserAuthenticationContext();
  const currentMeeting = useAppSelector(MeetingSelectors.getCurrentMeeting);
  const caseCluster = currentMeeting?.caseCluster;
  const { caseMap, isCaseLoading } = useCaseContent(caseCluster?.cases || []);
  const userID = currentUser?.id;
  const hostID = currentMeeting?.hostID;
  const currentCaseIndex = GetCaseIndexByCaseID(
    currentMeeting?.currentCase,
    caseCluster
  );
  let currentCase: NewIPatientCase | undefined =
    caseMap && currentMeeting && currentMeeting.currentCase
      ? caseMap[currentMeeting.currentCase]
      : undefined;
  if (forcedPatientCase) {
    currentCase = forcedPatientCase;
  }
  const assetList: NewIAsset[] = currentCase?.assets || [];
  const variationList: Variation[] = currentCase?.variations || [];
  let isUserHost = userID && hostID && hostID === userID ? true : false;
  if (isPreview) {
    isUserHost = true;
  }
  const meetingStatsData = useMeetingStatsData(currentMeeting?.id, true);
  const currentSlide = useMemo(() => {
    if (!currentCase || !currentMeeting) return undefined;
    return currentCase.slides[currentMeeting.currentSlide || 0];
  }, [currentCase, currentMeeting?.currentSlide]);
  const connectedUserList = useUserListByMeeting(currentMeeting?.id, true);

  const meetingInteractions = useMeetingInteractionFunctions(
    currentMeeting,
    isPreview ?? false
  );

  // use meeting subscription to track changes in meeting at runtime
  useMeetingSubscription();
  //NOTE: we don't need sync checks during preview
  useMeetingDebugger(isPreview ? undefined : currentMeeting);

  return (
    <CurrentMeetingDataContext.Provider
      value={{
        meeting: currentMeeting,
        user: currentUser,
        hostID: currentMeeting?.hostID,
        host: currentMeeting?.host,
        caseCluster,
        caseClusterName: caseCluster?.name,
        caseMap,
        currentCase,
        currentCaseId: currentMeeting?.currentCase,
        currentCaseIndex,
        isCaseLoading: isCaseLoading || !currentUser,
        slideIndex: currentMeeting?.currentSlide,
        slideName: currentSlide ? currentSlide.title : undefined,
        slideID: currentSlide ? currentSlide.id : undefined,
        isUserHost,
        meetingStatsData,
        assetList,
        variationList,
        connectedUserList,
        meetingInteractions,
        isPreview: isPreview ?? false,
      }}
    >
      {children}
    </CurrentMeetingDataContext.Provider>
  );
}

export const useCurrentMeetingDataContext = () => {
  const context = useContext(CurrentMeetingDataContext);
  if (context === undefined) {
    throw new Error(
      'useCurrentMeetingDataContext must be used within a CurrentMeetingDataContextProvider'
    );
  }
  return context;
};
