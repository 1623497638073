export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  LIVE_MEETING: '/live',
  MEETING_STATS: '/meetingstats',
  PREVIEW_MEETING: '/preview',
  PREVIEW_CASE: '/previewCase',

  ADMIN: '/admin',
  ADMIN_CASE_LIST: '/admin/allcases',
  ADMIN_CASE_EDIT: '/admin/case',
  // ADMIN_CASE_VIEW: '/admin/view/case',
  ADMIN_TEST: '/admin/test',

  ADMIN_USER_LIST: '/admin/allusers',

  ADMIN_CLIENTS: '/admin/clients',
  ADMIN_MEETING_LIST: '/admin/allmeetings',
  ADMIN_CASE_CLUSTER_LIST: '/admin/caseClusters',
};
