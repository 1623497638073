import { InfoItemStyled } from './CaseSlideItem.style';
import { EyeOutlined } from '@ant-design/icons';
import { Box, Heading } from '@chakra-ui/react';
import { InfoSlideItemType } from '@/utils/types/zod/slideTypes/slideItemTypes/infoSlideItemSchema';
import { getAssetByRefOrThrow } from '@/pages/caseCluster/utils/getAssetByRef';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';

type Props = {
  item: InfoSlideItemType;
  onMoreInfoCallback: (item: InfoSlideItemType) => void;
};

const InfoItem = ({ item, onMoreInfoCallback }: Props) => {
  const { assetList } = useCurrentMeetingDataContext();
  const thumbUrl =
    item.assetRefs.length > 0
      ? getAssetByRefOrThrow(item.assetRefs[0], assetList).thumbUrl
      : undefined;

  return (
    <InfoItemStyled
      data-test="slide-item"
      item={item}
      onClick={() => item.assetRefs && onMoreInfoCallback?.(item)}
    >
      {
        //-- CASE Thumb
        thumbUrl ? (
          <>
            <div className="thumbContainer noselect">
              <img className="thumb nodrag" alt={item.title} src={thumbUrl} />
            </div>
            {/* // TODO: reset bookmark funcitonality when developped */}
            {/* <div className="bookmark" /> */}
            <Heading
              color="livelinx.purple200"
              padding="5px 35px 0px 5px"
              // fontSize={{ base: '0.8em', md: '1.4em', lg: '0.8em' }}
              fontSize="0.8em"
              width="100%"
              className="title"
            >
              {item.title}
            </Heading>
            <Box
              color="livelinx.black100"
              padding="0px 5px 15px 5px"
              fontSize="0.7em"
              paddingBottom="10px"
              className="description"
            >
              {item.assetRefs &&
                item.assetRefs.length > 1 &&
                `${item.assetRefs.length} documents`}
              {item.assetRefs &&
                item.assetRefs.length <= 1 &&
                `${item.assetRefs.length} document`}
            </Box>
            <Box
              visibility="hidden"
              transition=" all 0.3s ease-out"
              position="absolute"
              top="0px"
              left="0px"
              width="100%"
              height="80%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontSize="2em"
              color="primary"
              backgroundColor="#ffffff84"
              opacity="0"
              borderRadius="10px"
              userSelect="none"
              _hover={{
                visibility: 'visible',
                opacity: 1,
              }}
            >
              <EyeOutlined />
            </Box>
          </>
        ) : (
          // -- CASE Text Only
          <div className="title_noThumb noselect">
            <div className="content">{item.title}</div>
          </div>
        )
      }
    </InfoItemStyled>
  );
};

export default InfoItem;
