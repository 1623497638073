import {
  UpdateUserMeetingJoinMutation,
  UpdateUserMeetingJoinMutationVariables,
  UserMeetingJoinByUserIDQuery,
  UserMeetingJoinByUserIDQueryVariables,
} from '@/services/API';
import { updateUserMeetingJoin } from '@/services/graphql/mutations';
import { userMeetingJoinByUserID } from '@/services/graphql/queries';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { CaptureSentryMessage } from '@/utils/helpers/CaptureSentryException';
import { GraphQLResult } from '@aws-amplify/api-graphql';

export async function mutationUpdateLeaveUserMeetingJoin(
  userID: string,
  meetingID: string,
  isCurrentlyInMeeting: boolean
) {
  const queryVariables: UserMeetingJoinByUserIDQueryVariables = {
    userID,
  };
  const alluserMeetingJoin = await callGraphQLApi<
    GraphQLResult<UserMeetingJoinByUserIDQuery>
  >(userMeetingJoinByUserID, queryVariables);
  const userMeetingJoin =
    alluserMeetingJoin.data?.userMeetingJoinByUserID?.items.find(
      (elem) => elem?.meetingID === meetingID
    );

  if (!userMeetingJoin) {
    CaptureSentryMessage(
      `mutationUpdateUserMeetingJoin: userMeetingJoinByUserID is empty`,
      {
        userMeetingJoinByUserID: userMeetingJoin,
      }
    );
    return;
  }
  const updateVariables: UpdateUserMeetingJoinMutationVariables = {
    input: {
      id: userMeetingJoin.id,
      isCurrentlyInMeeting,
    },
  };
  await callGraphQLApi<GraphQLResult<UpdateUserMeetingJoinMutation>>(
    updateUserMeetingJoin,
    updateVariables
  );
}
