import CaseTable from '@/pages/admin/components/CaseTable/CaseTable';
const CaseListView = () => {
  return (
    <>
      <CaseTable />
    </>
  );
};

export default CaseListView;
