import { actions } from '@/store/slices/caseCluster';
import { AppDispatch } from '@/store/Store';
import { CaptureSentryMessage } from '@/utils/helpers/CaptureSentryException';
import {
  UnloadedIPatientCase,
  unloadedIPatientCaseSchema,
} from '@/utils/types/zod/patientCaseSchema';
import { ZodError } from 'zod';
import APP_CONSTANTS from '@/utils/constants/app.constants';

export async function pureFetchCaseList(): Promise<UnloadedIPatientCase[]> {
  const response = await fetch(
    `${
      APP_CONSTANTS.PATIENT_CASES_REPO_PATH
    }caseList.json?nocache=${new Date().getTime()}`
  );
  const responseJson = await response.json();

  return responseJson;
}

export function fetchCaseList() {
  return async (dispatch: AppDispatch) => {
    dispatch(actions.setLoading(true));
    try {
      const responseJson = await pureFetchCaseList();
      for (const unloadedCase of responseJson) {
        unloadedIPatientCaseSchema.parse(unloadedCase);
      }
      dispatch(actions.setCaseList(responseJson));
      dispatch(actions.setLoading(false));
    } catch (error) {
      if (error instanceof ZodError) {
        console.error(
          `Validation of unloadedPatientCase failed while fetching caseList`
        );
        CaptureSentryMessage(
          'Validation of unloadedPatientCase failed while fetching caseList'
        );
        return;
      }
      dispatch(actions.setError(`caseListFetchError:${error}`));
    }
  };
}
