/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { Meeting } from '../../services/API';
import { RootState } from '@/store/Store';
import { IsLocalHost } from '@/utils/helpers/isLocalHost';

// --------------------- slice ------------------------

export interface MeetingState {
  status: string | null;
  error: any;
  //NOTE: meetings in database
  meetingList: Meeting[];
  //NOTE: meetings just in memory (for preview for example)
  localMeetingList: Meeting[];
  currentMeetingID: string | null;
}
const initialState: MeetingState = {
  status: null,
  error: null,
  meetingList: [],
  localMeetingList: [],
  currentMeetingID: null,
};

export const Slice = createSlice({
  name: 'meeting',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.status = action.payload ? null : 'error';
      state.error = action.payload.message;
      if (IsLocalHost()) alert(`Error in meetingSlice: ${action.payload}`);
      console.error('Error in meetingSlice: ', action.payload);
    },
    setMeetingList: (state, action: PayloadAction<Array<Meeting>>) => {
      state.meetingList = action.payload;
      state.status = null;
      state.error = null;
    },
    newMeeting: (state, action: PayloadAction<Meeting>) => {
      state.meetingList.push(action.payload);
      state.status = null;
      state.error = null;
    },
    newLocalMeeting: (state, action: PayloadAction<Meeting>) => {
      state.localMeetingList.push(action.payload);
      state.status = null;
      state.error = null;
    },
    receivedMeetingUpdate(state, actions: PayloadAction<Meeting>) {
      const meeting = actions.payload;
      const databaseIndex = state.meetingList.findIndex(
        (item) => item.id === meeting.id
      );
      const localIndex = state.localMeetingList.findIndex(
        (item) => item.id === meeting.id
      );
      console.log('databaseIndex, localIndex', databaseIndex, localIndex);
      //if meeting is in database
      if (databaseIndex !== -1) {
        state.meetingList[databaseIndex] = meeting;
        //if meeting doesn't exist anywhere, suppose that it's a database meeting
      } else if (databaseIndex === -1 && localIndex === -1) {
        state.meetingList.push(meeting);
      } else if (localIndex !== -1) {
        state.localMeetingList[localIndex] = meeting;
      }
      state.status = null;
      state.error = null;
    },
    deleteMeeting: (state, action: PayloadAction<Meeting>) => {
      const meeting = action.payload;
      state.meetingList = state.meetingList.filter(
        (item) => item.id !== meeting.id
      );
      state.localMeetingList = state.localMeetingList.filter(
        (item) => item.id !== meeting.id
      );
      state.status = null;
      state.error = null;
    },
    updateCurrentMeetingInfo: (state, action: PayloadAction<string | null>) => {
      state.currentMeetingID = action.payload;
      state.status = null;
      state.error = null;
    },
  },
});

// --------------------- selectors ------------------------

// eslint-disable-next-line no-unused-vars
const selectState: (rootState: RootState) => MeetingState = (rootState) =>
  rootState.meeting;
const getMeetingListState = (rootState: RootState) => selectState(rootState);
const getMeetingList = createSelector(
  getMeetingListState,
  (meetingState) => meetingState.meetingList
);
const getMeetingListWithLocalMeetings = createSelector(
  getMeetingListState,
  (meetingState) => [
    ...meetingState.meetingList,
    ...meetingState.localMeetingList,
  ]
);
const getCurrentMeetingID = (rootState: RootState) =>
  selectState(rootState).currentMeetingID;
const getError = (rootState: RootState) => selectState(rootState).error;

const getCurrentMeeting = createSelector(
  getMeetingListWithLocalMeetings,
  getCurrentMeetingID,
  (meetingList, meetingID) =>
    meetingList.find((meeting) => meeting.id === meetingID)
);

// --------------------- actions ------------------------

// --------------------- exports ------------------------

const { actions } = Slice;
export { actions };

export const MeetingSelectors = {
  getMeetingList,
  getCurrentMeeting,
  getError,
};

export default Slice.reducer;
