import { CaseSlideStats } from '@/services/API';
import { CaptureSentryMessage } from '@/utils/helpers/CaptureSentryException';
// import { CaptureSentryException } from '@/utils/helpers/CaptureSentryException';
import { NewISlide } from '@/utils/types/zod/slideTypes/slideSchema';

export type ParsedSlideStats = {
  id: string;
  index: number;
  title: string;
  time: number;
};

export type ParsedSlideStatsPerCase = Record<string, ParsedSlideStats[]>;

export function parseSlideStatsPerCase(
  cases: string[],
  slideList: Record<string, NewISlide[]>,
  slideStatsPerCase: Record<string, CaseSlideStats[]>
) {
  const parsedSlideStatsPerCase: Record<string, ParsedSlideStats[]> = {};
  for (const c of cases) {
    const slideStats = slideStatsPerCase[c].map((item) => {
      const slideInfo = slideList[c].find((slide) => slide.id === item.slideId);

      if (slideInfo) {
        return {
          id: slideInfo.id,
          index: slideList[c].indexOf(slideInfo),
          time: item.time,
          title: slideInfo.title,
        };
      }
      //this means it's statistics about an old slide that got deleted
      //NOTE: This is not an error
      console.warn(
        'TODO Later filter old slides before it reaches this code',
        item
      );
      CaptureSentryMessage(
        'parseSlideStatsPerCase: Slide not found, it is probably a deleted slide',
        {
          caseId: c,
          slideId: item.slideId,
        }
      );
      return {
        id: '0',
        index: 0,
        time: item.time,
        title: item.slideId,
      };
    });
    slideStats.sort((a, b) => b.time - a.time);
    parsedSlideStatsPerCase[c] = slideStats;
  }

  return parsedSlideStatsPerCase;
}
