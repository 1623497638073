import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

function ItemListContainer({ children, ...other }: Props & FlexProps) {
  return (
    <Flex
      justifyContent="space-evenly"
      flexWrap="wrap"
      alignItems="center"
      alignContent="center"
      height="100%"
      width="100%"
      p="10px"
      data-test="slide-item-container"
      {...other}
    >
      {children}
    </Flex>
  );
}

export default ItemListContainer;
