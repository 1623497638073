import useAllClientList from '@/features/clients/hooks/useAllClientList';
import { Client } from '@/services/API';
import useAllClientsSubscription from '@/services/subscriptions/useAllClientsSubscription';
import {
  Button,
  Heading,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import Table from '@/ui/Table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import CreateClientForm from './components/CreateClientForm/CreateClientForm';
import PopConfirm from '@/ui/PopConfirm/PopConfirm';
import { DeleteOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@/store/StoreHooks';
import { deleteAClient } from '@/store/thunk/clients';
import { MeetingSelectors } from '@/store/slices/meeting';

export default function ClientsView() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const clientsList = useAllClientList();
  const meetingList = useAppSelector(MeetingSelectors.getMeetingList);
  useAllClientsSubscription();

  const {
    isOpen: isFormOpen,
    onOpen: openForm,
    onClose: closeForm,
  } = useDisclosure();

  const handleDeleteClient = (client: Client) => {
    dispatch(deleteAClient(client.clientName));
  };

  const columns: Column<Client>[] = [
    {
      Header: t('admin.clients.table.clientname.columnTitle') as string,
      accessor: (item: Client) => (
        <Heading size="md">{item.clientName}</Heading>
      ),
    },
    {
      Header: t('common.createdAt') as string,
      accessor: (item: Client) =>
        item.createdAt ? new Date(item.createdAt).toLocaleString() : 'No date',
    },
    {
      Header: t('common.action') as string,
      accessor: (item: Client) => (
        <PopConfirm
          title={
            <div>
              {t('admin.clients.delete.confirmText')}
              <br />
              {t('meeting.header.confirmRestart')}
            </div>
          }
          onConfirm={() => handleDeleteClient(item)}
          okText={t('common.yes') as string}
          cancelText={t('common.no') as string}
        >
          <Button
            disabled={meetingList.some((el) => el.clientIDs?.includes(item.id))}
            variant="danger"
            key="delete"
            size="sm"
            rightIcon={<DeleteOutlined />}
          >
            {t('common.delete')}
          </Button>
        </PopConfirm>
      ),
    },
  ];

  return (
    <>
      <HStack justifyContent="space-between" pb="12px">
        <Heading size="md">{t('admin.clients.clientsList.title')}: </Heading>
        <Popover
          placement="bottom-start"
          isOpen={isFormOpen}
          onClose={closeForm}
        >
          <PopoverTrigger>
            <Button
              background="blue.400"
              color="white"
              size="md"
              onClick={openForm}
            >
              {t('admin.clients.button.createNew')}
            </Button>
          </PopoverTrigger>
          <PopoverContent w="auto" padding="10px" boxShadow="lg">
            <PopoverBody>
              <CreateClientForm closeForm={closeForm} />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </HStack>

      {clientsList && (
        <Table columns={columns} data={clientsList} useSort={true} />
      )}
    </>
  );
}
