import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/store/StoreHooks';
import {
  CaseCluster,
  CreateMeetingInput,
  Meeting,
  UpdateMeetingInput,
  User,
} from '@/services/API';
import { useForm } from 'react-hook-form';
import { updateAMeeting, createAMeeting } from '@/store/thunk/meeting';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Button,
  Select,
  VStack,
  Spacer,
  Checkbox,
  Text,
  HStack,
  Button as ChakraButton,
  Switch,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import useAllClientList from '@/features/clients/hooks/useAllClientList';
import ItemList from '@/ui/ItemList/ItemList';
import { getClientNameFromID } from '@/utils/helpers/getClientNameFromID';
import { MEETING_DEFAULT_LOCATION } from '@/features/meeting/helpers/constants';
import { MeetingTypes } from '@/features/caseCluster/caseCluster.types';
import { IsLocalHost } from '@/utils/helpers/isLocalHost';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import {
  DEFAULT_HOST_NAME,
  DEFAULT_HOST_VALUE,
} from '@/utils/constants/app.constants';
import useMeetingUserAccessForMeeting from '@/services/hooks/useMeetingUserAccessForMeeting';

type Props = {
  user: User;
  userList: User[];
  caseClusterlist: CaseCluster[];
  meeting?: Meeting; // if we have a meeting, we are in edition mode or in duplicate mode
  isDuplicateMeeting?: boolean;
  closeMeetingForm: () => void;
};

type FormData = {
  name: string;
  caseClusterID: string;
  date: string;
  host: string;
  hostFullName: string;
  //This is just so that the form would accept this input, but it is not used, the useState is used
  meetingClients: string[];
  generateGuestUrl: boolean;
  generatePreviewCode: boolean;
  isDemo: boolean;
  location: string;
  customNotes: string;
  purchaseOrderNbr: string;
  meetingType: string;
};

const generateCode = () => {
  //random string of 6 characters
  return Math.random().toString(36).substring(2, 8);
};

const CreateMeetingForm = ({
  userList,
  caseClusterlist,
  meeting,
  isDuplicateMeeting,
  closeMeetingForm,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { user } = useUserAuthenticationContext();
  const allClients = useAllClientList();
  const { userAccessList } = useMeetingUserAccessForMeeting(meeting?.id);

  //list of the ids of the clients that are part of the meeting
  const [clientsAddedList, setClientsAddedList] = useState<string[]>(
    //Note: This function maps the clientIDs to clientNames and check for undefined values
    meeting?.clientIDs?.map((elem) => {
      const name = allClients
        ? getClientNameFromID(allClients, elem)
        : undefined;
      return name ? name : elem;
    }) || []
  );
  const [selectedClient, setSelectedClient] = useState<string | undefined>(
    undefined
  );
  const [eventDate, setEventDate] = useState<Date>(
    meeting ? new Date(meeting.eventDate) : new Date()
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  // --------------------- handlers ------------------------

  const onSubmit = async (values: FormData) => {
    if (!allClients) {
      throw new Error('allClients is undefined, this should not happen');
    }
    const clientsAddedListIDS: string[] = [];

    clientsAddedList.forEach((clientName) => {
      const found = allClients.find((elem) => elem.clientName === clientName);
      if (found) {
        clientsAddedListIDS.push(found.id);
      }
    });

    if (!meeting || isDuplicateMeeting) {
      const meetingInput: CreateMeetingInput = {
        name: values.name,
        caseClusterID: values.caseClusterID,
        hostID: values.host,
        hostFullName: values.hostFullName,
        eventDate: eventDate.toISOString(),
        eventLocation: values.location,
        clientIDs: clientsAddedListIDS,
        purchaseOrderNbr: values.purchaseOrderNbr,
        meetingNotes: values.customNotes,
        currentSlide: 0,
        isMock: false,
        isInTesting: false,
        isArchived: false,
        meetingType: values.meetingType,
        isDemo: values.isDemo,
      };
      if (values.generateGuestUrl) meetingInput.guestUrlCode = generateCode();
      if (values.generatePreviewCode) meetingInput.previewCode = generateCode();
      dispatch(
        createAMeeting(
          meetingInput,
          isDuplicateMeeting
            ? userAccessList.map((val) => val.userID)
            : undefined
        )
      );
      closeMeetingForm();
    } else {
      const meetingUpdate: UpdateMeetingInput = {
        id: meeting.id,
        name: values.name,
        caseClusterID: values.caseClusterID,
        hostID: values.host,
        hostFullName: values.hostFullName,
        eventDate: eventDate.toISOString(),
        eventLocation: values.location,
        clientIDs: clientsAddedListIDS,
        purchaseOrderNbr: values.purchaseOrderNbr,
        meetingNotes: values.customNotes,
        meetingType: values.meetingType,
      };
      if (values.generateGuestUrl) {
        meetingUpdate.guestUrlCode = generateCode();
      }
      if (values.generatePreviewCode) {
        meetingUpdate.previewCode = generateCode();
      }
      dispatch(updateAMeeting(meetingUpdate));
      closeMeetingForm();
    }
  };

  const removeClient = (client: string) => {
    setClientsAddedList((prev) => prev.filter((item) => item !== client));
  };

  const addClient = () => {
    if (selectedClient) {
      setClientsAddedList((prev) => [...prev, selectedClient]);
    }
  };

  // --------------------- effects ------------------------

  useEffect(() => {
    //reset  fields when component unmounts
    return () => reset();
  }, [closeMeetingForm]);

  useEffect(() => {
    const defaultValues: { host?: string } = {};
    if (meeting) {
      defaultValues.host = meeting.hostID;
      reset({ ...defaultValues });
    } else reset({ ...defaultValues });
  }, [meeting]);

  // const onSubmitFailed = (errorInfo: any) => {
  //   openToast({
  //     type: 'error',
  //     message: `${t('warning.meetingFormFailedToCreate')} :${
  //       errorInfo as string
  //     }`,
  //   });
  // };

  // --------------------- render ------------------------

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack gap="10px" pb="10px">
          <FormControl>
            <FormLabel fontSize="sm" fontWeight="normal" mb="1">
              {t('admin.createMeeting.form.meetingClients.label')}
            </FormLabel>

            <HStack>
              <Select
                {...register('meetingClients', { required: false })}
                placeholder={
                  t('admin.createMeeting.form.meetingClients.placeholder') ||
                  'Click to add a client'
                }
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
              >
                {allClients &&
                  allClients.map((client) => {
                    return clientsAddedList.includes(
                      client.clientName
                    ) ? null : (
                      <option key={client.id} value={client.clientName}>
                        {client.clientName}
                      </option>
                    );
                  })}
              </Select>

              <ChakraButton
                background="blue.400"
                color="white"
                size="md"
                onClick={() => addClient()}
              >
                {t('common.add')}
              </ChakraButton>
            </HStack>

            <VStack p={2} align="flex-start">
              {clientsAddedList && (
                <ItemList
                  itemList={clientsAddedList}
                  removeItemFunc={removeClient}
                />
              )}
              {clientsAddedList.length === 0 && (
                <Text>No clients added yet.</Text>
              )}
            </VStack>
          </FormControl>

          <FormControl isRequired isInvalid={Boolean(errors.name)}>
            <FormLabel fontSize="sm" fontWeight="normal" mb="1">
              {t('admin.createMeeting.form.meetingName.label')}
            </FormLabel>
            <Input
              type="text"
              {...register('name', { required: true })}
              placeholder="Name"
              defaultValue={
                (meeting && meeting.name) ||
                (IsLocalHost() ? `Meeting - ${new Date().toISOString()}` : '')
              }
            />
            {errors.name && (
              <FormHelperText color="red">
                {t('admin.createMeeting.form.meetingName.error')}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            isRequired
            isInvalid={!meeting && Boolean(errors.caseClusterID)}
          >
            <FormLabel fontSize="sm" fontWeight="normal" mb="1">
              {t('admin.createMeeting.form.caseCluster.label')}
            </FormLabel>
            <Select
              data-test="meeting-caseCluster-dropdDown"
              {...register('caseClusterID', {
                required: !(meeting && !isDuplicateMeeting),
              })}
              defaultValue={meeting && meeting.caseClusterID}
              isDisabled={Boolean(meeting && !isDuplicateMeeting)}
            >
              {caseClusterlist &&
                caseClusterlist.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </Select>

            {errors.caseClusterID && (
              <FormHelperText color="red">
                {t('admin.createMeeting.form.meetingCaseCluster.error')}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={Boolean(errors.host)}>
            <FormLabel fontSize="sm" fontWeight="normal" mb="1">
              {t('admin.createMeeting.form.host.label')}
            </FormLabel>
            <Select
              data-test="meeting-host-dropdDown"
              {...register('host', {
                required: true,
                validate: (value) => {
                  if (value === DEFAULT_HOST_VALUE) return false;
                  return true;
                },
              })}
              defaultValue={
                IsLocalHost()
                  ? user?.id || DEFAULT_HOST_VALUE
                  : DEFAULT_HOST_VALUE
              }
            >
              <option key={DEFAULT_HOST_VALUE} value={DEFAULT_HOST_VALUE}>
                {t('admin.createMeeting.form.host.placeholder')}
              </option>
              {userList &&
                userList
                  .sort((a, b) => (a.email < b.email ? -1 : 1)) // sort ascending
                  .map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.email}
                    </option>
                  ))}
            </Select>

            {errors.host && (
              <FormHelperText color="red">
                {t('admin.createMeeting.form.meetingHost.error')}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={Boolean(errors.hostFullName)}>
            <FormLabel fontSize="sm" fontWeight="normal" mb="1">
              {t('admin.createMeeting.form.hostFullName.label')}
            </FormLabel>
            <Input
              type="text"
              {...register('hostFullName', { required: true })}
              placeholder="John Doe"
              defaultValue={
                meeting?.hostFullName ||
                (IsLocalHost() ? DEFAULT_HOST_NAME : '')
              }
            />
            {errors.hostFullName && (
              <FormHelperText color="red">
                {t('admin.createMeeting.form.hostFullName.error')}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={Boolean(errors.location)}>
            <FormLabel fontSize="sm" fontWeight="normal" mb="1">
              {t('admin.createMeeting.form.meetingLocation.label')}
            </FormLabel>
            <Input
              type="text"
              {...register('location', { required: true })}
              placeholder="Location"
              defaultValue={meeting?.eventLocation || MEETING_DEFAULT_LOCATION}
            />
            {errors.location && (
              <FormHelperText color="red">
                {t('admin.createMeeting.form.meetingLocation.error')}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={Boolean(errors.date)}>
            <FormLabel fontSize="sm" fontWeight="normal" mb="1">
              {t('admin.createMeeting.form.eventDate.label')}
            </FormLabel>
            <div
              style={{
                border: '1px solid #E2E8F0',
                borderRadius: '5px',
                paddingTop: '7px',
                paddingBottom: '7px',
                paddingLeft: '15px',
              }}
            >
              <DatePicker
                selected={eventDate} // Use your state value here
                onChange={(date: Date) => setEventDate(date)} // Update the state when the date changes
                dateFormat="yyyy-MM-dd" // Customize the date format as needed
              />
            </div>
            {errors.date && (
              <FormHelperText color="red">
                {t('admin.createMeeting.form.eventDate.error')}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={Boolean(errors.purchaseOrderNbr)}>
            <FormLabel fontSize="sm" fontWeight="normal" mb="1">
              {t('admin.createMeeting.form.purchaseOrderNbr.label')}
            </FormLabel>
            <Input
              type="text"
              {...register('purchaseOrderNbr', { required: true })}
              defaultValue={
                (meeting && meeting.purchaseOrderNbr) ||
                (IsLocalHost() ? 'PO-111' : '')
              }
            />
            {errors.purchaseOrderNbr && (
              <FormHelperText color="red">
                {t('admin.createMeeting.form.purchaseOrderNbr.error')}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            isRequired
            isInvalid={!meeting && Boolean(errors.meetingType)}
          >
            <FormLabel fontSize="sm" fontWeight="normal" mb="1">
              {t('admin.createMeeting.form.meetingType.label')}
            </FormLabel>
            <Select
              data-test="meeting-meetingType-dropdDown"
              {...register('meetingType', { required: true })}
              defaultValue={meeting && meeting.meetingType}
            >
              {Object.values(MeetingTypes).map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Select>

            {errors.meetingType && (
              <FormHelperText color="red">
                {t('admin.createMeeting.form.meetingType.error')}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors.customNotes)}>
            <FormLabel fontSize="sm" fontWeight="normal" mb="1">
              {t('admin.createMeeting.form.customNotes.label')}
            </FormLabel>
            <Input
              type="text"
              {...register('customNotes')}
              defaultValue={(meeting && meeting.meetingNotes) || ''}
            />
            {errors.customNotes && (
              <FormHelperText color="red">
                {t('admin.createMeeting.form.customNotes.error')}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl>
            <FormLabel fontSize="sm" fontWeight="normal" mb="1">
              {t('admin.createMeeting.form.demo.label')}
            </FormLabel>
            <Switch
              {...register('isDemo', { required: false })}
              disabled={!!meeting || isDuplicateMeeting}
              defaultChecked={meeting?.isDemo || false}
              style={{
                alignItems: 'center',
                display: 'flex',
                // justifyContent: 'center',
              }}
            >
              <Text opacity={!!meeting || isDuplicateMeeting ? 0.6 : 1}>
                {t('admin.createMeeting.form.demo.text')}
              </Text>
            </Switch>
          </FormControl>

          <FormControl>
            <FormLabel fontSize="sm" fontWeight="normal" mb="1">
              {t('admin.createMeeting.form.guestUrl.label')}
            </FormLabel>
            <Checkbox {...register('generateGuestUrl', { required: false })}>
              {t('admin.createMeeting.form.guestUrl.text')}
            </Checkbox>
          </FormControl>

          <FormControl>
            <FormLabel fontSize="sm" fontWeight="normal" mb="1">
              {t('admin.createMeeting.form.previewCode.label')}
            </FormLabel>
            <Checkbox {...register('generatePreviewCode', { required: false })}>
              {t('admin.createMeeting.form.previewCode.text')}
            </Checkbox>
          </FormControl>

          <Spacer h="5px" />

          <Button
            onClick={handleSubmit(onSubmit)}
            p="2"
            width="full"
            background="blue.400"
            color="white"
            variant="solid"
          >
            {meeting
              ? isDuplicateMeeting
                ? t('common.duplicate')
                : t('common.update')
              : t('common.create')}
          </Button>
        </VStack>
      </form>
    </>
  );
};

export default CreateMeetingForm;
