import { CaseStats } from '@/services/API';
import { useState, useCallback } from 'react';
import { mutationCreateCaseAssetView } from '../meeting-stats/graphql/create';
import { useUserAuthenticationContext } from '../userAuth/context/UserAuthenticationContext';
import { trackEvent } from './trackingHelpers';
import { CaptureSentryMessage } from '@/utils/helpers/CaptureSentryException';

export default function useMeetingTrackingAssetStats(
  currentCaseId: string | undefined | null,
  caseStatsDetails: Record<string, CaseStats>
) {
  //this is the time that the user started watching the asset
  const [startWatchTime, setStartWatchTime] = useState<number>(0);
  const [currentAssetID, setCurrentAssetID] = useState<string | null>(null);
  const { user } = useUserAuthenticationContext();

  const userId = user?.id;

  const trackAssetOpen = useCallback(
    (assetID: string, assetName: string, _isLibraryAsset = false) => {
      trackEvent('Asset', assetName);

      //you can not look at multiple assets at the same time
      if (currentAssetID) trackAssetClose();
      console.log('Stats | asset opened:', assetID);
      setStartWatchTime(Date.now());
      setCurrentAssetID(assetID);
    },
    [currentAssetID]
  );

  const trackAssetClose = () => {
    if (
      !currentCaseId ||
      !caseStatsDetails ||
      !caseStatsDetails[currentCaseId]
    ) {
      console.warn('Couldnt createAssetView statistic');
      CaptureSentryMessage(
        'MeetingTrackingAssetStats: Couldnt createAssetView statistic',
        {
          currentCaseId: currentCaseId,
          caseStatsDetails: caseStatsDetails,
        }
      );
      return;
    }
    const secondsWatched = Math.round((Date.now() - startWatchTime) / 1000);
    console.log('Stats | asset closed:', currentAssetID, secondsWatched);
    mutationCreateCaseAssetView(
      caseStatsDetails[currentCaseId].id,
      currentAssetID || '',
      userId || '',
      secondsWatched
    );
    setCurrentAssetID(null);
  };

  return {
    trackAssetOpen,
    trackAssetClose,
  };
}
