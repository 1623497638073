/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    firstname
    lastname
    email
    createdAt
    updatedAt
    meetingAccess {
      items {
        id
        owner
        userID
        meetingID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    last_seen
    currentMeetingID
    currentMeeting {
      id
      owner
      name
      caseClusterID
      hostID
      host {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      hostFullName
      createdAt
      updatedAt
      eventDate
      endDate
      eventLocation
      participants {
        items {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        nextToken
        __typename
      }
      userAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      guestUrlCode
      previewCode
      clientIDs
      currentCase
      currentSlide
      caseCluster {
        id
        owner
        name
        cases
        createdAt
        updatedAt
        isMock
        __typename
      }
      caseStats {
        items {
          id
          owner
          meetingID
          caseId
          version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      meetingStats {
        id
        owner
        meetingID
        casePresentationHistory
        version
        createdAt
        updatedAt
        __typename
      }
      purchaseOrderNbr
      meetingNotes
      meetingType
      isMock
      isInTesting
      isArchived
      isDemo
      __typename
    }
    isTemporary
    owner
    isMock
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    firstname
    lastname
    email
    createdAt
    updatedAt
    meetingAccess {
      items {
        id
        owner
        userID
        meetingID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    last_seen
    currentMeetingID
    currentMeeting {
      id
      owner
      name
      caseClusterID
      hostID
      host {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      hostFullName
      createdAt
      updatedAt
      eventDate
      endDate
      eventLocation
      participants {
        items {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        nextToken
        __typename
      }
      userAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      guestUrlCode
      previewCode
      clientIDs
      currentCase
      currentSlide
      caseCluster {
        id
        owner
        name
        cases
        createdAt
        updatedAt
        isMock
        __typename
      }
      caseStats {
        items {
          id
          owner
          meetingID
          caseId
          version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      meetingStats {
        id
        owner
        meetingID
        casePresentationHistory
        version
        createdAt
        updatedAt
        __typename
      }
      purchaseOrderNbr
      meetingNotes
      meetingType
      isMock
      isInTesting
      isArchived
      isDemo
      __typename
    }
    isTemporary
    owner
    isMock
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    firstname
    lastname
    email
    createdAt
    updatedAt
    meetingAccess {
      items {
        id
        owner
        userID
        meetingID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    last_seen
    currentMeetingID
    currentMeeting {
      id
      owner
      name
      caseClusterID
      hostID
      host {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      hostFullName
      createdAt
      updatedAt
      eventDate
      endDate
      eventLocation
      participants {
        items {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        nextToken
        __typename
      }
      userAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      guestUrlCode
      previewCode
      clientIDs
      currentCase
      currentSlide
      caseCluster {
        id
        owner
        name
        cases
        createdAt
        updatedAt
        isMock
        __typename
      }
      caseStats {
        items {
          id
          owner
          meetingID
          caseId
          version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      meetingStats {
        id
        owner
        meetingID
        casePresentationHistory
        version
        createdAt
        updatedAt
        __typename
      }
      purchaseOrderNbr
      meetingNotes
      meetingType
      isMock
      isInTesting
      isArchived
      isDemo
      __typename
    }
    isTemporary
    owner
    isMock
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createUserClient = /* GraphQL */ `mutation CreateUserClient(
  $input: CreateUserClientInput!
  $condition: ModelUserClientConditionInput
) {
  createUserClient(input: $input, condition: $condition) {
    id
    owner
    clientID
    client {
      id
      clientName
      createdAt
      updatedAt
      owner
      __typename
    }
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserClientMutationVariables,
  APITypes.CreateUserClientMutation
>;
export const updateUserClient = /* GraphQL */ `mutation UpdateUserClient(
  $input: UpdateUserClientInput!
  $condition: ModelUserClientConditionInput
) {
  updateUserClient(input: $input, condition: $condition) {
    id
    owner
    clientID
    client {
      id
      clientName
      createdAt
      updatedAt
      owner
      __typename
    }
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserClientMutationVariables,
  APITypes.UpdateUserClientMutation
>;
export const deleteUserClient = /* GraphQL */ `mutation DeleteUserClient(
  $input: DeleteUserClientInput!
  $condition: ModelUserClientConditionInput
) {
  deleteUserClient(input: $input, condition: $condition) {
    id
    owner
    clientID
    client {
      id
      clientName
      createdAt
      updatedAt
      owner
      __typename
    }
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserClientMutationVariables,
  APITypes.DeleteUserClientMutation
>;
export const createClient = /* GraphQL */ `mutation CreateClient(
  $input: CreateClientInput!
  $condition: ModelClientConditionInput
) {
  createClient(input: $input, condition: $condition) {
    id
    clientName
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClientMutationVariables,
  APITypes.CreateClientMutation
>;
export const updateClient = /* GraphQL */ `mutation UpdateClient(
  $input: UpdateClientInput!
  $condition: ModelClientConditionInput
) {
  updateClient(input: $input, condition: $condition) {
    id
    clientName
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClientMutationVariables,
  APITypes.UpdateClientMutation
>;
export const deleteClient = /* GraphQL */ `mutation DeleteClient(
  $input: DeleteClientInput!
  $condition: ModelClientConditionInput
) {
  deleteClient(input: $input, condition: $condition) {
    id
    clientName
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClientMutationVariables,
  APITypes.DeleteClientMutation
>;
export const createUserMeetingAccess = /* GraphQL */ `mutation CreateUserMeetingAccess(
  $input: CreateUserMeetingAccessInput!
  $condition: ModelUserMeetingAccessConditionInput
) {
  createUserMeetingAccess(input: $input, condition: $condition) {
    id
    owner
    userID
    meetingID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMeetingAccessMutationVariables,
  APITypes.CreateUserMeetingAccessMutation
>;
export const updateUserMeetingAccess = /* GraphQL */ `mutation UpdateUserMeetingAccess(
  $input: UpdateUserMeetingAccessInput!
  $condition: ModelUserMeetingAccessConditionInput
) {
  updateUserMeetingAccess(input: $input, condition: $condition) {
    id
    owner
    userID
    meetingID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMeetingAccessMutationVariables,
  APITypes.UpdateUserMeetingAccessMutation
>;
export const deleteUserMeetingAccess = /* GraphQL */ `mutation DeleteUserMeetingAccess(
  $input: DeleteUserMeetingAccessInput!
  $condition: ModelUserMeetingAccessConditionInput
) {
  deleteUserMeetingAccess(input: $input, condition: $condition) {
    id
    owner
    userID
    meetingID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMeetingAccessMutationVariables,
  APITypes.DeleteUserMeetingAccessMutation
>;
export const createMeeting = /* GraphQL */ `mutation CreateMeeting(
  $input: CreateMeetingInput!
  $condition: ModelMeetingConditionInput
) {
  createMeeting(input: $input, condition: $condition) {
    id
    owner
    name
    caseClusterID
    hostID
    host {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    hostFullName
    createdAt
    updatedAt
    eventDate
    endDate
    eventLocation
    participants {
      items {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      nextToken
      __typename
    }
    userAccess {
      items {
        id
        owner
        userID
        meetingID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    guestUrlCode
    previewCode
    clientIDs
    currentCase
    currentSlide
    caseCluster {
      id
      owner
      name
      cases
      createdAt
      updatedAt
      isMock
      __typename
    }
    caseStats {
      items {
        id
        owner
        meetingID
        caseId
        assetViews {
          nextToken
          __typename
        }
        slides {
          nextToken
          __typename
        }
        version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    meetingStats {
      id
      owner
      meetingID
      casePresentationHistory
      version
      createdAt
      updatedAt
      __typename
    }
    purchaseOrderNbr
    meetingNotes
    meetingType
    isMock
    isInTesting
    isArchived
    isDemo
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMeetingMutationVariables,
  APITypes.CreateMeetingMutation
>;
export const updateMeeting = /* GraphQL */ `mutation UpdateMeeting(
  $input: UpdateMeetingInput!
  $condition: ModelMeetingConditionInput
) {
  updateMeeting(input: $input, condition: $condition) {
    id
    owner
    name
    caseClusterID
    hostID
    host {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    hostFullName
    createdAt
    updatedAt
    eventDate
    endDate
    eventLocation
    participants {
      items {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      nextToken
      __typename
    }
    userAccess {
      items {
        id
        owner
        userID
        meetingID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    guestUrlCode
    previewCode
    clientIDs
    currentCase
    currentSlide
    caseCluster {
      id
      owner
      name
      cases
      createdAt
      updatedAt
      isMock
      __typename
    }
    caseStats {
      items {
        id
        owner
        meetingID
        caseId
        assetViews {
          nextToken
          __typename
        }
        slides {
          nextToken
          __typename
        }
        version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    meetingStats {
      id
      owner
      meetingID
      casePresentationHistory
      version
      createdAt
      updatedAt
      __typename
    }
    purchaseOrderNbr
    meetingNotes
    meetingType
    isMock
    isInTesting
    isArchived
    isDemo
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMeetingMutationVariables,
  APITypes.UpdateMeetingMutation
>;
export const deleteMeeting = /* GraphQL */ `mutation DeleteMeeting(
  $input: DeleteMeetingInput!
  $condition: ModelMeetingConditionInput
) {
  deleteMeeting(input: $input, condition: $condition) {
    id
    owner
    name
    caseClusterID
    hostID
    host {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    hostFullName
    createdAt
    updatedAt
    eventDate
    endDate
    eventLocation
    participants {
      items {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      nextToken
      __typename
    }
    userAccess {
      items {
        id
        owner
        userID
        meetingID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    guestUrlCode
    previewCode
    clientIDs
    currentCase
    currentSlide
    caseCluster {
      id
      owner
      name
      cases
      createdAt
      updatedAt
      isMock
      __typename
    }
    caseStats {
      items {
        id
        owner
        meetingID
        caseId
        assetViews {
          nextToken
          __typename
        }
        slides {
          nextToken
          __typename
        }
        version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    meetingStats {
      id
      owner
      meetingID
      casePresentationHistory
      version
      createdAt
      updatedAt
      __typename
    }
    purchaseOrderNbr
    meetingNotes
    meetingType
    isMock
    isInTesting
    isArchived
    isDemo
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMeetingMutationVariables,
  APITypes.DeleteMeetingMutation
>;
export const createUserMeetingJoin = /* GraphQL */ `mutation CreateUserMeetingJoin(
  $input: CreateUserMeetingJoinInput!
  $condition: ModelUserMeetingJoinConditionInput
) {
  createUserMeetingJoin(input: $input, condition: $condition) {
    id
    owner
    userID
    meetingID
    user {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    isCurrentlyInMeeting
    lastPingDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMeetingJoinMutationVariables,
  APITypes.CreateUserMeetingJoinMutation
>;
export const updateUserMeetingJoin = /* GraphQL */ `mutation UpdateUserMeetingJoin(
  $input: UpdateUserMeetingJoinInput!
  $condition: ModelUserMeetingJoinConditionInput
) {
  updateUserMeetingJoin(input: $input, condition: $condition) {
    id
    owner
    userID
    meetingID
    user {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    isCurrentlyInMeeting
    lastPingDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMeetingJoinMutationVariables,
  APITypes.UpdateUserMeetingJoinMutation
>;
export const deleteUserMeetingJoin = /* GraphQL */ `mutation DeleteUserMeetingJoin(
  $input: DeleteUserMeetingJoinInput!
  $condition: ModelUserMeetingJoinConditionInput
) {
  deleteUserMeetingJoin(input: $input, condition: $condition) {
    id
    owner
    userID
    meetingID
    user {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    isCurrentlyInMeeting
    lastPingDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMeetingJoinMutationVariables,
  APITypes.DeleteUserMeetingJoinMutation
>;
export const createAnswer = /* GraphQL */ `mutation CreateAnswer(
  $input: CreateAnswerInput!
  $condition: ModelAnswerConditionInput
) {
  createAnswer(input: $input, condition: $condition) {
    id
    owner
    questionID
    userID
    meetingID
    answerIndexes
    answerTexts
    createdAt
    updatedAt
    user {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAnswerMutationVariables,
  APITypes.CreateAnswerMutation
>;
export const updateAnswer = /* GraphQL */ `mutation UpdateAnswer(
  $input: UpdateAnswerInput!
  $condition: ModelAnswerConditionInput
) {
  updateAnswer(input: $input, condition: $condition) {
    id
    owner
    questionID
    userID
    meetingID
    answerIndexes
    answerTexts
    createdAt
    updatedAt
    user {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAnswerMutationVariables,
  APITypes.UpdateAnswerMutation
>;
export const deleteAnswer = /* GraphQL */ `mutation DeleteAnswer(
  $input: DeleteAnswerInput!
  $condition: ModelAnswerConditionInput
) {
  deleteAnswer(input: $input, condition: $condition) {
    id
    owner
    questionID
    userID
    meetingID
    answerIndexes
    answerTexts
    createdAt
    updatedAt
    user {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAnswerMutationVariables,
  APITypes.DeleteAnswerMutation
>;
export const createCaseCluster = /* GraphQL */ `mutation CreateCaseCluster(
  $input: CreateCaseClusterInput!
  $condition: ModelCaseClusterConditionInput
) {
  createCaseCluster(input: $input, condition: $condition) {
    id
    owner
    name
    cases
    createdAt
    updatedAt
    isMock
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCaseClusterMutationVariables,
  APITypes.CreateCaseClusterMutation
>;
export const updateCaseCluster = /* GraphQL */ `mutation UpdateCaseCluster(
  $input: UpdateCaseClusterInput!
  $condition: ModelCaseClusterConditionInput
) {
  updateCaseCluster(input: $input, condition: $condition) {
    id
    owner
    name
    cases
    createdAt
    updatedAt
    isMock
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCaseClusterMutationVariables,
  APITypes.UpdateCaseClusterMutation
>;
export const deleteCaseCluster = /* GraphQL */ `mutation DeleteCaseCluster(
  $input: DeleteCaseClusterInput!
  $condition: ModelCaseClusterConditionInput
) {
  deleteCaseCluster(input: $input, condition: $condition) {
    id
    owner
    name
    cases
    createdAt
    updatedAt
    isMock
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCaseClusterMutationVariables,
  APITypes.DeleteCaseClusterMutation
>;
export const createMessage = /* GraphQL */ `mutation CreateMessage(
  $input: CreateMessageInput!
  $condition: ModelMessageConditionInput
) {
  createMessage(input: $input, condition: $condition) {
    id
    owner
    channelID
    author
    body
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageMutationVariables,
  APITypes.CreateMessageMutation
>;
export const updateMessage = /* GraphQL */ `mutation UpdateMessage(
  $input: UpdateMessageInput!
  $condition: ModelMessageConditionInput
) {
  updateMessage(input: $input, condition: $condition) {
    id
    owner
    channelID
    author
    body
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageMutationVariables,
  APITypes.UpdateMessageMutation
>;
export const deleteMessage = /* GraphQL */ `mutation DeleteMessage(
  $input: DeleteMessageInput!
  $condition: ModelMessageConditionInput
) {
  deleteMessage(input: $input, condition: $condition) {
    id
    owner
    channelID
    author
    body
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageMutationVariables,
  APITypes.DeleteMessageMutation
>;
export const createMeetingStats = /* GraphQL */ `mutation CreateMeetingStats(
  $input: CreateMeetingStatsInput!
  $condition: ModelMeetingStatsConditionInput
) {
  createMeetingStats(input: $input, condition: $condition) {
    id
    owner
    meetingID
    casePresentationHistory
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMeetingStatsMutationVariables,
  APITypes.CreateMeetingStatsMutation
>;
export const updateMeetingStats = /* GraphQL */ `mutation UpdateMeetingStats(
  $input: UpdateMeetingStatsInput!
  $condition: ModelMeetingStatsConditionInput
) {
  updateMeetingStats(input: $input, condition: $condition) {
    id
    owner
    meetingID
    casePresentationHistory
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMeetingStatsMutationVariables,
  APITypes.UpdateMeetingStatsMutation
>;
export const deleteMeetingStats = /* GraphQL */ `mutation DeleteMeetingStats(
  $input: DeleteMeetingStatsInput!
  $condition: ModelMeetingStatsConditionInput
) {
  deleteMeetingStats(input: $input, condition: $condition) {
    id
    owner
    meetingID
    casePresentationHistory
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMeetingStatsMutationVariables,
  APITypes.DeleteMeetingStatsMutation
>;
export const createCaseStats = /* GraphQL */ `mutation CreateCaseStats(
  $input: CreateCaseStatsInput!
  $condition: ModelCaseStatsConditionInput
) {
  createCaseStats(input: $input, condition: $condition) {
    id
    owner
    meetingID
    caseId
    assetViews {
      items {
        id
        owner
        assetId
        userID
        timeWatched
        version
        createdAt
        updatedAt
        caseStatsAssetViewsId
        __typename
      }
      nextToken
      __typename
    }
    slides {
      items {
        id
        owner
        slideId
        time
        version
        createdAt
        updatedAt
        caseStatsSlidesId
        __typename
      }
      nextToken
      __typename
    }
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCaseStatsMutationVariables,
  APITypes.CreateCaseStatsMutation
>;
export const updateCaseStats = /* GraphQL */ `mutation UpdateCaseStats(
  $input: UpdateCaseStatsInput!
  $condition: ModelCaseStatsConditionInput
) {
  updateCaseStats(input: $input, condition: $condition) {
    id
    owner
    meetingID
    caseId
    assetViews {
      items {
        id
        owner
        assetId
        userID
        timeWatched
        version
        createdAt
        updatedAt
        caseStatsAssetViewsId
        __typename
      }
      nextToken
      __typename
    }
    slides {
      items {
        id
        owner
        slideId
        time
        version
        createdAt
        updatedAt
        caseStatsSlidesId
        __typename
      }
      nextToken
      __typename
    }
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCaseStatsMutationVariables,
  APITypes.UpdateCaseStatsMutation
>;
export const deleteCaseStats = /* GraphQL */ `mutation DeleteCaseStats(
  $input: DeleteCaseStatsInput!
  $condition: ModelCaseStatsConditionInput
) {
  deleteCaseStats(input: $input, condition: $condition) {
    id
    owner
    meetingID
    caseId
    assetViews {
      items {
        id
        owner
        assetId
        userID
        timeWatched
        version
        createdAt
        updatedAt
        caseStatsAssetViewsId
        __typename
      }
      nextToken
      __typename
    }
    slides {
      items {
        id
        owner
        slideId
        time
        version
        createdAt
        updatedAt
        caseStatsSlidesId
        __typename
      }
      nextToken
      __typename
    }
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCaseStatsMutationVariables,
  APITypes.DeleteCaseStatsMutation
>;
export const createCaseAssetView = /* GraphQL */ `mutation CreateCaseAssetView(
  $input: CreateCaseAssetViewInput!
  $condition: ModelCaseAssetViewConditionInput
) {
  createCaseAssetView(input: $input, condition: $condition) {
    id
    owner
    assetId
    userID
    timeWatched
    version
    createdAt
    updatedAt
    caseStatsAssetViewsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCaseAssetViewMutationVariables,
  APITypes.CreateCaseAssetViewMutation
>;
export const updateCaseAssetView = /* GraphQL */ `mutation UpdateCaseAssetView(
  $input: UpdateCaseAssetViewInput!
  $condition: ModelCaseAssetViewConditionInput
) {
  updateCaseAssetView(input: $input, condition: $condition) {
    id
    owner
    assetId
    userID
    timeWatched
    version
    createdAt
    updatedAt
    caseStatsAssetViewsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCaseAssetViewMutationVariables,
  APITypes.UpdateCaseAssetViewMutation
>;
export const deleteCaseAssetView = /* GraphQL */ `mutation DeleteCaseAssetView(
  $input: DeleteCaseAssetViewInput!
  $condition: ModelCaseAssetViewConditionInput
) {
  deleteCaseAssetView(input: $input, condition: $condition) {
    id
    owner
    assetId
    userID
    timeWatched
    version
    createdAt
    updatedAt
    caseStatsAssetViewsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCaseAssetViewMutationVariables,
  APITypes.DeleteCaseAssetViewMutation
>;
export const createCaseSlideStats = /* GraphQL */ `mutation CreateCaseSlideStats(
  $input: CreateCaseSlideStatsInput!
  $condition: ModelCaseSlideStatsConditionInput
) {
  createCaseSlideStats(input: $input, condition: $condition) {
    id
    owner
    slideId
    time
    version
    createdAt
    updatedAt
    caseStatsSlidesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCaseSlideStatsMutationVariables,
  APITypes.CreateCaseSlideStatsMutation
>;
export const updateCaseSlideStats = /* GraphQL */ `mutation UpdateCaseSlideStats(
  $input: UpdateCaseSlideStatsInput!
  $condition: ModelCaseSlideStatsConditionInput
) {
  updateCaseSlideStats(input: $input, condition: $condition) {
    id
    owner
    slideId
    time
    version
    createdAt
    updatedAt
    caseStatsSlidesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCaseSlideStatsMutationVariables,
  APITypes.UpdateCaseSlideStatsMutation
>;
export const deleteCaseSlideStats = /* GraphQL */ `mutation DeleteCaseSlideStats(
  $input: DeleteCaseSlideStatsInput!
  $condition: ModelCaseSlideStatsConditionInput
) {
  deleteCaseSlideStats(input: $input, condition: $condition) {
    id
    owner
    slideId
    time
    version
    createdAt
    updatedAt
    caseStatsSlidesId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCaseSlideStatsMutationVariables,
  APITypes.DeleteCaseSlideStatsMutation
>;
export const createMeetingNPSScore = /* GraphQL */ `mutation CreateMeetingNPSScore(
  $input: CreateMeetingNPSScoreInput!
  $condition: ModelMeetingNPSScoreConditionInput
) {
  createMeetingNPSScore(input: $input, condition: $condition) {
    id
    owner
    userID
    meetingID
    scores
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMeetingNPSScoreMutationVariables,
  APITypes.CreateMeetingNPSScoreMutation
>;
export const updateMeetingNPSScore = /* GraphQL */ `mutation UpdateMeetingNPSScore(
  $input: UpdateMeetingNPSScoreInput!
  $condition: ModelMeetingNPSScoreConditionInput
) {
  updateMeetingNPSScore(input: $input, condition: $condition) {
    id
    owner
    userID
    meetingID
    scores
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMeetingNPSScoreMutationVariables,
  APITypes.UpdateMeetingNPSScoreMutation
>;
export const deleteMeetingNPSScore = /* GraphQL */ `mutation DeleteMeetingNPSScore(
  $input: DeleteMeetingNPSScoreInput!
  $condition: ModelMeetingNPSScoreConditionInput
) {
  deleteMeetingNPSScore(input: $input, condition: $condition) {
    id
    owner
    userID
    meetingID
    scores
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMeetingNPSScoreMutationVariables,
  APITypes.DeleteMeetingNPSScoreMutation
>;
export const createAccessLink = /* GraphQL */ `mutation CreateAccessLink(
  $input: CreateAccessLinkInput!
  $condition: ModelAccessLinkConditionInput
) {
  createAccessLink(input: $input, condition: $condition) {
    id
    purpose
    accessCode
    data
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccessLinkMutationVariables,
  APITypes.CreateAccessLinkMutation
>;
export const updateAccessLink = /* GraphQL */ `mutation UpdateAccessLink(
  $input: UpdateAccessLinkInput!
  $condition: ModelAccessLinkConditionInput
) {
  updateAccessLink(input: $input, condition: $condition) {
    id
    purpose
    accessCode
    data
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccessLinkMutationVariables,
  APITypes.UpdateAccessLinkMutation
>;
export const deleteAccessLink = /* GraphQL */ `mutation DeleteAccessLink(
  $input: DeleteAccessLinkInput!
  $condition: ModelAccessLinkConditionInput
) {
  deleteAccessLink(input: $input, condition: $condition) {
    id
    purpose
    accessCode
    data
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccessLinkMutationVariables,
  APITypes.DeleteAccessLinkMutation
>;
