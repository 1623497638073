import { GlobalColors, GlobalTheme } from '@/Styles';
import { createTheme } from '@aws-amplify/ui-react';

// by default, createTheme extends the defaultTheme.
const AuthTheme = createTheme({
  name: 'CustomAmplifyTheme',
  tokens: {
    fonts: {
      default: {
        variable: { value: GlobalTheme.fontDefault },
        static: { value: GlobalTheme.fontDefault },
      },
    },

    components: {
      authenticator: {
        router: {
          borderColor: { value: '#e0e0e0' },
        },
      },
      tabs: {
        // borderColor: { value: '#fff000' },
        // backgroundColor: { value: '#ff0000' },
        item: {
          // _focus: {
          //   color: { value: '#f000ff' },
          // },
          _active: {
            borderColor: { value: GlobalColors.blue },
          },
          // backgroundColor: { value: '#fff000' },
        },
      },
      button: {
        primary: {
          backgroundColor: { value: GlobalTheme.components.button.bgColor },
          _hover: {
            backgroundColor: {
              value: GlobalTheme.components.button._hover.bgColor,
            },
          },
        },
        borderRadius: { value: GlobalTheme.components.button.borderRadius },
        color: { value: GlobalTheme.components.button.color },
      },
    },

    colors: {
      font: {
        // references colors.neutral.100
        // because the default theme defines that color already
        // we don't need to re-define it here
        primary: { value: '{colors.neutral.100.value}' },
      },
    },
  },
});

export default AuthTheme;
