import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import {
  Flex,
  Heading,
  Text,
  VStack,
  Button,
  IconButton,
} from '@chakra-ui/react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useAppSelector } from '@/store/StoreHooks';
import { MeetingSelectors } from '@/store/slices/meeting';
import { NpsRatingBox } from './NpsRatingBox';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { useQuery } from '@tanstack/react-query';
import { checkIfUserHasCreatedNPS } from '@/features/meeting-stats/graphql/queries';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes/Routes';
import { AppSpinner } from '@/ui/AppSpinner';
import { CaptureSentryMessage } from '@/utils/helpers/CaptureSentryException';
import { useMeetingStatsCreationContext } from '@/features/meeting-stats/context/MeetingStatsCreationContext';

const RateMeetingUserView = () => {
  const [nbrUsers, setNbrUsers] = useState<number>(1);
  const [currNbrUsers, setCurrNbrUsers] = useState<number>(1);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentMeeting = useAppSelector(MeetingSelectors.getCurrentMeeting);
  const { user } = useUserAuthenticationContext();
  const { createNPSScoreResult } = useMeetingStatsCreationContext();
  const [votes, setVotes] = useState<number[]>([]);
  const hasUserVotedQuery = useQuery({
    queryKey: ['hasUserVotedQuery', currentMeeting?.id, user?.id],
    refetchOnWindowFocus: false,
    enabled: !!currentMeeting && !!user,
    queryFn: async () => {
      if (!currentMeeting || !user) {
        console.error('NPSScore: currentMeeting or user is null');
        return false;
      }
      const hasVoted = await checkIfUserHasCreatedNPS(
        currentMeeting.id,
        user.id
      );
      return hasVoted;
    },
  });
  const handleVote = (userIndex: number, rating: number) => {
    setVotes((prev) => {
      const newVotes = [...prev];
      newVotes.push(rating);
      return newVotes;
    });
  };

  useEffect(() => {
    if (
      votes.length === nbrUsers &&
      nbrUsers !== 0 &&
      !hasUserVotedQuery.data
    ) {
      if (!currentMeeting || !user) {
        CaptureSentryMessage('NPSScore: currentMeeting or user is null');
        return;
      }
      createNPSScoreResult(currentMeeting.id, user.id, votes).then(() => {
        hasUserVotedQuery.refetch();
      });
    }
  }, [votes.length, currentMeeting, user, hasUserVotedQuery]);

  if (hasUserVotedQuery.isLoading) {
    return <AppSpinner />;
  }

  return (
    <Flex
      flex="auto"
      height="100%"
      direction="column"
      justifyContent="center"
      boxShadow="0px 0px 2px #eee"
      borderRadius="5px"
      padding="10px"
      textAlign="center"
    >
      <VStack
        background="white"
        justifyContent="center"
        alignItems="center"
        userSelect="none"
      >
        <Heading color="livelinx.purple200" fontWeight="200em" fontSize="2em">
          {t('npsScore.title.user')}
        </Heading>
        <Text fontSize="1.5em" color="livelinx.grey200">
          {!hasUserVotedQuery.data &&
            (nbrUsers === 0
              ? t('npsScore.nbrUsers.question')
              : t('npsScore.description.user'))}
          {hasUserVotedQuery.data && t('npsScore.thanks.title')}
        </Text>
      </VStack>

      <Flex
        background="white"
        padding="20px"
        justifyContent="center"
        alignItems="center"
        gap="20px"
      >
        {!hasUserVotedQuery.data && nbrUsers === 0 && (
          <Flex direction="column">
            <IconButton
              size="md"
              borderRadius="0"
              border="none"
              background="livelinx.purple200"
              color="#FFF"
              aria-label="up-amt-users"
              icon={<UpOutlined />}
              onClick={() => {
                if (currNbrUsers >= 3) return;
                setCurrNbrUsers((prev) => prev + 1);
              }}
              sx={{ marginBottom: '10px' }}
            />
            <Text fontSize="1.5em" color="livelinx.grey200">
              {currNbrUsers}
            </Text>
            <IconButton
              size="md"
              borderRadius="0"
              border="none"
              background="livelinx.purple200"
              color="#FFF"
              aria-label="up-amt-users"
              icon={<DownOutlined />}
              onClick={() => {
                if (currNbrUsers <= 1) return;
                setCurrNbrUsers((prev) => prev - 1);
              }}
              sx={{ marginTop: '10px' }}
            />
            <Button
              sx={{ marginTop: '10px' }}
              onClick={() => setNbrUsers(currNbrUsers)}
            >
              Confirm
            </Button>
          </Flex>
        )}
        {!hasUserVotedQuery.data &&
          nbrUsers > 0 &&
          Array.from({ length: nbrUsers }).map((_, i) => (
            <NpsRatingBox
              key={i}
              userIndex={i + 1}
              onSubmit={(rating) => {
                handleVote(i, rating);
              }}
            />
          ))}
        {hasUserVotedQuery.data && (
          <Button variant="primary" onClick={() => navigate(ROUTES.HOME)}>
            Go home
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default RateMeetingUserView;
