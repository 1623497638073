export const config = {
  CHAT_FEATURE: [
    {
      //IPSEN 2024
      meetingID: '74db963c-8d3b-498a-a537-af9282e23f86',
      users: [] as string[],
    },
    {
      //Test Zone Novo
      meetingID: '70c2e7c1-715c-4619-a754-d92628aae47f',
    },
  ],
  PIP_FEATURE: [
    {
      //IPSEN 2024
      meetingID: '74db963c-8d3b-498a-a537-af9282e23f86',
      videoURL: 'https://www.youtube.com/watch?v=36YnV9STBqc',
      users: ['desmet.informatique@gmail.com'],
    },
    {
      //Test Zone Novo
      meetingID: '70c2e7c1-715c-4619-a754-d92628aae47f',
      videoURL: 'https://www.youtube.com/watch?v=36YnV9STBqc',
      users: [
        'mo.zouina@outlook.com',
        'anthodb@gmail.com',
        'anthodb+livelinx@gmail.com',
        'desmet.informatique@gmail.com',
      ],
    },
  ],
  USER_LIST_FEATURE: [
    {
      //IPSEN 2024
      meetingID: '74db963c-8d3b-498a-a537-af9282e23f86',
    },
    {
      //Test Zone Novo
      meetingID: '70c2e7c1-715c-4619-a754-d92628aae47f',
      users: [
        'mo.zouina@outlook.com',
        'anthodb@gmail.com',
        'anthodb+livelinx@gmail.com',
        'desmet.informatique@gmail.com',
      ],
    },
  ],
};
