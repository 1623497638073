import { composeQuestionID } from '@/features/meeting/answer/answerHelper';
import { listAnswersByQuestionID } from '@/features/meeting/answer/listAnswersByQuestionId';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { CaptureSentryMessage } from '@/utils/helpers/CaptureSentryException';
import { NewISlide } from '@/utils/types/zod/slideTypes/slideSchema';
import { Variation } from '@/utils/types/zod/variationSchema';
import { useQuery } from '@tanstack/react-query';

async function getFirstValidVariation(
  meetingID: string,
  caseID: string,
  userID: string,
  variations: Variation[]
): Promise<Variation | undefined> {
  for (const variation of variations) {
    //get the question, check if the user answer is the one we want
    const allAnswers = await listAnswersByQuestionID(
      composeQuestionID(meetingID, caseID, variation.conditionKey)
    );
    if (!allAnswers) {
      continue;
    }
    //NOTE: If ever a user can send multiple answers, change this
    const userAnswer = allAnswers.find((el) => el.userID === userID);
    if (!userAnswer) {
      continue;
    }
    //NOTE MULTIVOTE: If at some point we want to add conditions according to multivote, we'll have to add a check here
    if (userAnswer.answerIndexes[0] === variation.conditionValue) {
      return variation;
    }
  }
  return undefined;
}

type UseSlideVariationReturnType = {
  slideHasVariations: boolean;
  isLoading: boolean;
  slideVariation: Variation | undefined;
};

export default function useSlideVariations(
  slide: NewISlide
): UseSlideVariationReturnType {
  const slideHasVariations = slide.variationIds.length > 0;
  const { userID } = useUserAuthenticationContext();
  const { currentCaseId, meeting, isUserHost } = useCurrentMeetingDataContext();

  if (isUserHost || !slideHasVariations) {
    return {
      slideHasVariations: false,
      isLoading: false,
      slideVariation: undefined,
    };
  }

  const { variationList: allVariations } = useCurrentMeetingDataContext();

  const slideVariationQuery = useQuery({
    queryKey: ['useSlideVariations'],
    refetchOnWindowFocus: false,
    enabled: allVariations.length > 0,
    queryFn: async () => {
      console.log('getting variations');
      const possibleVariations = allVariations.filter((variation) =>
        slide.variationIds.includes(variation.variationId)
      );
      if (!userID) {
        CaptureSentryMessage(
          'No userID while fetching slideVariations. This is not normal.'
        );
        throw new Error('No userID at this point is not normal');
      }
      //NIKOTODO: add assertions here
      const slideVariation = await getFirstValidVariation(
        //NIKOTODO: This will probably brake in caseView
        meeting?.id || '',
        currentCaseId || '',
        userID,
        possibleVariations
      );
      if (!slideVariation) {
        throw new Error(
          'No Slide variation found. This means that: either not all variations have been added in the patient case or the user did not answer the required question.'
        );
      }
      console.log('variation result', slideVariation);
      return slideVariation;
    },
  });

  // if (slideVariationQuery.isLoading) {
  //   return {
  //     slideHasVariations,
  //     isLoading: true,
  //     slideVariation: undefined,
  //   };
  // }
  // if (isFalsy(slideVariationQuery.data)) {
  //   throw new Error('b');
  // }
  return {
    slideHasVariations,
    isLoading: slideVariationQuery.isLoading,
    slideVariation: slideVariationQuery.data,
  };
}
