import { useEffect, useState } from 'react';
import Empty from '@/ui/Empty/Empty';
import { Meeting } from '@/services/API';
import AssetModal from '@/components/AssetModal/AssetModal';
import CaseSlide from '../../components/CaseSlide/CaseSlide';
import SupportedDocumentsPopover from './SupportedDocumentsPopover';
import { CustomSlideIndex } from '@/utils/types/enums';
import { Trans, useTranslation } from 'react-i18next';
import {
  Text,
  Button,
  HStack,
  Flex,
  Heading,
  keyframes,
  Icon,
  Divider,
} from '@chakra-ui/react';
import { GlobalTheme } from '@/Styles';
import SupportedDocumentIcon from '@/assets/images/icon_support_doc.svg';

import liveMeetingBackground from '@/assets/images/liveMeetingBackground.svg?url';
import avatarManIcon from '@/assets/images/genderMaleIcon.svg';
import avatarWomanIcon from '@/assets/images/genderFemaleIcon.svg';
import { SimpleContentModal } from '@/ui/SimpleContentModal';
import AnamneseItem from '@/components/CaseSlide/items/AnamneseItem';
import MeetingTestingModeWarning from './MeetingTestingModeWarning';
import { NewIPatientCase } from '@/utils/types/zod/patientCaseSchema';
import { NewISlide } from '@/utils/types/zod/slideTypes/slideSchema';
import { NewIAsset } from '@/utils/types/zod/assetSchema';
import { getAssetListByRefOrThrow } from './utils/getAssetByRef';
import { getAnamneseItemFromCase } from '@/features/caseCluster/helpers/getAnamneseItem';
import { fadeInLeft } from 'react-animations';
import { getIndexFirstSlideNotAnamnese } from './utils/getIndexFirstSlideNotAnamnese';
import TopRightItem from './TopRightItem';
import RateMeeting from './ratecaseCluster/RateMeeting';
import MeetingRightSideBar, {
  MeetingRightSidebarProps,
} from './MeetingRightSideBar/MeetingRightSideBar';

type Props = {
  patientCaseIndex: number;
  patientCase: NewIPatientCase;
  meeting?: Meeting;
  isHost: boolean;
  currSlide?: NewISlide | undefined;
  // trackAnswer?: (answerIndex: number, isConfirmed: boolean) => void;
  trackAssetOpen?: (
    assetID: string,
    assetName: string,
    isLibrary: boolean
  ) => void;
  trackAssetClose?: () => void;
  meetingRightSideBarProps: MeetingRightSidebarProps;
};

const CaseExplorer = ({
  patientCaseIndex,
  patientCase,
  meeting,
  isHost,
  currSlide,
  // trackAnswer,
  trackAssetOpen,
  trackAssetClose,
  meetingRightSideBarProps,
}: Props) => {
  const { t } = useTranslation();
  const isPreview = !!currSlide;
  const fromRight = keyframes`${fadeInLeft}`;

  const [modalAssets, setModalAssets] = useState<NewIAsset[] | undefined>();
  const [modalAssetIndex, setModalAssetIndex] = useState<number>(0);
  const [showPatientProfile, setShowPatientProfile] = useState<boolean>(false);

  let slideIndex = isPreview
    ? patientCase.slides.indexOf(currSlide)
    : meeting?.currentSlide;
  if (!slideIndex || slideIndex < 0) {
    slideIndex = 0;
  }
  const currentSlide: NewISlide | undefined =
    currSlide || patientCase?.slides?.[slideIndex];

  const anamenseItem = getAnamneseItemFromCase(patientCase);
  const shouldRenderLLXBackground =
    slideIndex < getIndexFirstSlideNotAnamnese(patientCase);

  // --------------------- handlers ------------------------

  const showMoreInfoAssets = (assetRefs: string[]) => {
    const assetList = getAssetListByRefOrThrow(assetRefs, patientCase.assets);
    setModalAssetIndex(0);
    trackAssetOpen &&
      trackAssetOpen(assetList[0].id, assetList[0].title, false);
    setModalAssets(assetList);
  };

  const showLibraryAsset = (assetRef: string) => {
    const assetList = getAssetListByRefOrThrow(
      currentSlide?.libraryRefs,
      patientCase.assets
    );
    const index = assetList.findIndex((item) => item.id === assetRef) || 0;
    const asset = assetList[index];

    trackAssetOpen && trackAssetOpen(assetRef, asset.path, true);

    setModalAssetIndex(index);
    setModalAssets(assetList);
  };

  /*
  NOTE: If the slide changes and the modal assets are still open, we close them
  */

  useEffect(() => {
    // if (previousSlideIndex !== slideIndex) {
    setModalAssets(undefined);
    // }
  }, [slideIndex]);

  // --------------------- effect ------------------------

  /*
  Note: I think this code is useless but I'm not sure so I'm just commenting it before deleting it
  */

  // useEffect(() => {
  //   const filteredModalAssets = currentSlide?.items
  //     .filter((slide) => slide.assets && slide.assets?.length > 0)
  //     .filter((data) =>
  //       data.assets?.filter(
  //         (asset) =>
  //           modalAssets && modalAssets.some((el) => asset.url === el.url)
  //       )
  //     );

  //   if (filteredModalAssets) {
  //     setModalAssets(undefined);
  //   }
  // }, [currentSlide?.id]);

  // --------------------- render ------------------------

  if (!currentSlide) {
    // CASE NPS SCORE
    if (meeting?.currentSlide === CustomSlideIndex.NPS_SCORE) {
      return <RateMeeting isHost={isHost} />;
    }
    // CASE EMPTY
    return (
      <Flex w="full" justifyContent="center">
        <Empty>{t('noSlideFound')}</Empty>
      </Flex>
    );
  }

  return (
    <Flex
      direction="column"
      height="100%"
      w="full"
      overflow="hidden"
      position="relative"
    >
      <Flex justifyContent="space-between">
        <Flex
          flexDirection="column"
          padding="25px 20px 10px 60px"
          backgroundColor="white"
        >
          <Heading
            color="livelinx.purple200"
            fontSize="1.5em"
            animation={`0.7s ${fromRight}`}
            userSelect="none"
          >
            <Trans>{currentSlide?.title}</Trans>
          </Heading>

          <HStack justifyContent="space-between" marginBottom="5px">
            <Flex alignItems="center" gap="20px" marginTop="5px">
              {!currentSlide.hidePatientProfile &&
                slideIndex >= getIndexFirstSlideNotAnamnese(patientCase) && (
                  <>
                    <Button
                      variant="link"
                      color="livelinx.grey200"
                      letterSpacing="2px"
                      fontFamily="poppins-regular"
                      fontWeight="normal"
                      fontSize="md"
                      onClick={() => {
                        setShowPatientProfile(true);
                      }}
                    >
                      <Icon
                        as={
                          anamenseItem?.gender == 'woman'
                            ? avatarWomanIcon
                            : avatarManIcon
                        }
                        boxSize="25px"
                        marginRight="10px"
                      />
                      Patient case
                    </Button>
                  </>
                )}

              {!currentSlide.hideSupportedDocuments && (
                <SupportedDocumentsPopover
                  onOpenAsset={(asset) => showLibraryAsset(asset)}
                  slide={currentSlide}
                >
                  <Button
                    data-test="supportedDocuments-button"
                    variant="link"
                    display="flex"
                    color={GlobalTheme.color_purple_grey}
                    fontSize="sm"
                    isDisabled={
                      currentSlide &&
                      currentSlide.libraryRefs &&
                      currentSlide.libraryRefs.length > 0
                        ? false
                        : true
                    }
                  >
                    <Icon as={SupportedDocumentIcon} />
                    <Text pl={1}>
                      {' '}
                      {t('home.caseCluster.slide.supportingDocuments')} (
                      {currentSlide.libraryRefs?.length || 0})
                    </Text>
                  </Button>
                </SupportedDocumentsPopover>
              )}
            </Flex>
          </HStack>
        </Flex>
        {currentSlide.topRight && (
          <TopRightItem
            item={currentSlide.topRight}
            slides={patientCase.slides}
          />
        )}
      </Flex>
      <Divider />
      <Flex
        flex="auto"
        pt="10px"
        backgroundSize="cover"
        flexDirection="column"
        position="relative"
        backgroundColor={
          shouldRenderLLXBackground ? 'livelinx.purple400' : 'white'
        }
        backgroundImage={shouldRenderLLXBackground ? liveMeetingBackground : ''}
      >
        {meeting?.isInTesting && <MeetingTestingModeWarning isHost={isHost} />}
        <CaseSlide
          key={currentSlide.id} // this wont be updated without this...
          // key={currentSlide!.id + '' + answers.length} // this wont be updated without this...
          isHost={isHost}
          caseIndex={patientCaseIndex}
          slide={currentSlide}
          slideIndex={slideIndex}
          onShowAssets={(assetList) => {
            showMoreInfoAssets(assetList);
          }}
          isInViewMode={isPreview}
        />
      </Flex>
      {modalAssets && (
        <AssetModal
          index={modalAssetIndex}
          assetList={modalAssets}
          onAssetChange={(oldAsset: NewIAsset, newAsset: NewIAsset) => {
            trackAssetClose && trackAssetClose();
            trackAssetOpen && trackAssetOpen(newAsset.id, newAsset.path, false);
          }}
          onModalClose={() => {
            trackAssetClose && trackAssetClose();
            setModalAssets(undefined);
          }}
        />
      )}
      {anamenseItem && (
        <SimpleContentModal
          isOpen={showPatientProfile}
          onClose={() => setShowPatientProfile(false)}
          content={
            <AnamneseItem
              title={`CASES ${patientCaseIndex + 1}`}
              isWomanCase={anamenseItem.gender === 'woman'}
              subtitle={anamenseItem.title}
              descriptionList={anamenseItem.descriptionList}
            />
          }
        />
      )}
      <MeetingRightSideBar {...meetingRightSideBarProps} />
    </Flex>
  );
};

export default CaseExplorer;
