import ViewedCase from '@/features/meeting-stats/components/ViewedCase';
import PageLayout from '@/ui/Layout/PageLayout';
import { Container, Grid, GridItem, useDisclosure } from '@chakra-ui/react';
import BestSlide from '@/features/meeting-stats/components/BestSlide';
import useDeviceSize from '@/services/hooks/useDeviceSize';
import Header from '../Header';
import Footer from '../Footer';
import BestAsset from '@/features/meeting-stats/components/BestAsset';
import EngagementContributors from '@/features/meeting-stats/components/EngagementContributors';
import { useContext, useMemo } from 'react';
import { MeetingStatsViewContext } from '@/features/meeting-stats/context/MeetingStatsViewContext';
import PercentageChunk from '@/features/meeting-stats/components/PercentageChunk';
import { useTranslation } from 'react-i18next';
import { ParsedSlideStats } from '@/features/meeting-stats/utils/parseSlideStatsPerCase';
import { ParsedAssetViews } from '@/features/meeting-stats/utils/parseAssetViewsPerCase';
import { ChunkContainerLoading } from '@/features/meeting-stats/components/ChunkContainer';
import SpecificCaseStatsView from '../SpecificCaseStatsView/SpecificCaseStatsView';
import LorealStatsPage from '@/customSlides/loreal/LorealStatsPage/LorealStatsPage';
import ScoreDetailModal from './ScoreDetailModal';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { Column } from 'react-table';
import { CaptureSentryMessage } from '@/utils/helpers/CaptureSentryException';

export type MeetingLLXScoreDetailsRow = {
  case: string;
  score: number;
};

export const MeetingStatsView = () => {
  const { isDesktopOrLaptop } = useDeviceSize();
  const { t } = useTranslation();
  const {
    isOpen: isEngagementScoreDetailOpen,
    onOpen: onEngagementScoreDetailOpen,
    onClose: onEngagementScoreDetailClose,
  } = useDisclosure();
  const {
    caseIds,
    caseTitles,
    currentCase,
    parsedSlideStats,
    parsedAssetViews,
    engagementScore,
    caseIdToLLXRating,
    npsScore,
    meetingDate,
    meetingLocation,
    totalDuration,
    longestCaseDuration,
    consensusRate,
    uniqueCasesPresented,
  } = useContext(MeetingStatsViewContext);

  const { isAdmin } = useUserAuthenticationContext();

  const isLorealCase = currentCase === 'lorealFR' || currentCase === 'lorealNL';

  const caseToTop4Slides = useMemo(() => {
    if (!parsedSlideStats) return undefined;
    const toReturn: Record<string, ParsedSlideStats[]> = {};
    caseIds.forEach((caseId) => {
      const slides = parsedSlideStats[caseId];
      toReturn[caseId] = slides.slice(0, 4);
    });
    return toReturn;
  }, [parsedSlideStats, caseIds]);

  const bestAssetPerCase = useMemo(() => {
    if (!parsedAssetViews) return undefined;
    return caseIds.reduce((acc, curr) => {
      acc.set(curr, parsedAssetViews[curr][0]);
      return acc;
    }, new Map<string, ParsedAssetViews>());
  }, [parsedAssetViews, caseIds]);

  const bestAssetInGeneral = useMemo(() => {
    if (!bestAssetPerCase) return undefined;
    let record:
      | {
          caseTitle: string;
          asset: ParsedAssetViews | undefined;
        }
      | undefined = undefined;
    for (const [key, asset] of bestAssetPerCase.entries()) {
      if (
        !record ||
        !record.asset ||
        !asset ||
        asset.views > record.asset.views
      ) {
        record = {
          caseTitle: caseTitles[key],
          asset,
        };
      }
    }
    return record;
  }, [bestAssetPerCase]);

  const llxScoreDetailsColumns: Column<MeetingLLXScoreDetailsRow>[] = [
    {
      Header: 'Case',
      accessor: 'case',
    },
    {
      Header: 'Score',
      accessor: (row) => `${(row.score * 100).toFixed(2)}%`,
    },
  ];

  const llxScoreDetailsData = useMemo(() => {
    if (uniqueCasesPresented.length === 0) {
      return [
        {
          case: 'No cases presented',
          score: 0,
        },
      ];
    }
    const result = uniqueCasesPresented.map((elem) => {
      const title = caseTitles[elem];

      if (!title) {
        CaptureSentryMessage('Case title not found', {
          caseId: elem,
          caseTitles,
          uniqueCasesPresented: uniqueCasesPresented,
        });
      }
      return {
        case: caseTitles[elem] || 'Not available',
        score: caseIdToLLXRating.get(elem) || 0,
      };
    });

    return result;
  }, [uniqueCasesPresented]);

  return (
    <PageLayout
      showHeader={false}
      showFooter={false}
      background="linear-gradient(to bottom right, #F9FAFF, #ECF0FF)"
    >
      <Header date={meetingDate} location={meetingLocation} />

      <Container
        padding="20px"
        style={{
          minWidth: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Grid
          templateColumns={`repeat(${isDesktopOrLaptop ? 2 : 1}, 1fr)`}
          templateRows="auto"
          rowGap={isDesktopOrLaptop ? '22px' : '15px'}
          columnGap="22px"
        >
          {currentCase === '' && (
            <>
              <GridItem>
                {engagementScore !== undefined ? (
                  <PercentageChunk
                    percentage={Math.floor(engagementScore * 100)}
                    title={t('meetings.stats.engagement.title')}
                    onClick={
                      isAdmin ? () => onEngagementScoreDetailOpen() : undefined
                    }
                  />
                ) : (
                  <ChunkContainerLoading />
                )}
              </GridItem>
              <GridItem>
                {npsScore !== undefined ? (
                  <PercentageChunk
                    percentage={Math.floor(npsScore * 100)}
                    title={t('meetings.stats.nps.title')}
                  />
                ) : (
                  <ChunkContainerLoading />
                )}
              </GridItem>
              <GridItem>
                <ViewedCase />
              </GridItem>
              <GridItem>
                {caseIds && caseToTop4Slides ? (
                  <BestSlide
                    caseIds={caseIds}
                    caseTitles={caseTitles}
                    caseToTop4Slides={caseToTop4Slides}
                  />
                ) : (
                  <ChunkContainerLoading />
                )}
              </GridItem>
              <GridItem>
                {bestAssetInGeneral ? (
                  <BestAsset
                    caseTitle={bestAssetInGeneral.caseTitle}
                    asset={bestAssetInGeneral.asset}
                  />
                ) : (
                  <ChunkContainerLoading />
                )}
              </GridItem>
              <GridItem>
                <EngagementContributors
                  options={[
                    {
                      title: 'Total duration',
                      value: totalDuration,
                      maxValue: totalDuration,
                      textOnTheRight: `${totalDuration} min.`,
                    },
                    {
                      title: 'Longest case',
                      value: longestCaseDuration,
                      maxValue: totalDuration,
                      textOnTheRight: `${longestCaseDuration} min.`,
                    },
                    {
                      title: 'Consensus rate',
                      value: Math.floor(consensusRate * 100),
                      maxValue: 100,
                      textOnTheRight: `${Math.floor(consensusRate * 100)}%`,
                    },
                  ]}
                />
              </GridItem>
              <GridItem colSpan={isDesktopOrLaptop ? 2 : 1}>
                <Footer />
              </GridItem>
            </>
          )}
          <ScoreDetailModal
            isOpen={isEngagementScoreDetailOpen}
            onClose={onEngagementScoreDetailClose}
            columns={llxScoreDetailsColumns}
            data={llxScoreDetailsData}
          />
          {currentCase !== '' && !isLorealCase && (
            <SpecificCaseStatsView
              caseToTop4Slides={caseToTop4Slides}
              bestAssetPerCase={bestAssetPerCase}
            />
          )}
        </Grid>
      </Container>

      {currentCase !== '' && isLorealCase && <LorealStatsPage />}
    </PageLayout>
  );
};
