import { getEnvironnementFromUrl } from '../helpers/getEnvironnementFromUrl';
import { IsLocalHost } from '../helpers/isLocalHost';

const IS_LOCALHOST = IsLocalHost();

const APP_CONSTANTS = {
  IS_LOCALHOST,
  ENVIRONNEMENT: getEnvironnementFromUrl(window.location.href),
  PATIENT_CASES_REPO_PATH: `${import.meta.env.VITE_ASSETS_REPO}/patientCases/`,
  SHOW_CALL_BUTTONS: false,
  DISCLAIMER_TIMEOUT: 6000,
  //in seconds
  USER_MEETING_PING_INTERVAL: 5,
};

export const NULL_ID_VALUE = 'NONE';
export const DEFAULT_HOST_VALUE = 'default';
export const DEFAULT_HOST_NAME = 'John Doe';

export const SYNCHRONISATION_CHECK_INTERVAL = 5000;

export default APP_CONSTANTS;

export const LOREAL_STATS_TUTORIAL_KEY = 'loreal_statsPage_tutorial_finished';
