import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import { trackEvent } from '@/features/tracking/trackingHelpers';
import { NewISlide } from '@/utils/types/zod/slideTypes/slideSchema';
import {
  Divider,
  Heading,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAssetByRefOrThrow } from './utils/getAssetByRef';

type Props = {
  slide: NewISlide;
  children: React.ReactNode;
  onOpenAsset: (assetRef: string) => void;
};

function SupportedDocumentsPopover({ slide, children, onOpenAsset }: Props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { assetList } = useCurrentMeetingDataContext();

  // --------------------- effect ------------------------

  useEffect(() => {
    if (isOpen) {
      trackEvent('SupportingDocument', 'open');
    }
  }, [isOpen]);

  // --------------------- render ------------------------

  return (
    <Popover
      placement="bottom-start"
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <PopoverContent borderRadius="15px" bgColor="#969BFF">
        <PopoverArrow bgColor="#969BFF" />
        <PopoverBody p="20px">
          <Heading size="sm" color="white" pb="10px">
            {t('supportedDocuments.title', {
              num: slide.libraryRefs?.length || 0,
            })}
          </Heading>
          <VStack pt="10px" align="start" gap="10px">
            {slide.libraryRefs?.map((assetRef, idx) => {
              const asset = getAssetByRefOrThrow(assetRef, assetList);
              return (
                <>
                  <HStack
                    data-test="supporting-documents-asset"
                    gap="10px"
                    key={assetRef}
                    userSelect="none"
                    cursor="pointer"
                    onClick={() => onOpenAsset(assetRef)}
                  >
                    <Image
                      width="60px"
                      height="50px"
                      alt={asset.thumbUrl}
                      src={asset.thumbUrl}
                      borderRadius="10px"
                      border="1px solid white"
                    />
                    <Text color="white" noOfLines={2} wordBreak="break-all">
                      {asset.title}
                    </Text>
                  </HStack>
                  {idx !== slide.libraryRefs.length - 1 && (
                    <Divider orientation="horizontal" />
                  )}
                </>
              );
            })}
          </VStack>
        </PopoverBody>
      </PopoverContent>
      <PopoverTrigger>{children}</PopoverTrigger>
    </Popover>
  );
}

export default SupportedDocumentsPopover;
