import { GetMeetingQuery, Meeting } from '@/services/API';
import { getMeeting } from '@/services/graphql/queries';

import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import { useEffect, useState } from 'react';
import { SYNCHRONISATION_CHECK_INTERVAL } from '@/utils/constants/app.constants';
import { CaptureSentryMessage } from '@/utils/helpers/CaptureSentryException';
import { reloadApp } from '@/utils/reloadApp';

type CheckResult = {
  syncOk: boolean;
  currentDate: string | null | undefined;
  latestDate: string | null | undefined;
  latestMeeting: GetMeetingQuery['getMeeting'] | null | undefined;
};

export default function useMeetingDebugger(
  currentMeeting: Meeting | undefined
) {
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [lastMeetingDate, setLastMeetingDate] = useState<
    string | undefined | null
  >(undefined);
  const [firstUnsyncResult, setfirstUnsyncResult] = useState<
    CheckResult | undefined
  >(undefined);

  // --------------------- handlers ------------------------

  const synchronisationCheck = async () => {
    if (!currentMeeting) return undefined;

    // fetch latest meeting result
    const meetingData = await callGraphQLApi<GraphQLResult<GetMeetingQuery>>(
      getMeeting,
      { id: currentMeeting.id }
    );

    const checkResult = {
      currentDate: currentMeeting.updatedAt,
      latestDate: meetingData.data?.getMeeting?.updatedAt,
      latestMeeting: meetingData.data?.getMeeting,
      syncOk:
        currentMeeting.updatedAt === meetingData.data?.getMeeting?.updatedAt,
    };
    return checkResult;
  };

  // --------------------- effects ------------------------

  useEffect(() => {
    const interval = setInterval(async () => {
      // we save last meeting date, if the meeting updated date has changed, we don't need a sync check yet
      if (
        currentMeeting &&
        (!lastMeetingDate || lastMeetingDate !== currentMeeting?.updatedAt)
      ) {
        console.log(
          'MEETING UNSYNC 0/2 - updating meeting',
          currentMeeting?.updatedAt
        );
        setLastMeetingDate(currentMeeting?.updatedAt);
        return;
      }

      if (currentMeeting && !isChecking) {
        console.log('RUN SYNCHRONISATION CHECK', currentMeeting?.updatedAt);
        setIsChecking(true);
        const checkResult = await synchronisationCheck();

        // Case SYNC NOT OK
        if (!checkResult?.syncOk) {
          if (!firstUnsyncResult) {
            // STEP 1 - we are unsynced, let's wait for a second check and keep result
            console.warn(
              'MEETING UNSYNC 1/2 - Running second check',
              checkResult
            );
            CaptureSentryMessage(
              'Meeting unsynchronised, running second check',
              {
                syncResult: checkResult,
              }
            );
            setfirstUnsyncResult(checkResult);
          }

          // STEP 2 - we are still unsynced, we force a reload if current meeting has not been updated since
          else {
            // case the current meeting has been updated since the first check, it means we are not unsynced anymore
            if (currentMeeting.updatedAt !== firstUnsyncResult.currentDate) {
              console.warn(
                'MEETING UNSYNC 2/2 - We are good, meeting has been updated',
                checkResult
              );
              // we are good we can continue, the meeting has been updated since the first check
              setfirstUnsyncResult(undefined);
            } else {
              console.warn(
                'MEETING UNSYNC 2/2 - Still unsyced, checking for a forced reload'
              );
              CaptureSentryMessage(
                'Meeting unsynchronised, checking for reload',
                {
                  syncResult: checkResult,
                }
              );

              // if the second check also has a different date, we force a reload
              if (checkResult?.currentDate !== checkResult?.latestDate) {
                CaptureSentryMessage('Meeting unsynchronised, Forcing reload');
                console.warn('MEETING UNSYNC 2/2 - Forcing reload');
                reloadApp();
              } else {
                CaptureSentryMessage(
                  'Meeting unsyced but still waiting for confirmation'
                );
              }
            }
          }
        }

        // CASE SYNC OK
        else {
          setfirstUnsyncResult(undefined);
        }
        setIsChecking(false);
      }
    }, SYNCHRONISATION_CHECK_INTERVAL);
    return () => {
      setIsChecking(false);
      clearInterval(interval);
    };
  });
}
