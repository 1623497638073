import { Route, Routes } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import '@aws-amplify/pubsub';
import { useTranslation } from 'react-i18next';
import { ROUTES } from './routes/Routes';
import useNetworkConnection from '@/utils/helpers/hooks/useNetwork';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '@/theme/theme';
import LoginView from './pages/auth/LoginView';
import AdminPage from './pages/admin/AdminPage';
import { VideoCallWrapper } from './pages/caseCluster/VideoCallWrapper';
import HomeView from './pages/home/HomeView';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './ui/ErrorFallback/ErrorFallback';
import { useToast, ToastId } from '@chakra-ui/react';
import { useRef } from 'react';
import AdminRoute from './routes/AdminRoute';
import LiveMeetingPermissions from './features/meeting/LiveMeetingPermissions';
import { Authenticator } from '@aws-amplify/ui-react';
import { MeetingStatsView } from './pages/meeting-stats/MeetingStatsView/MeetingStatsView';
import EmptyPageWithTitle from './pages/page404/EmptyPageWithTitle.tsx';
import MeetingStatsViewContextProvider from './features/meeting-stats/context/MeetingStatsViewContext';
import UserAuthenticationContextProvider from './features/userAuth/context/UserAuthenticationContext';
import AdminTestPage from './pages/test/AdminTestPage';
import SubscriptionDebuggerContextProvider from './services/subscriptions/SubscriptionDebuggerContext';
import { reloadApp } from './utils/reloadApp';
import PreviewMeetingPermissions from './pages/previewMeeting/PreviewMeetingPermissions';
import TestPage from './pages/test/TestPage';
import PreviewCasePermissions from './pages/previewCase/PreviewCasePermissions';

const App = () => {
  const { online } = useNetworkConnection();
  const { t } = useTranslation();
  const toast = useToast();
  const offlineNotificationToastRef = useRef<ToastId | null>(null);
  if (!online) {
    offlineNotificationToastRef.current = toast({
      status: 'warning',
      title: t('warning.noNetwork'),
      size: 'md',
      position: 'top',
      isClosable: false,
      duration: null,
    });
  } else {
    if (offlineNotificationToastRef.current) {
      toast.close(offlineNotificationToastRef.current);
    }
  }
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        console.error('Error boundary reset');
        reloadApp();
      }}
    >
      <Authenticator.Provider>
        <ChakraProvider theme={theme}>
          <SubscriptionDebuggerContextProvider>
            <UserAuthenticationContextProvider>
              <Routes>
                <Route path={ROUTES.LOGIN} element={<LoginView />} />
                <Route
                  path={`${ROUTES.ADMIN}/*`}
                  element={
                    <AdminRoute>
                      <AdminPage />
                    </AdminRoute>
                  }
                />
                <Route
                  path={`${ROUTES.ADMIN_TEST}`}
                  element={
                    <AdminRoute>
                      <AdminTestPage />
                    </AdminRoute>
                  }
                />
                <Route path="/test" element={<TestPage />} />
                {
                  //NOTE: this is not in Admin Route as it will be used for case previewing later.
                  // It's not in the Admin layout page.
                }
                <Route
                  path={ROUTES.LIVE_MEETING}
                  element={
                    <VideoCallWrapper>
                      <LiveMeetingPermissions />
                    </VideoCallWrapper>
                  }
                />
                <Route
                  path={ROUTES.PREVIEW_MEETING}
                  element={<PreviewMeetingPermissions />}
                />
                <Route
                  path={`${ROUTES.PREVIEW_CASE}/:accessCode`}
                  element={<PreviewCasePermissions />}
                />
                <Route path="/" element={<HomeView />} />
                <Route
                  path={`${ROUTES.MEETING_STATS}/:meetingID`}
                  element={
                    <MeetingStatsViewContextProvider>
                      <MeetingStatsView />
                    </MeetingStatsViewContextProvider>
                  }
                />
                <Route
                  path="*"
                  element={
                    <EmptyPageWithTitle
                      title="Sorry..."
                      subtitle="The page was not found."
                    />
                  }
                />
              </Routes>
            </UserAuthenticationContextProvider>
          </SubscriptionDebuggerContextProvider>
        </ChakraProvider>
      </Authenticator.Provider>
    </ErrorBoundary>
  );
};
export default App;
