import { config } from '@/config/remote.config';

export default function shouldActivateUserListFeature(
  meetingID: string | undefined,
  userMail: string | undefined
) {
  if (!meetingID || !userMail) return false;
  const configItem = config.USER_LIST_FEATURE.find(
    (elem) => elem.meetingID === meetingID
  );
  if (!configItem) return false;
  return (
    !configItem.users ||
    configItem.users.length === 0 ||
    configItem.users.includes(userMail)
  );
}
