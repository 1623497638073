import AuthGuard from '@/components/Auth/AuthGuard';
import MeetingListView from '@/features/meeting/MeetingListView';

function HomeView() {
  return (
    <AuthGuard>
      <MeetingListView />
    </AuthGuard>
  );
}

export default HomeView;
