import { useEffect, useState, useRef } from 'react';
import { CaseMap } from '../caseCluster.types';
import useCaseList from './useCaseList';
import { ROUTES } from '@/routes/Routes';
import { fetchCaseData } from '../helpers/fetchCaseData';
import { parseCasePaths } from '../helpers/parseCasePaths';
import { NewIPatientCase } from '@/utils/types/zod/patientCaseSchema';

/**
 *  Get the caseCluster cases only and fetch their content (slides and assets refs)
 */
function useCaseContent(caseIds: string[]) {
  const caseList = useCaseList(); // TODO: usecaselist should problaby use a context as Vendor or CaseCluster ID to filter list here already
  const [state, setState] = useState<{
    isLoading: boolean;
    casesPaths: string[] | null;
    caseMap: CaseMap;
    error: string | null;
  }>({
    isLoading: true,
    casesPaths: null,
    caseMap: {},
    error: null,
  });

  const { casesPaths } = state;
  const fetchRetryCount = useRef(0);
  const maxRetry = 5;

  useEffect(() => {
    if (caseIds.length !== 0 && caseList && !casesPaths) {
      const paths = parseCasePaths(caseList, caseIds);

      // Security, we prefer to throw an error here than to continue here
      // TODO: later be sure to display a correct modal error (or generic error page)
      if (paths.length === 0) {
        console.log('caseList', caseList);
        throw new Error(`No cases found in caseList with ids : ${caseIds}`);
      }

      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        casesPaths: paths,
      }));
    }
  }, [caseIds, caseList, casesPaths]);

  // load cases content
  useEffect(() => {
    const fetchAllCaseContent = async () => {
      try {
        const results: NewIPatientCase[] = await fetchCaseData(
          caseIds,
          state.casesPaths
        );

        const newCaseMap: CaseMap = results.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {} as CaseMap);

        console.log('newCasemap', newCaseMap);

        setState((prevState) => ({
          ...prevState,
          caseMap: newCaseMap,
          isLoading: false,
        }));
      } catch (e) {
        console.error(`Error while fetching case content: ${e}`);
        console.error(
          `retrying after 1sec (${fetchRetryCount.current}/${maxRetry})`
        );

        if (fetchRetryCount.current == maxRetry) {
          setState((prevState) => ({
            ...prevState,
            error: `error fetching cases:${e}`,
            isLoading: false,
          }));

          // redirect to home in case of multiple retry error
          window.location.replace(ROUTES.HOME);
        }

        // retry
        else {
          fetchRetryCount.current += 1;
          // wait 1sec then retry // TODO: this could be in a helper
          await new Promise<void>((resolve) => {
            setTimeout(() => {
              fetchAllCaseContent().then(() => resolve());
            }, 1000);
          });
        }
      }
    };
    if (state.casesPaths) {
      fetchAllCaseContent();
    }
  }, [state.casesPaths]);

  return {
    caseMap: state.caseMap,
    isCaseLoading: state.isLoading,
    error: state.error,
  };
}

export default useCaseContent;
