import {
  OnCreateUserMeetingJoinSubscription,
  OnUpdateUserMeetingJoinSubscription,
  User,
  UserMeetingJoin,
  UserMeetingsByMeetingIDQuery,
  UserMeetingsByMeetingIDQueryVariables,
} from '@/services/API';
import { userMeetingsByMeetingID } from '@/services/graphql/queries';
import {
  onCreateUserMeetingJoin,
  onUpdateUserMeetingJoin,
} from '@/services/graphql/subscriptions';
import useNewSubscriptions from '@/services/subscriptions/useNewSubscriptions';
import APP_CONSTANTS from '@/utils/constants/app.constants';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { CaptureSentryMessage } from '@/utils/helpers/CaptureSentryException';
import { GraphQLResult } from '@aws-amplify/api';
import { useEffect, useMemo, useState } from 'react';

const isInMeeting = (
  isCurrentlyInMeeting: boolean,
  currentPingDate: Date,
  lastPingDate: Date
) => {
  const result =
    isCurrentlyInMeeting &&
    currentPingDate.getTime() - lastPingDate.getTime() <
      APP_CONSTANTS.USER_MEETING_PING_INTERVAL * 2 * 1000;
  return result;
};

export default function useMeetingParticipantsList(
  meetingID: string | null | undefined
): User[] {
  const [meetingParticipants, setMeetingParticipants] = useState<
    UserMeetingJoin[]
  >([]);

  useEffect(() => {
    if (!meetingID) return;
    const fetchMeetingParticipants = async () => {
      const variables: UserMeetingsByMeetingIDQueryVariables = {
        meetingID,
      };
      const meetingParticipants = await callGraphQLApi<
        GraphQLResult<UserMeetingsByMeetingIDQuery>
      >(userMeetingsByMeetingID, variables);
      if (!meetingParticipants.data?.userMeetingsByMeetingID?.items) {
        CaptureSentryMessage(
          'useMeetingParticipantsList: meetingParticipants.data?.userMeetingsByMeetingID?.items is undefined',
          {
            meetingParticipants: meetingParticipants.data,
          }
        );
        return;
      }
      setMeetingParticipants((prev) => {
        const test: UserMeetingJoin[] =
          (
            meetingParticipants.data?.userMeetingsByMeetingID
              ?.items as UserMeetingJoin[]
          ).filter(
            (elem: UserMeetingJoin) =>
              elem &&
              isInMeeting(
                elem?.isCurrentlyInMeeting || false,
                new Date(),
                new Date(elem?.lastPingDate || 0)
              )
          ) || [];
        return [...prev, ...test];
      });
    };
    fetchMeetingParticipants();
  }, [meetingID]);

  const subscriptions = useMemo(() => {
    if (!meetingID) return [];
    return [
      {
        query: onCreateUserMeetingJoin,
        variables: {},
        callback: (result: OnCreateUserMeetingJoinSubscription) => {
          if (result.onCreateUserMeetingJoin?.meetingID !== meetingID) return;
          if (!result.onCreateUserMeetingJoin.user) return;
          setMeetingParticipants((prev) => [
            ...prev,
            result.onCreateUserMeetingJoin as UserMeetingJoin,
          ]);
        },
      },
      {
        query: onUpdateUserMeetingJoin,
        variables: {},
        callback: (result: OnUpdateUserMeetingJoinSubscription) => {
          if (result.onUpdateUserMeetingJoin?.meetingID !== meetingID) return;
          setMeetingParticipants((prev) => {
            const newObj = prev.filter(
              (elem) =>
                elem.id !== result.onUpdateUserMeetingJoin?.id &&
                isInMeeting(
                  elem.isCurrentlyInMeeting || false,
                  new Date(),
                  new Date(elem.lastPingDate || 0)
                )
            );

            if (
              isInMeeting(
                result.onUpdateUserMeetingJoin?.isCurrentlyInMeeting || false,
                new Date(),
                new Date(result.onUpdateUserMeetingJoin?.lastPingDate || 0)
              )
            ) {
              newObj.push(result.onUpdateUserMeetingJoin as UserMeetingJoin);
            }
            return newObj;
          });
        },
      },
    ];
  }, [meetingID]);

  useNewSubscriptions(subscriptions, 'useMeetingParticipantsList');

  return Array.from(meetingParticipants).map((elem) => elem.user);
}
