import { Tag } from '@chakra-ui/react';

type Props = {
  client: string;
};

export default function ClientTag({ client }: Props) {
  return (
    <Tag ml={2} colorScheme="blue">
      {client}
    </Tag>
  );
}
