import { AppDispatch } from '@/store/Store';
import { actions } from '@/store/slices/meeting';
import { CreateMeetingInput } from '@/services/API';
import { pureCreateMeeting } from '@/backend/pureCreateMeeting';

export function createAMeeting(input: CreateMeetingInput, userList?: string[]) {
  return async (dispatch: AppDispatch) => {
    try {
      await pureCreateMeeting(input, userList);
    } catch (error) {
      console.error(`error at createAMeeting:`, error);
      dispatch(actions.setError(error));
    }
  };
}
