import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MeetingSelectors } from '@/store/slices/meeting';
import { GlobalTheme } from '../../../../Styles';
import { useAppSelector } from '@/store/StoreHooks';
import { useUserListByMeeting } from '@/services/hooks/useUserListByMeeting';
import { FormEvent } from 'react';
import useChatMessages, {
  messageActions,
} from '@/features/call/useChatMessages';
import { Box, Flex, IconButton, Input, useToast } from '@chakra-ui/react';
import { isUrl } from '@/utils/helpers/isUrl';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { RefreshIcon } from '@/components/icons/icons';
import PopConfirm from '@/ui/PopConfirm/PopConfirm';
import mutationDeleteAllMessagesForMeeting from '@/features/meeting/messages/mutationDeleteAllMessagesForMeeting';
import { AppSpinner } from '@/ui/AppSpinner';

// --------------------- styled ------------------------

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .messages {
    flex: 1 1 auto;
    min-height: 0px; /*use this to force flex to respect size*/
    position: relative;

    flex-direction: column;
  }

  .author {
    font-family: 'poppins-regular';
    color: ${GlobalTheme.color_purple_grey};
    font-size: 0.8em;
    margin-top: 20px;
  }

  .messages-scroller {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    overflow-y: scroll;
  }

  .messageWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .messageWrapper.me {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .message {
    color: ${GlobalTheme.color_dark_grey};
    font-family: 'nunito-regular';
    /* align-self: flex-start; */
    margin-top: 4px;
    /* margin-bottom: 20px; */
    padding: 8px 12px;
    max-width: 240px;
    background: #ffffff;
    border-radius: 0px 10px 10px 10px;
    font-size: 1em;
  }

  .message.me {
    color: ${GlobalTheme.color_dark_grey};
    /* align-self: flex-end; */
    background: #e1e7ff;
    border-radius: 10px 10px 0px 10px;
  }

  .chat-bar {
    height: 64px;
    border-top: 1px solid #ddd;
  }

  .chat-bar form {
    height: 100%;
    padding: 16px;
  }

  .chat-bar form input {
    width: 100%;
    height: 32px;
    padding: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 16px;
    outline: none;
  }

  .chat-bar form input:focus {
    border: 1px solid blue;
  }
`;

type Props = {
  isVisible: boolean;
  useChatMessageUtils: ReturnType<typeof useChatMessages>;
};

function ChatView(props: Props) {
  // --------------------- state ------------------------

  const [messageBody, setMessageBody] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const currentMeeting = useAppSelector(MeetingSelectors.getCurrentMeeting);
  const channelID = currentMeeting?.id;
  const userList = useUserListByMeeting(currentMeeting?.id, true);
  const { user: userInfo, isAdmin } = useUserAuthenticationContext();
  const { t } = useTranslation();
  const toast = useToast();
  const messages = props.useChatMessageUtils.messages;
  const messagesIsLoading = props.useChatMessageUtils.isLoading;

  // --------------------- Handlers ------------------------

  const getUserName = (userID: string) => {
    const user = userList.find((u) => u.id === userID);
    if (user?.id === userInfo?.id) {
      return 'me';
    }
    // case user is not in the participant list anymore
    // TODO: we should manage this in a more robust way (using username in the message info?)
    if (!user) {
      return `unknown-${userID?.substring(4, 8)}`;
    }

    return user?.firstname;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessageBody(event.target.value);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const msg = messageBody.trim();
    if (msg === '') {
      return;
    }

    setMessageBody('');
    if (msg && userInfo?.id && channelID)
      messageActions.addMessage({ author: userInfo?.id, body: msg, channelID });
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const resetChatHistory = async () => {
    if (!channelID) {
      toast({
        title: t('chat.admin.clearChat.errorTitle'),
        description: t('chat.admin.clearChat.errorDescription'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    await mutationDeleteAllMessagesForMeeting(channelID);
    toast({
      title: t('chat.admin.clearChat.successTitle'),
      description: t('chat.admin.clearChat.successDescription'),
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  // --------------------- effect ------------------------

  useEffect(() => {
    if (props.isVisible) {
      scrollToBottom();
      props.useChatMessageUtils.markAllMessagesAsRead();
    }
  }, [messages, props.isVisible]);

  return (
    <Wrapper
      style={{
        position: 'relative',
      }}
    >
      {isAdmin && (
        <Flex top={2} left={2} position="absolute" zIndex={5}>
          <PopConfirm
            title={
              <Box>
                {t('chat.admin.clearChat.confirmText')}
                <br />
                {t('warning.confirmChoice')}
              </Box>
            }
            onConfirm={resetChatHistory}
          >
            <IconButton
              size="xs"
              borderRadius="full"
              aria-label="Refresh Button"
              border="2px solid"
              color="livelinx.lightgray"
              _hover={{
                transform: 'scale(1.1)',
                transition: 'all 0.2s ease-out',
              }}
              icon={<RefreshIcon size={14} />}
            />
          </PopConfirm>
        </Flex>
      )}
      {messagesIsLoading && (
        <Flex
          width="full"
          height="full"
          alignItems="center"
          justifyContent="center"
        >
          <AppSpinner />
        </Flex>
      )}
      <div className="messages">
        <div className="messages-scroller">
          {messages &&
            messages.map((message, index, arr) => {
              const isMe = message.author === userInfo?.id;
              const userName = getUserName(message.author);
              const prevUserName = getUserName(arr[index - 1]?.author);
              const showUserName = prevUserName !== userName;
              return (
                <div
                  key={message.id}
                  className={`messageWrapper${isMe && ' me'}`}
                >
                  {showUserName && <div className="author">{userName} </div>}
                  <div
                    key={message.id}
                    className={
                      isMe
                        ? 'message me noselect blueshadow'
                        : 'message noselect blueshadow'
                    }
                  >
                    {isUrl(message.body) ? (
                      <a href={message.body} rel="noreferrer" target="_blank">
                        {message.body}
                      </a>
                    ) : (
                      message.body
                    )}
                  </div>
                </div>
              );
            })}

          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="chat-bar">
        <form onSubmit={handleSubmit}>
          <Input
            disabled={userInfo === null}
            type="text"
            name="messageBody"
            placeholder={t('chat.typeMessagePlaceHolder') || undefined}
            onChange={handleChange}
            value={messageBody}
          />
        </form>
      </div>
    </Wrapper>
  );
}

export default ChatView;
