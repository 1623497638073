import { usePrevious } from '@/utils/helpers/react.helper';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import {
  mutationUpdateCaseSlideStats,
  mutationUpdateMeetingStats,
} from '../graphql/update';
import { useMemo } from 'react';
import { useMeetingCaseStatsDetails } from '../hooks/useMeetingCaseStatsDetails';
import useMeetingTrackingAssetStats from '@/features/tracking/useMeetingTrackingAssetStats';
import useMeetingTracking from '@/features/tracking/useMeetingTracking';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import { UpdateMeetingStatsInput } from '@/services/API';

type Props = {
  children: React.ReactNode;
  isPreview?: boolean;
};

type MeetingStatsCreationContextType = {
  trackAssetOpen: (
    assetID: string,
    assetName: string,
    _isLibraryAsset: boolean
  ) => void;
  trackAssetClose: () => void;
  trackMeetingLeave: () => void;
  trackCaseClusterStop: () => void;
  trackAnswer: (answerIndex: number, isConfirmed: boolean) => void;
  trackSlideChange: () => void;
  trackConsensusRate: (consensusRate: number) => void;
  createNPSScoreResult: (
    meetingID: string,
    userID: string,
    score: number[]
  ) => Promise<void>;
};

export const MeetingStatsCreationContext = createContext<
  MeetingStatsCreationContextType | undefined
>(undefined);

export default function MeetingStatsCreationContextProvider({
  children,
  isPreview,
}: Props) {
  const { meeting, currentCaseId, isUserHost, slideID, meetingStatsData } =
    useCurrentMeetingDataContext();
  const meetingID = meeting?.id;
  const previousCaseId = usePrevious(currentCaseId);
  const lastSlideID = usePrevious(slideID);
  const lastCaseId = usePrevious(currentCaseId);
  const [timeSpentOnSlide, setTimeSpentOnSlide] = useState<number>(0);
  const startTime = useRef<number>(0);
  const interval = useRef<ReturnType<typeof setInterval>>();

  const caseStatsDetails = useMeetingCaseStatsDetails(meetingID, true);

  const { trackAssetOpen, trackAssetClose } = useMeetingTrackingAssetStats(
    currentCaseId,
    caseStatsDetails
  );

  const {
    trackMeetingLeave,
    trackCaseClusterStop,
    trackAnswer,
    trackSlideChange,
    trackConsensusRate,
    createNPSScoreResult,
  } = useMeetingTracking(isPreview ?? false);

  const slideStatsDetailsOfLastCase = useMemo(() => {
    if (!lastCaseId || !caseStatsDetails || !caseStatsDetails[lastCaseId])
      return undefined;
    return caseStatsDetails[lastCaseId]?.slides?.items;
  }, [lastCaseId, caseStatsDetails]);

  //track slide changes
  useEffect(() => {
    if (isPreview) return; // don't track anything in preview mode
    if (lastSlideID === slideID) return;
    if (!isUserHost || meeting?.isInTesting) return;
    console.log(
      'slideChanged lastSlide currentSlide time isHost: ',
      lastSlideID,
      slideID,
      timeSpentOnSlide / 1000,
      isUserHost
    );
    if (lastSlideID && slideStatsDetailsOfLastCase) {
      const slideItem = slideStatsDetailsOfLastCase.find(
        (item) => item?.slideId == lastSlideID
      );
      if (slideItem?.id) {
        mutationUpdateCaseSlideStats(
          slideItem.id,
          slideItem.time + Math.round(timeSpentOnSlide / 1000)
        );
        // console.log('SLIDESTATS UPDATE DONE');
      } else {
        console.error(
          'useMeetingStatsCreation: slideStatsID not found ID:',
          lastSlideID
        );
      }
    }
    startTime.current = Date.now();
    interval.current = setInterval(() => {
      setTimeSpentOnSlide(Math.floor(Date.now() - startTime.current));
    }, 500);
    return () => {
      clearInterval(interval.current);
      interval.current = undefined;
    };
  }, [slideID, slideStatsDetailsOfLastCase, isPreview]);

  //track case changes
  useEffect(() => {
    if (isPreview) return; // don't track anything in preview mode
    // if (currentCaseId === undefined) return;
    if (!currentCaseId) return;
    if (!isUserHost || meeting?.isInTesting) return;
    if (previousCaseId === currentCaseId) return;
    if (!meetingStatsData) {
      console.warn(
        'caseId changed but not all data is ready',
        previousCaseId,
        currentCaseId,
        meetingStatsData
      );
      return;
    }

    console.log('caseId changed, curr', previousCaseId, currentCaseId);
    const newCasePresentationHistory = meetingStatsData.casePresentationHistory;
    // const currentCaseIndex =
    // if (currentCaseIndex === -1) {
    //   newCasePresentationHistory.push(CASE_SELECTOR);
    // } else {
    //   newCasePresentationHistory.push(currentCaseIndex.toString());
    // }
    newCasePresentationHistory.push(currentCaseId);
    const input: UpdateMeetingStatsInput = {
      id: meetingStatsData.id,
      casePresentationHistory: newCasePresentationHistory,
    };
    mutationUpdateMeetingStats(input);
    console.log('MEETINGSTATS UPDATE DONE', newCasePresentationHistory);
  }, [
    currentCaseId,
    previousCaseId,
    meetingStatsData,
    isUserHost,
    meeting?.isInTesting,
    isPreview,
  ]);

  return (
    <MeetingStatsCreationContext.Provider
      value={{
        trackAssetOpen,
        trackAssetClose,
        trackMeetingLeave,
        trackCaseClusterStop,
        trackAnswer,
        trackSlideChange,
        trackConsensusRate,
        createNPSScoreResult,
      }}
    >
      {children}
    </MeetingStatsCreationContext.Provider>
  );
}

export const useMeetingStatsCreationContext = () => {
  const context = useContext(MeetingStatsCreationContext);
  if (context === undefined) {
    throw new Error(
      'useMeetingStatsCreationContext must be used within a MeetingStatsCreationContextProvider'
    );
  }
  return context;
};
