import { useEffect, useMemo, useState } from 'react';
import {
  onCreateAnswer,
  onDeleteAnswer,
  onUpdateAnswer,
} from '@/services/graphql/subscriptions';
import { composeQuestionID } from '@/features/meeting/answer/answerHelper';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import {
  Answer,
  OnCreateAnswerSubscription,
  OnDeleteAnswerSubscription,
  OnUpdateAnswerSubscription,
} from '../API';
import { listAnswersByQuestionID } from '@/features/meeting/answer/listAnswersByQuestionId';
import isSlideQuestionSlide from '@/utils/types/type-validation/isSlideQuestionSlide';
import { SlideType } from '@/utils/types/enums';
import { NewIPatientCase } from '@/utils/types/zod/patientCaseSchema';
import useNewSubscriptions from '../subscriptions/useNewSubscriptions';

export function useAnswersBySlide(
  index: number,
  isInviewMode: boolean = false,
  meetingID: string | null | undefined = null,
  patientCase: NewIPatientCase | null | undefined = null
) {
  const [answerList, setAnswerList] = useState<Answer[]>([]);
  const [refreshTs, setRefreshTs] = useState(Date.now());
  const { user } = useUserAuthenticationContext();
  const { meeting, currentCase } = useCurrentMeetingDataContext();

  if (!meetingID) {
    meetingID = meeting?.id;
  }
  if (!patientCase) {
    patientCase = currentCase;
  }
  const currentSlide = patientCase?.slides?.[index];
  const questionID = isInviewMode
    ? undefined
    : currentSlide &&
      (isSlideQuestionSlide(currentSlide) ||
        currentSlide.type === SlideType.CUSTOM)
    ? composeQuestionID(
        meetingID as string,
        patientCase?.id as string,
        currentSlide.id
      )
    : undefined;

  // TODO: NIKO:  there is a bug here.. in the case there are multiple anwsers, we need to use the more recent.
  // we should never have multiple answers for the same questionID though
  // const answer = userAnswer.data.listAnswers.items.map[0];
  // const userAnswer = answerList?.find(
  //   (answer) => answer.user.id === (user?.id as string)
  // );
  const userAnswers = answerList?.filter(
    (answer) => answer.user.id === (user?.id as string)
  );
  const userAnswer =
    userAnswers.length &&
    userAnswers.reduce((prev, current) => {
      return prev &&
        new Date(prev!.updatedAt!).getTime() >
          new Date(current!.updatedAt!).getTime()
        ? prev
        : current;
    });

  // fetch answer list by questionId
  useEffect(() => {
    const fetchAnswers = async () => {
      if (!questionID) {
        setAnswerList([]);
        return;
      }
      try {
        const items = await listAnswersByQuestionID(questionID);
        if (items) {
          setAnswerList(items as Answer[]);
        }
      } catch (error) {
        console.error(`error with meeting answerList:${JSON.stringify(error)}`);
      }
    };
    fetchAnswers();
  }, [questionID, refreshTs]);

  const subscriptions = useMemo(() => {
    if (!questionID) return [];

    //TODO: filters don't work

    // const variables: OnCreateAnswerSubscriptionVariables = {
    //   filter: {
    //     questionID: {

    //     }
    //   }
    // }
    return [
      {
        query: onCreateAnswer,
        variables: {},
        callback: (result: OnCreateAnswerSubscription) => {
          if (result.onCreateAnswer?.questionID !== questionID) return;
          setRefreshTs(Date.now());
        },
      },
      {
        query: onUpdateAnswer,
        variables: {},
        callback: (result: OnUpdateAnswerSubscription) => {
          if (result.onUpdateAnswer?.questionID !== questionID) return;
          setRefreshTs(Date.now());
        },
      },
      {
        query: onDeleteAnswer,
        variables: {},
        callback: (result: OnDeleteAnswerSubscription) => {
          if (result.onDeleteAnswer?.questionID !== questionID) return;
          setRefreshTs(Date.now());
        },
      },
    ];
  }, [questionID]);

  useNewSubscriptions(subscriptions, 'useAnswersBySlide');

  return {
    answers: answerList,
    userAnswer,
  };
}
