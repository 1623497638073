import PageLayout from '@/ui/Layout/PageLayout';
import { Flex, Text } from '@chakra-ui/react';

interface Props {
  title: string;
  subtitle: string | string[];
}

export default function EmptyPageWithTitle({ title, subtitle }: Props) {
  return (
    <PageLayout>
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        height="100%"
      >
        <Text color="livelinx.purple200" fontSize="2rem">
          {title}
        </Text>
        {typeof subtitle === 'string' ? (
          <Text color="livelinx.purple200" fontSize="2rem">
            {subtitle}
          </Text>
        ) : (
          subtitle.map((line, index) => (
            <Text key={index} color="livelinx.purple200" fontSize="2rem">
              {line}
            </Text>
          ))
        )}
      </Flex>
    </PageLayout>
  );
}
