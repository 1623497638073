import { useEffect, useMemo, useState } from 'react';
import { listUsers, userMeetingsByMeetingID } from '@/services/graphql/queries';
import {
  onCreateUserMeetingJoin,
  onDeleteUserMeetingJoin,
} from '../graphql/subscriptions';
import {
  ListUsersQuery,
  OnCreateUserMeetingJoinSubscription,
  OnDeleteUserMeetingJoinSubscription,
  User,
  UserMeetingJoin,
  UserMeetingsByMeetingIDQuery,
} from '@/services/API';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { GraphQLResult } from '@aws-amplify/api';
import useNewSubscriptions from '../subscriptions/useNewSubscriptions';
import { CaptureSentryException } from '@/utils/helpers/CaptureSentryException';

/**
 * use global user list (should be only used in admin actually, or must be filtered somehow)
 */
export function useUserList() {
  const [userList, setUserList] = useState<User[]>([]);

  useEffect(() => {
    callGraphQLApi<GraphQLResult<ListUsersQuery>>(listUsers, {})
      .then((response) => {
        const items = response?.data?.listUsers?.items;
        if (items) {
          setUserList(items as User[]);
        }
      })
      .catch((error) => {
        console.error(`error with userlist:${JSON.stringify(error)}`);
      });
  }, []);
  return userList;
}

/**
 * use meeting participant list
 */
export function useUserListByMeeting(
  meetingID: string | undefined,
  live: boolean
) {
  const [userList, setUserList] = useState<User[]>([]);

  // fetch user list by meeting
  useEffect(() => {
    if (!meetingID) return;
    callGraphQLApi<GraphQLResult<UserMeetingsByMeetingIDQuery>>(
      userMeetingsByMeetingID,
      {
        meetingID,
        sortDirection: 'ASC',
      }
    )
      .then((response) => {
        const items = response?.data?.userMeetingsByMeetingID
          ?.items as UserMeetingJoin[];
        if (items && items.length) {
          setUserList(items.map((item) => item.user));
        }
      })
      .catch((error) => {
        CaptureSentryException(error, {
          message: 'error with meeting userList',
          meetingID,
          userList,
        });
        console.error(`error with meeting userList:${JSON.stringify(error)}`);
      });
  }, [meetingID]);

  //live part
  if (live) {
    const subscriptions = useMemo(() => {
      if (!meetingID) return [];
      return [
        {
          query: onCreateUserMeetingJoin,
          variables: {
            meetingID,
          },
          callback: (response: OnCreateUserMeetingJoinSubscription) => {
            const item = response.onCreateUserMeetingJoin;
            if (item) {
              setUserList((prev) => [...prev, item.user as User]);
            }
          },
        },
        {
          query: onDeleteUserMeetingJoin,
          variables: {
            meetingID,
          },
          callback: (response: OnDeleteUserMeetingJoinSubscription) => {
            const item = response.onDeleteUserMeetingJoin;
            if (item) {
              setUserList((prev) =>
                prev.filter((elem) => elem.id !== item.userID)
              );
            }
          },
        },
      ];
    }, [meetingID]);

    useNewSubscriptions(subscriptions, 'useUserListByMeeting');
  }

  return userList;
}
