import {
  Button,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { FaAngleDown } from 'react-icons/fa';

type options = {
  value: string;
  optionLabel: string;
};
type DropdownOptionsData = {
  label: string;
  options: options[];
};
type sortingOptionsType =
  | 'caseClusterAscending'
  | 'caseClusterDescending'
  | 'hostAscending'
  | 'hostDescending'
  | 'meetingAscending'
  | 'meetingDescending'
  | 'dateDescending';

type Props = {
  options: Array<DropdownOptionsData>;
  value: string;
  title: string;
  handleFilterValue: (data: sortingOptionsType) => void;
};

const DropDownButton = ({
  options,
  value,
  title,
  handleFilterValue,
}: Props) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        boxShadow="md"
        size="sm"
        bg="white"
        rightIcon={<FaAngleDown />}
        ml="4"
      >
        <span style={{ color: '#454343' }}>{title} :</span> {value}
      </MenuButton>
      <MenuList>
        {options.map((data, index) => (
          <MenuGroup title={data.label} key={index + data.label}>
            {data.options.map((option: options, i: number) => (
              <MenuItem
                key={i + option.value}
                bg={value === option.value ? 'gray.100' : ''}
                // onClick={() => {
                //   handleFilterValue(option.value as sortingOptionsType);
                // }}
                onClick={() =>
                  handleFilterValue(option.value as sortingOptionsType)
                }
              >
                {option.optionLabel}
              </MenuItem>
            ))}
          </MenuGroup>
        ))}
      </MenuList>
    </Menu>
  );
};

export default DropDownButton;
