import getAvailableUserMeetings from '@/features/meeting/helpers/getAvailableUserMeetings';
import { MeetingSelectors } from '@/store/slices/meeting';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ROUTES } from '@/routes/Routes';
import { useTranslation } from 'react-i18next';
import { trackPageView } from '@/features/tracking/trackingHelpers';
import MeetingList from '@/pages/admin/components/MeetingList/MeetingList';
import { Box, Center, Fade, Flex, Heading, Button } from '@chakra-ui/react';
import { useUserMeetingPing } from '@/services/hooks/useUserMeetingPing';
import {
  Meeting,
  UpdateMeetingMutation,
  UpdateMeetingMutationVariables,
} from '@/services/API';
import { fetchMeetingList } from '@/store/thunk/meeting';
import { useAppDispatch, useAppSelector } from '@/store/StoreHooks';
import PageLayout from '@/ui/Layout/PageLayout';
import { callGraphQLApi } from '@/utils/graphQLAPI';
import { updateMeeting } from '@/services/graphql/mutations';
import { GraphQLResult } from '@aws-amplify/api';
import { useUserAuthenticationContext } from '../userAuth/context/UserAuthenticationContext';
import { useEffect, useMemo } from 'react';
import PopConfirm from '@/ui/PopConfirm/PopConfirm';
import useDoStatsExistForMeetings from './answer/hooks/useDoStatsExistForMeetings';
import useMeetingSubscription from '@/services/subscriptions/useMeetingSubscription';
import useMeetingUserAccessForUser from '@/services/hooks/useMeetingUserAccessForUser';
import pureRestartMeeting from '@/backend/pureRestartMeeting';
import getJoinMeetingButtonText from './utils/getJoinMeetingButtonText';

// --------------------- styled ------------------------

const TableWrapper = styled.div``;

function MeetingListView() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, isAdmin, userClients } = useUserAuthenticationContext();
  const { t } = useTranslation();

  const { userAccessList } = useMeetingUserAccessForUser(user?.id);
  // recover full meeting list
  const originalMeetingList = useAppSelector(MeetingSelectors.getMeetingList);

  const meetingList = useMemo(() => {
    if (!originalMeetingList || !user) return [];
    let toReturn = originalMeetingList.filter(
      (meeting) => meeting.isArchived === false
    );
    toReturn = getAvailableUserMeetings(
      toReturn,
      user,
      userClients,
      userAccessList,
      isAdmin
    );
    return toReturn;
  }, [originalMeetingList, userAccessList.length, user, userClients]);

  // filter to use only available meetings

  const statsExistForMeeting = useDoStatsExistForMeetings(meetingList);

  useMeetingSubscription();

  // --------------------- methods ------------------------

  const goToLiveMeetingPage = (
    meetingID: string,
    testMode: boolean = false
  ) => {
    // TODO: THere is an issue here, when we switch meeting, the meeting is not updated in the store
    // so we are redirected to the correct url, but something is wrong with the case state
    // we need to investigate, for now me make a full refresh
    // navigate(`${ROUTES.LIVE_MEETING}?meeting=${meetingID}`, { replace: true });
    let newUrl = `${ROUTES.LIVE_MEETING}?meeting=${meetingID}`;
    if (testMode) {
      newUrl = `${newUrl}&testMode=true`;
    }
    window.location.href = newUrl;
  };

  // handle meeting join click
  const handleMeetingJoin = async (
    meetingID: string,
    testMode: boolean = false
  ) => {
    goToLiveMeetingPage(meetingID, testMode);
  };
  const handleMeetingRestart = async (meetingID: string) => {
    await pureRestartMeeting(meetingID);
    goToLiveMeetingPage(meetingID);
  };
  const handleTestMeeting = async (meetingID: string) => {
    const variables: UpdateMeetingMutationVariables = {
      input: {
        id: meetingID,
        isInTesting: true,
      },
    };
    await callGraphQLApi<GraphQLResult<UpdateMeetingMutation>>(
      updateMeeting,
      variables
    );
    handleMeetingJoin(meetingID, true);
  };
  const handleMeetingStats = (meetingID: string) => {
    navigate(`${ROUTES.MEETING_STATS}/${meetingID}`);
  };

  // --------------------- effects ------------------------

  // ping user
  // TODO: merge this with auto checking network status
  useUserMeetingPing(user);

  // fetch meetings
  useEffect(() => {
    dispatch(fetchMeetingList());
  }, [dispatch]);

  // track
  useEffect(() => {
    if (user?.id) {
      trackPageView({ pageUrl: 'home', pageTitle: 'home', userId: user?.id });
    }
  }, [user?.id]);

  // SIMPLE auto connect if only one meeting today
  useEffect(() => {
    if (meetingList && meetingList.length === 1) {
      // ok we have only one meeting in list, just check if the date is today
      const meeting = meetingList[0];
      const today = new Date().toDateString();
      const eventDate = new Date(meeting.eventDate).toDateString();
      if (today === eventDate && meeting.host?.id !== user?.id)
        handleMeetingJoin(meeting.id);
    }
  }, [meetingList]);

  // --------------------- render ------------------------

  return (
    <PageLayout>
      <Box p="20px" bg="livelinx.grey100" h="100%">
        {user && meetingList.length == 0 && (
          <Fade in={true} delay={1}>
            <Box h="full">
              <Center h="100px">
                <Heading size="sm" color="grey">
                  {t('home.noMeeting')}
                </Heading>
              </Center>
            </Box>
          </Fade>
        )}

        {meetingList.length > 0 && user && (
          <Fade in={true}>
            <TableWrapper>
              <MeetingList
                data={meetingList}
                actions={[
                  {
                    render: (item: Meeting) => {
                      const doStatsExistForThisMeeting =
                        statsExistForMeeting[item.id];
                      const isHost = item?.host?.id === user.id;
                      return (
                        <Flex direction="column" gap={2} width="150px">
                          <Button
                            variant="primary"
                            size="md"
                            onClick={async () => {
                              if (isHost && item.isDemo) {
                                await pureRestartMeeting(item.id);
                              }
                              await handleMeetingJoin(item.id);
                            }}
                            w="100%"
                          >
                            {getJoinMeetingButtonText(
                              t,
                              isHost,
                              doStatsExistForThisMeeting,
                              item
                            )}
                          </Button>
                          {isHost && (
                            <>
                              {!item.isDemo && (
                                <Button
                                  variant="blue"
                                  size="xs"
                                  onClick={() => {
                                    handleTestMeeting(item.id);
                                  }}
                                >
                                  {t('admin.home.table.testMeeting.button')}
                                </Button>
                              )}
                              {!item.isDemo && doStatsExistForThisMeeting && (
                                <PopConfirm
                                  title={
                                    <div>
                                      {t(
                                        'admin.home.table.restartMeeting.button.confirmText'
                                      )}
                                      <br />
                                      {t('meeting.header.confirmRestart')}
                                    </div>
                                  }
                                  onConfirm={() => {
                                    handleMeetingRestart(item.id);
                                  }}
                                >
                                  <Button variant="blue" size="xs">
                                    {t(
                                      'admin.home.table.restartMeeting.button'
                                    )}
                                  </Button>
                                </PopConfirm>
                              )}
                              {doStatsExistForThisMeeting && (
                                <Button
                                  variant="blue"
                                  size="xs"
                                  onClick={() => {
                                    handleMeetingStats(item.id);
                                  }}
                                >
                                  {t('admin.home.table.meetingStats.button')}
                                </Button>
                              )}
                            </>
                          )}
                        </Flex>
                      );
                    },
                  },
                ]}
              />
            </TableWrapper>
          </Fade>
        )}
      </Box>
    </PageLayout>
  );
}

export default MeetingListView;
