/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateUser = /* GraphQL */ `subscription OnCreateUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onCreateUser(filter: $filter, owner: $owner) {
    id
    firstname
    lastname
    email
    createdAt
    updatedAt
    meetingAccess {
      items {
        id
        owner
        userID
        meetingID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    last_seen
    currentMeetingID
    currentMeeting {
      id
      owner
      name
      caseClusterID
      hostID
      host {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      hostFullName
      createdAt
      updatedAt
      eventDate
      endDate
      eventLocation
      participants {
        items {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        nextToken
        __typename
      }
      userAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      guestUrlCode
      previewCode
      clientIDs
      currentCase
      currentSlide
      caseCluster {
        id
        owner
        name
        cases
        createdAt
        updatedAt
        isMock
        __typename
      }
      caseStats {
        items {
          id
          owner
          meetingID
          caseId
          version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      meetingStats {
        id
        owner
        meetingID
        casePresentationHistory
        version
        createdAt
        updatedAt
        __typename
      }
      purchaseOrderNbr
      meetingNotes
      meetingType
      isMock
      isInTesting
      isArchived
      isDemo
      __typename
    }
    isTemporary
    owner
    isMock
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSubscriptionVariables,
  APITypes.OnCreateUserSubscription
>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onUpdateUser(filter: $filter, owner: $owner) {
    id
    firstname
    lastname
    email
    createdAt
    updatedAt
    meetingAccess {
      items {
        id
        owner
        userID
        meetingID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    last_seen
    currentMeetingID
    currentMeeting {
      id
      owner
      name
      caseClusterID
      hostID
      host {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      hostFullName
      createdAt
      updatedAt
      eventDate
      endDate
      eventLocation
      participants {
        items {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        nextToken
        __typename
      }
      userAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      guestUrlCode
      previewCode
      clientIDs
      currentCase
      currentSlide
      caseCluster {
        id
        owner
        name
        cases
        createdAt
        updatedAt
        isMock
        __typename
      }
      caseStats {
        items {
          id
          owner
          meetingID
          caseId
          version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      meetingStats {
        id
        owner
        meetingID
        casePresentationHistory
        version
        createdAt
        updatedAt
        __typename
      }
      purchaseOrderNbr
      meetingNotes
      meetingType
      isMock
      isInTesting
      isArchived
      isDemo
      __typename
    }
    isTemporary
    owner
    isMock
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSubscriptionVariables,
  APITypes.OnUpdateUserSubscription
>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser(
  $filter: ModelSubscriptionUserFilterInput
  $owner: String
) {
  onDeleteUser(filter: $filter, owner: $owner) {
    id
    firstname
    lastname
    email
    createdAt
    updatedAt
    meetingAccess {
      items {
        id
        owner
        userID
        meetingID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    last_seen
    currentMeetingID
    currentMeeting {
      id
      owner
      name
      caseClusterID
      hostID
      host {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      hostFullName
      createdAt
      updatedAt
      eventDate
      endDate
      eventLocation
      participants {
        items {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        nextToken
        __typename
      }
      userAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      guestUrlCode
      previewCode
      clientIDs
      currentCase
      currentSlide
      caseCluster {
        id
        owner
        name
        cases
        createdAt
        updatedAt
        isMock
        __typename
      }
      caseStats {
        items {
          id
          owner
          meetingID
          caseId
          version
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      meetingStats {
        id
        owner
        meetingID
        casePresentationHistory
        version
        createdAt
        updatedAt
        __typename
      }
      purchaseOrderNbr
      meetingNotes
      meetingType
      isMock
      isInTesting
      isArchived
      isDemo
      __typename
    }
    isTemporary
    owner
    isMock
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSubscriptionVariables,
  APITypes.OnDeleteUserSubscription
>;
export const onCreateUserClient = /* GraphQL */ `subscription OnCreateUserClient(
  $filter: ModelSubscriptionUserClientFilterInput
  $owner: String
) {
  onCreateUserClient(filter: $filter, owner: $owner) {
    id
    owner
    clientID
    client {
      id
      clientName
      createdAt
      updatedAt
      owner
      __typename
    }
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserClientSubscriptionVariables,
  APITypes.OnCreateUserClientSubscription
>;
export const onUpdateUserClient = /* GraphQL */ `subscription OnUpdateUserClient(
  $filter: ModelSubscriptionUserClientFilterInput
  $owner: String
) {
  onUpdateUserClient(filter: $filter, owner: $owner) {
    id
    owner
    clientID
    client {
      id
      clientName
      createdAt
      updatedAt
      owner
      __typename
    }
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserClientSubscriptionVariables,
  APITypes.OnUpdateUserClientSubscription
>;
export const onDeleteUserClient = /* GraphQL */ `subscription OnDeleteUserClient(
  $filter: ModelSubscriptionUserClientFilterInput
  $owner: String
) {
  onDeleteUserClient(filter: $filter, owner: $owner) {
    id
    owner
    clientID
    client {
      id
      clientName
      createdAt
      updatedAt
      owner
      __typename
    }
    userID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserClientSubscriptionVariables,
  APITypes.OnDeleteUserClientSubscription
>;
export const onCreateClient = /* GraphQL */ `subscription OnCreateClient(
  $filter: ModelSubscriptionClientFilterInput
  $owner: String
) {
  onCreateClient(filter: $filter, owner: $owner) {
    id
    clientName
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateClientSubscriptionVariables,
  APITypes.OnCreateClientSubscription
>;
export const onUpdateClient = /* GraphQL */ `subscription OnUpdateClient(
  $filter: ModelSubscriptionClientFilterInput
  $owner: String
) {
  onUpdateClient(filter: $filter, owner: $owner) {
    id
    clientName
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateClientSubscriptionVariables,
  APITypes.OnUpdateClientSubscription
>;
export const onDeleteClient = /* GraphQL */ `subscription OnDeleteClient(
  $filter: ModelSubscriptionClientFilterInput
  $owner: String
) {
  onDeleteClient(filter: $filter, owner: $owner) {
    id
    clientName
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteClientSubscriptionVariables,
  APITypes.OnDeleteClientSubscription
>;
export const onCreateUserMeetingAccess = /* GraphQL */ `subscription OnCreateUserMeetingAccess(
  $filter: ModelSubscriptionUserMeetingAccessFilterInput
  $owner: String
) {
  onCreateUserMeetingAccess(filter: $filter, owner: $owner) {
    id
    owner
    userID
    meetingID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserMeetingAccessSubscriptionVariables,
  APITypes.OnCreateUserMeetingAccessSubscription
>;
export const onUpdateUserMeetingAccess = /* GraphQL */ `subscription OnUpdateUserMeetingAccess(
  $filter: ModelSubscriptionUserMeetingAccessFilterInput
  $owner: String
) {
  onUpdateUserMeetingAccess(filter: $filter, owner: $owner) {
    id
    owner
    userID
    meetingID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserMeetingAccessSubscriptionVariables,
  APITypes.OnUpdateUserMeetingAccessSubscription
>;
export const onDeleteUserMeetingAccess = /* GraphQL */ `subscription OnDeleteUserMeetingAccess(
  $filter: ModelSubscriptionUserMeetingAccessFilterInput
  $owner: String
) {
  onDeleteUserMeetingAccess(filter: $filter, owner: $owner) {
    id
    owner
    userID
    meetingID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserMeetingAccessSubscriptionVariables,
  APITypes.OnDeleteUserMeetingAccessSubscription
>;
export const onCreateMeeting = /* GraphQL */ `subscription OnCreateMeeting(
  $filter: ModelSubscriptionMeetingFilterInput
  $owner: String
) {
  onCreateMeeting(filter: $filter, owner: $owner) {
    id
    owner
    name
    caseClusterID
    hostID
    host {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    hostFullName
    createdAt
    updatedAt
    eventDate
    endDate
    eventLocation
    participants {
      items {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      nextToken
      __typename
    }
    userAccess {
      items {
        id
        owner
        userID
        meetingID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    guestUrlCode
    previewCode
    clientIDs
    currentCase
    currentSlide
    caseCluster {
      id
      owner
      name
      cases
      createdAt
      updatedAt
      isMock
      __typename
    }
    caseStats {
      items {
        id
        owner
        meetingID
        caseId
        assetViews {
          nextToken
          __typename
        }
        slides {
          nextToken
          __typename
        }
        version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    meetingStats {
      id
      owner
      meetingID
      casePresentationHistory
      version
      createdAt
      updatedAt
      __typename
    }
    purchaseOrderNbr
    meetingNotes
    meetingType
    isMock
    isInTesting
    isArchived
    isDemo
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMeetingSubscriptionVariables,
  APITypes.OnCreateMeetingSubscription
>;
export const onUpdateMeeting = /* GraphQL */ `subscription OnUpdateMeeting(
  $filter: ModelSubscriptionMeetingFilterInput
  $owner: String
) {
  onUpdateMeeting(filter: $filter, owner: $owner) {
    id
    owner
    name
    caseClusterID
    hostID
    host {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    hostFullName
    createdAt
    updatedAt
    eventDate
    endDate
    eventLocation
    participants {
      items {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      nextToken
      __typename
    }
    userAccess {
      items {
        id
        owner
        userID
        meetingID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    guestUrlCode
    previewCode
    clientIDs
    currentCase
    currentSlide
    caseCluster {
      id
      owner
      name
      cases
      createdAt
      updatedAt
      isMock
      __typename
    }
    caseStats {
      items {
        id
        owner
        meetingID
        caseId
        assetViews {
          nextToken
          __typename
        }
        slides {
          nextToken
          __typename
        }
        version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    meetingStats {
      id
      owner
      meetingID
      casePresentationHistory
      version
      createdAt
      updatedAt
      __typename
    }
    purchaseOrderNbr
    meetingNotes
    meetingType
    isMock
    isInTesting
    isArchived
    isDemo
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMeetingSubscriptionVariables,
  APITypes.OnUpdateMeetingSubscription
>;
export const onDeleteMeeting = /* GraphQL */ `subscription OnDeleteMeeting(
  $filter: ModelSubscriptionMeetingFilterInput
  $owner: String
) {
  onDeleteMeeting(filter: $filter, owner: $owner) {
    id
    owner
    name
    caseClusterID
    hostID
    host {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    hostFullName
    createdAt
    updatedAt
    eventDate
    endDate
    eventLocation
    participants {
      items {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        meetingAccess {
          nextToken
          __typename
        }
        last_seen
        currentMeetingID
        currentMeeting {
          id
          owner
          name
          caseClusterID
          hostID
          hostFullName
          createdAt
          updatedAt
          eventDate
          endDate
          eventLocation
          guestUrlCode
          previewCode
          clientIDs
          currentCase
          currentSlide
          purchaseOrderNbr
          meetingNotes
          meetingType
          isMock
          isInTesting
          isArchived
          isDemo
          __typename
        }
        isTemporary
        owner
        isMock
        __typename
      }
      nextToken
      __typename
    }
    userAccess {
      items {
        id
        owner
        userID
        meetingID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    guestUrlCode
    previewCode
    clientIDs
    currentCase
    currentSlide
    caseCluster {
      id
      owner
      name
      cases
      createdAt
      updatedAt
      isMock
      __typename
    }
    caseStats {
      items {
        id
        owner
        meetingID
        caseId
        assetViews {
          nextToken
          __typename
        }
        slides {
          nextToken
          __typename
        }
        version
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    meetingStats {
      id
      owner
      meetingID
      casePresentationHistory
      version
      createdAt
      updatedAt
      __typename
    }
    purchaseOrderNbr
    meetingNotes
    meetingType
    isMock
    isInTesting
    isArchived
    isDemo
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMeetingSubscriptionVariables,
  APITypes.OnDeleteMeetingSubscription
>;
export const onCreateUserMeetingJoin = /* GraphQL */ `subscription OnCreateUserMeetingJoin(
  $filter: ModelSubscriptionUserMeetingJoinFilterInput
  $owner: String
) {
  onCreateUserMeetingJoin(filter: $filter, owner: $owner) {
    id
    owner
    userID
    meetingID
    user {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    isCurrentlyInMeeting
    lastPingDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserMeetingJoinSubscriptionVariables,
  APITypes.OnCreateUserMeetingJoinSubscription
>;
export const onUpdateUserMeetingJoin = /* GraphQL */ `subscription OnUpdateUserMeetingJoin(
  $filter: ModelSubscriptionUserMeetingJoinFilterInput
  $owner: String
) {
  onUpdateUserMeetingJoin(filter: $filter, owner: $owner) {
    id
    owner
    userID
    meetingID
    user {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    isCurrentlyInMeeting
    lastPingDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserMeetingJoinSubscriptionVariables,
  APITypes.OnUpdateUserMeetingJoinSubscription
>;
export const onDeleteUserMeetingJoin = /* GraphQL */ `subscription OnDeleteUserMeetingJoin(
  $filter: ModelSubscriptionUserMeetingJoinFilterInput
  $owner: String
) {
  onDeleteUserMeetingJoin(filter: $filter, owner: $owner) {
    id
    owner
    userID
    meetingID
    user {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    isCurrentlyInMeeting
    lastPingDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserMeetingJoinSubscriptionVariables,
  APITypes.OnDeleteUserMeetingJoinSubscription
>;
export const onCreateAnswer = /* GraphQL */ `subscription OnCreateAnswer(
  $filter: ModelSubscriptionAnswerFilterInput
  $owner: String
) {
  onCreateAnswer(filter: $filter, owner: $owner) {
    id
    owner
    questionID
    userID
    meetingID
    answerIndexes
    answerTexts
    createdAt
    updatedAt
    user {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAnswerSubscriptionVariables,
  APITypes.OnCreateAnswerSubscription
>;
export const onUpdateAnswer = /* GraphQL */ `subscription OnUpdateAnswer(
  $filter: ModelSubscriptionAnswerFilterInput
  $owner: String
) {
  onUpdateAnswer(filter: $filter, owner: $owner) {
    id
    owner
    questionID
    userID
    meetingID
    answerIndexes
    answerTexts
    createdAt
    updatedAt
    user {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAnswerSubscriptionVariables,
  APITypes.OnUpdateAnswerSubscription
>;
export const onDeleteAnswer = /* GraphQL */ `subscription OnDeleteAnswer(
  $filter: ModelSubscriptionAnswerFilterInput
  $owner: String
) {
  onDeleteAnswer(filter: $filter, owner: $owner) {
    id
    owner
    questionID
    userID
    meetingID
    answerIndexes
    answerTexts
    createdAt
    updatedAt
    user {
      id
      firstname
      lastname
      email
      createdAt
      updatedAt
      meetingAccess {
        items {
          id
          owner
          userID
          meetingID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      last_seen
      currentMeetingID
      currentMeeting {
        id
        owner
        name
        caseClusterID
        hostID
        host {
          id
          firstname
          lastname
          email
          createdAt
          updatedAt
          last_seen
          currentMeetingID
          isTemporary
          owner
          isMock
          __typename
        }
        hostFullName
        createdAt
        updatedAt
        eventDate
        endDate
        eventLocation
        participants {
          nextToken
          __typename
        }
        userAccess {
          nextToken
          __typename
        }
        guestUrlCode
        previewCode
        clientIDs
        currentCase
        currentSlide
        caseCluster {
          id
          owner
          name
          cases
          createdAt
          updatedAt
          isMock
          __typename
        }
        caseStats {
          nextToken
          __typename
        }
        meetingStats {
          id
          owner
          meetingID
          casePresentationHistory
          version
          createdAt
          updatedAt
          __typename
        }
        purchaseOrderNbr
        meetingNotes
        meetingType
        isMock
        isInTesting
        isArchived
        isDemo
        __typename
      }
      isTemporary
      owner
      isMock
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAnswerSubscriptionVariables,
  APITypes.OnDeleteAnswerSubscription
>;
export const onCreateCaseCluster = /* GraphQL */ `subscription OnCreateCaseCluster(
  $filter: ModelSubscriptionCaseClusterFilterInput
  $owner: String
) {
  onCreateCaseCluster(filter: $filter, owner: $owner) {
    id
    owner
    name
    cases
    createdAt
    updatedAt
    isMock
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCaseClusterSubscriptionVariables,
  APITypes.OnCreateCaseClusterSubscription
>;
export const onUpdateCaseCluster = /* GraphQL */ `subscription OnUpdateCaseCluster(
  $filter: ModelSubscriptionCaseClusterFilterInput
  $owner: String
) {
  onUpdateCaseCluster(filter: $filter, owner: $owner) {
    id
    owner
    name
    cases
    createdAt
    updatedAt
    isMock
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCaseClusterSubscriptionVariables,
  APITypes.OnUpdateCaseClusterSubscription
>;
export const onDeleteCaseCluster = /* GraphQL */ `subscription OnDeleteCaseCluster(
  $filter: ModelSubscriptionCaseClusterFilterInput
  $owner: String
) {
  onDeleteCaseCluster(filter: $filter, owner: $owner) {
    id
    owner
    name
    cases
    createdAt
    updatedAt
    isMock
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCaseClusterSubscriptionVariables,
  APITypes.OnDeleteCaseClusterSubscription
>;
export const onCreateMessage = /* GraphQL */ `subscription OnCreateMessage(
  $filter: ModelSubscriptionMessageFilterInput
  $owner: String
) {
  onCreateMessage(filter: $filter, owner: $owner) {
    id
    owner
    channelID
    author
    body
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMessageSubscriptionVariables,
  APITypes.OnCreateMessageSubscription
>;
export const onUpdateMessage = /* GraphQL */ `subscription OnUpdateMessage(
  $filter: ModelSubscriptionMessageFilterInput
  $owner: String
) {
  onUpdateMessage(filter: $filter, owner: $owner) {
    id
    owner
    channelID
    author
    body
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMessageSubscriptionVariables,
  APITypes.OnUpdateMessageSubscription
>;
export const onDeleteMessage = /* GraphQL */ `subscription OnDeleteMessage(
  $filter: ModelSubscriptionMessageFilterInput
  $owner: String
) {
  onDeleteMessage(filter: $filter, owner: $owner) {
    id
    owner
    channelID
    author
    body
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMessageSubscriptionVariables,
  APITypes.OnDeleteMessageSubscription
>;
export const onCreateMeetingStats = /* GraphQL */ `subscription OnCreateMeetingStats(
  $filter: ModelSubscriptionMeetingStatsFilterInput
  $owner: String
) {
  onCreateMeetingStats(filter: $filter, owner: $owner) {
    id
    owner
    meetingID
    casePresentationHistory
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMeetingStatsSubscriptionVariables,
  APITypes.OnCreateMeetingStatsSubscription
>;
export const onUpdateMeetingStats = /* GraphQL */ `subscription OnUpdateMeetingStats(
  $filter: ModelSubscriptionMeetingStatsFilterInput
  $owner: String
) {
  onUpdateMeetingStats(filter: $filter, owner: $owner) {
    id
    owner
    meetingID
    casePresentationHistory
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMeetingStatsSubscriptionVariables,
  APITypes.OnUpdateMeetingStatsSubscription
>;
export const onDeleteMeetingStats = /* GraphQL */ `subscription OnDeleteMeetingStats(
  $filter: ModelSubscriptionMeetingStatsFilterInput
  $owner: String
) {
  onDeleteMeetingStats(filter: $filter, owner: $owner) {
    id
    owner
    meetingID
    casePresentationHistory
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMeetingStatsSubscriptionVariables,
  APITypes.OnDeleteMeetingStatsSubscription
>;
export const onCreateCaseStats = /* GraphQL */ `subscription OnCreateCaseStats(
  $filter: ModelSubscriptionCaseStatsFilterInput
  $owner: String
) {
  onCreateCaseStats(filter: $filter, owner: $owner) {
    id
    owner
    meetingID
    caseId
    assetViews {
      items {
        id
        owner
        assetId
        userID
        timeWatched
        version
        createdAt
        updatedAt
        caseStatsAssetViewsId
        __typename
      }
      nextToken
      __typename
    }
    slides {
      items {
        id
        owner
        slideId
        time
        version
        createdAt
        updatedAt
        caseStatsSlidesId
        __typename
      }
      nextToken
      __typename
    }
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCaseStatsSubscriptionVariables,
  APITypes.OnCreateCaseStatsSubscription
>;
export const onUpdateCaseStats = /* GraphQL */ `subscription OnUpdateCaseStats(
  $filter: ModelSubscriptionCaseStatsFilterInput
  $owner: String
) {
  onUpdateCaseStats(filter: $filter, owner: $owner) {
    id
    owner
    meetingID
    caseId
    assetViews {
      items {
        id
        owner
        assetId
        userID
        timeWatched
        version
        createdAt
        updatedAt
        caseStatsAssetViewsId
        __typename
      }
      nextToken
      __typename
    }
    slides {
      items {
        id
        owner
        slideId
        time
        version
        createdAt
        updatedAt
        caseStatsSlidesId
        __typename
      }
      nextToken
      __typename
    }
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCaseStatsSubscriptionVariables,
  APITypes.OnUpdateCaseStatsSubscription
>;
export const onDeleteCaseStats = /* GraphQL */ `subscription OnDeleteCaseStats(
  $filter: ModelSubscriptionCaseStatsFilterInput
  $owner: String
) {
  onDeleteCaseStats(filter: $filter, owner: $owner) {
    id
    owner
    meetingID
    caseId
    assetViews {
      items {
        id
        owner
        assetId
        userID
        timeWatched
        version
        createdAt
        updatedAt
        caseStatsAssetViewsId
        __typename
      }
      nextToken
      __typename
    }
    slides {
      items {
        id
        owner
        slideId
        time
        version
        createdAt
        updatedAt
        caseStatsSlidesId
        __typename
      }
      nextToken
      __typename
    }
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCaseStatsSubscriptionVariables,
  APITypes.OnDeleteCaseStatsSubscription
>;
export const onCreateCaseAssetView = /* GraphQL */ `subscription OnCreateCaseAssetView(
  $filter: ModelSubscriptionCaseAssetViewFilterInput
  $owner: String
) {
  onCreateCaseAssetView(filter: $filter, owner: $owner) {
    id
    owner
    assetId
    userID
    timeWatched
    version
    createdAt
    updatedAt
    caseStatsAssetViewsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCaseAssetViewSubscriptionVariables,
  APITypes.OnCreateCaseAssetViewSubscription
>;
export const onUpdateCaseAssetView = /* GraphQL */ `subscription OnUpdateCaseAssetView(
  $filter: ModelSubscriptionCaseAssetViewFilterInput
  $owner: String
) {
  onUpdateCaseAssetView(filter: $filter, owner: $owner) {
    id
    owner
    assetId
    userID
    timeWatched
    version
    createdAt
    updatedAt
    caseStatsAssetViewsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCaseAssetViewSubscriptionVariables,
  APITypes.OnUpdateCaseAssetViewSubscription
>;
export const onDeleteCaseAssetView = /* GraphQL */ `subscription OnDeleteCaseAssetView(
  $filter: ModelSubscriptionCaseAssetViewFilterInput
  $owner: String
) {
  onDeleteCaseAssetView(filter: $filter, owner: $owner) {
    id
    owner
    assetId
    userID
    timeWatched
    version
    createdAt
    updatedAt
    caseStatsAssetViewsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCaseAssetViewSubscriptionVariables,
  APITypes.OnDeleteCaseAssetViewSubscription
>;
export const onCreateCaseSlideStats = /* GraphQL */ `subscription OnCreateCaseSlideStats(
  $filter: ModelSubscriptionCaseSlideStatsFilterInput
  $owner: String
) {
  onCreateCaseSlideStats(filter: $filter, owner: $owner) {
    id
    owner
    slideId
    time
    version
    createdAt
    updatedAt
    caseStatsSlidesId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCaseSlideStatsSubscriptionVariables,
  APITypes.OnCreateCaseSlideStatsSubscription
>;
export const onUpdateCaseSlideStats = /* GraphQL */ `subscription OnUpdateCaseSlideStats(
  $filter: ModelSubscriptionCaseSlideStatsFilterInput
  $owner: String
) {
  onUpdateCaseSlideStats(filter: $filter, owner: $owner) {
    id
    owner
    slideId
    time
    version
    createdAt
    updatedAt
    caseStatsSlidesId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCaseSlideStatsSubscriptionVariables,
  APITypes.OnUpdateCaseSlideStatsSubscription
>;
export const onDeleteCaseSlideStats = /* GraphQL */ `subscription OnDeleteCaseSlideStats(
  $filter: ModelSubscriptionCaseSlideStatsFilterInput
  $owner: String
) {
  onDeleteCaseSlideStats(filter: $filter, owner: $owner) {
    id
    owner
    slideId
    time
    version
    createdAt
    updatedAt
    caseStatsSlidesId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCaseSlideStatsSubscriptionVariables,
  APITypes.OnDeleteCaseSlideStatsSubscription
>;
export const onCreateMeetingNPSScore = /* GraphQL */ `subscription OnCreateMeetingNPSScore(
  $filter: ModelSubscriptionMeetingNPSScoreFilterInput
  $owner: String
) {
  onCreateMeetingNPSScore(filter: $filter, owner: $owner) {
    id
    owner
    userID
    meetingID
    scores
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMeetingNPSScoreSubscriptionVariables,
  APITypes.OnCreateMeetingNPSScoreSubscription
>;
export const onUpdateMeetingNPSScore = /* GraphQL */ `subscription OnUpdateMeetingNPSScore(
  $filter: ModelSubscriptionMeetingNPSScoreFilterInput
  $owner: String
) {
  onUpdateMeetingNPSScore(filter: $filter, owner: $owner) {
    id
    owner
    userID
    meetingID
    scores
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMeetingNPSScoreSubscriptionVariables,
  APITypes.OnUpdateMeetingNPSScoreSubscription
>;
export const onDeleteMeetingNPSScore = /* GraphQL */ `subscription OnDeleteMeetingNPSScore(
  $filter: ModelSubscriptionMeetingNPSScoreFilterInput
  $owner: String
) {
  onDeleteMeetingNPSScore(filter: $filter, owner: $owner) {
    id
    owner
    userID
    meetingID
    scores
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMeetingNPSScoreSubscriptionVariables,
  APITypes.OnDeleteMeetingNPSScoreSubscription
>;
export const onCreateAccessLink = /* GraphQL */ `subscription OnCreateAccessLink(
  $filter: ModelSubscriptionAccessLinkFilterInput
  $owner: String
) {
  onCreateAccessLink(filter: $filter, owner: $owner) {
    id
    purpose
    accessCode
    data
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccessLinkSubscriptionVariables,
  APITypes.OnCreateAccessLinkSubscription
>;
export const onUpdateAccessLink = /* GraphQL */ `subscription OnUpdateAccessLink(
  $filter: ModelSubscriptionAccessLinkFilterInput
  $owner: String
) {
  onUpdateAccessLink(filter: $filter, owner: $owner) {
    id
    purpose
    accessCode
    data
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccessLinkSubscriptionVariables,
  APITypes.OnUpdateAccessLinkSubscription
>;
export const onDeleteAccessLink = /* GraphQL */ `subscription OnDeleteAccessLink(
  $filter: ModelSubscriptionAccessLinkFilterInput
  $owner: String
) {
  onDeleteAccessLink(filter: $filter, owner: $owner) {
    id
    purpose
    accessCode
    data
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccessLinkSubscriptionVariables,
  APITypes.OnDeleteAccessLinkSubscription
>;
