export const customGetCaseStats = /* GraphQL */ `
  query GetCaseStats($id: ID!, $assetViewsNextToken: String) {
    getCaseStats(id: $id) {
      caseId
      assetViews(nextToken: $assetViewsNextToken) {
        items {
          id
          assetId
        }
        nextToken
        __typename
      }
      slides {
        items {
          id
          slideId
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;

export type CustomGetCaseStatsQuery = {
  getCaseStats?: {
    __typename: 'CaseStats';
    assetViews?: {
      __typename: 'ModelCaseAssetViewConnection';
      items: Array<{
        __typename: 'CaseAssetView';
        id: string;
        assetId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    slides?: {
      __typename: 'ModelCaseSlideStatsConnection';
      items: Array<{
        __typename: 'CaseSlideStats';
        id: string;
        slideId: string;
      } | null>;
      nextToken?: string | null;
    } | null;
  } | null;
};

/*
Query made to get caseStats and their assetViews and slides
*/
export const customGetMeeting = /* GraphQL */ `
  query GetMeeting($id: ID!) {
    getMeeting(id: $id) {
      id
      name
      host {
        id
        firstname
        lastname
        email
        createdAt
        updatedAt
        last_seen
        __typename
      }
      hostFullName
      createdAt
      updatedAt
      eventDate
      eventLocation
      clientIDs
      currentCase
      currentSlide
      caseCluster {
        id
        name
        cases
        createdAt
        updatedAt
        owner
        __typename
      }
      caseStats {
        items {
          id
          owner
          meetingID
          caseId
          assetViews {
            items {
              id
              assetId
              userID
              timeWatched
            }
            nextToken
            __typename
          }
          slides {
            items {
              id
              slideId
              time
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;

export type CustomGetMeetingQuery = {
  getMeeting?: {
    __typename: 'Meeting';
    id: string;
    name: string;
    caseClusterID: string;
    host?: {
      __typename: 'User';
      id: string;
      firstname: string;
      lastname: string;
      email: string;
      createdAt?: string | null;
      updatedAt?: string | null;
      last_seen?: string | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    eventDate: string;
    eventLocation: string;
    clientIDs?: Array<string> | null;
    currentCase?: string | null;
    currentSlide?: number | null;
    caseCluster?: {
      __typename: 'CaseCluster';
      id: string;
      name: string;
      cases: Array<string>;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null;
    caseStats?: {
      __typename: 'ModelCaseStatsConnection';
      items: Array<{
        __typename: 'CaseStats';
        id: string;
        owner?: string | null;
        meetingID: string;
        caseId: string;
        assetViews?: {
          __typename: 'ModelCaseAssetViewConnection';
          items: Array<{
            __typename: 'CaseAssetView';
            id: string;
            assetId: string;
          } | null>;
          nextToken?: string | null;
        } | null;
        slides?: {
          __typename: 'ModelCaseSlideStatsConnection';
          items: Array<{
            __typename: 'CaseSlideStats';
            id: string;
            slideId: string;
            time: number;
          } | null>;
          nextToken?: string | null;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null>;
      nextToken?: string | null;
    } | null;
    isMock?: boolean | null;
  } | null;
};
