import useCaseClusterList from '@/features/caseCluster/hooks/useCaseClusterList';
import { useTranslation } from 'react-i18next';
import { CaseCluster, Meeting } from '@/services/API';
import { MeetingSelectors } from '@/store/slices/meeting';
import { useAppDispatch, useAppSelector } from '@/store/StoreHooks';
import { Column } from 'react-table';
import Table from '@/ui/Table';
import {
  Heading,
  Button,
  HStack,
  Tag,
  VStack,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  useDisclosure,
} from '@chakra-ui/react';
import CreateCaseClusterForm from '@/pages/admin/components/CreateCaseClusterForm/CreateCaseClusterForm';
import useCaseClusterSubscription from '@/services/subscriptions/useCaseClusterSubscription';
import { DeleteOutlined } from '@ant-design/icons';

import { deleteACaseCluster } from '@/store/thunk/caseCluster/deleteCaseCluster';
import useMeetingSubscription from '@/services/subscriptions/useMeetingSubscription';
import PopConfirm from '@/ui/PopConfirm/PopConfirm';

function CaseClusterListView() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useCaseClusterSubscription();

  const caseClusterList = useCaseClusterList();
  useCaseClusterSubscription();
  const meetingList: Meeting[] = useAppSelector(
    MeetingSelectors.getMeetingList
  );

  //we need the meetings to be loaded
  useMeetingSubscription();

  const {
    isOpen: isFormOpen,
    onOpen: openForm,
    onClose: closeForm,
  } = useDisclosure();

  const handleDeleteCaseCluster = (caseClusterID: string) => {
    dispatch(deleteACaseCluster(caseClusterID));
  };

  const columns: Column<CaseCluster>[] = [
    // {
    //   Header: t('common.name') as string,
    //   accessor: 'name',
    // },
    {
      Header: t('admin.caseClusters.table.casesId.columnTitle') as string,
      // accessor: (item: CaseCluster) => <div>{item.cases?.join(',')}</div>,
      accessor: (item: CaseCluster) => (
        <VStack align="start">
          <Heading size="md">{item.name}</Heading>
          <HStack align="start">
            <Text w="150px">Cases included:</Text>
            <VStack w="auto" wrap="wrap" align="start">
              {item.cases?.map((caseTitle, i) => {
                return (
                  <div key={caseTitle}>
                    {i + 1}.{'  '}
                    <Tag
                      size="sm"
                      bgColor="primary"
                      color="white"
                      pointerEvents="none"
                    >
                      {caseTitle}
                    </Tag>
                  </div>
                );
              })}
            </VStack>
          </HStack>
        </VStack>
      ),
    },
    {
      Header: 'Meetings linked',
      accessor: (item: CaseCluster) => (
        <VStack align="left">
          {meetingList
            .filter((s) => s.caseClusterID === item.id)
            .map((sess) => (
              <div key={sess.id}>
                <Tag size="xs" bgColor="none" color="blue" pointerEvents="none">
                  {sess.name}
                </Tag>
              </div>
            ))}
        </VStack>
      ),
    },
    {
      Header: t('common.createdAt') as string,
      accessor: (item: CaseCluster) =>
        item.createdAt ? new Date(item.createdAt).toLocaleString() : 'No date',
    },
    {
      Header: 'Options',
      accessor: (item: CaseCluster) => (
        <PopConfirm
          title={
            <div>
              {t('admin.caseClusters.delete.confirmText')}
              <br />
              {t('meeting.header.confirmRestart')}
            </div>
          }
          onConfirm={() => handleDeleteCaseCluster(item.id)}
        >
          <Button
            variant="danger"
            key="delete"
            size="sm"
            isDisabled={meetingList.some((s) => s.caseClusterID === item.id)}
            rightIcon={<DeleteOutlined />}
          >
            {t('common.delete')}
          </Button>
        </PopConfirm>
      ),
    },
  ];

  return (
    <>
      <HStack justifyContent="space-between" pb="12px">
        <Heading size="md">
          {t('admin.caseClusters.caseClusterList.title')}:{' '}
        </Heading>
        <Popover
          placement="bottom-start"
          isOpen={isFormOpen}
          onClose={closeForm}
        >
          <PopoverTrigger>
            <Button
              background="blue.400"
              color="white"
              size="md"
              data-test="create-meeting-button-popUp"
              onClick={openForm}
            >
              {t('admin.caseClusters.button.createNew')}
            </Button>
          </PopoverTrigger>
          <PopoverContent w="auto" padding="10" boxShadow="lg">
            <PopoverArrow />
            <PopoverBody>
              <CreateCaseClusterForm closeForm={closeForm} />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </HStack>

      {caseClusterList && (
        <Table columns={columns} data={caseClusterList} useSort={false} />
      )}
    </>
  );
}

export default CaseClusterListView;
