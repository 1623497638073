import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import { Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';

// notes on states :
// Connected - Connected and working with no issues.
// ConnectedPendingDisconnect - The connection has no active subscriptions and is disconnecting.
// ConnectedPendingKeepAlive - The connection is open, but has missed expected keep-alive messages.
// ConnectedPendingNetwork - The connection is open, but the network connection has been disrupted. When the network recovers, the connection will continue serving traffic.
// Connecting - Attempting to connect.
// ConnectionDisrupted - The connection is disrupted and the network is available.
// ConnectionDisruptedPendingNetwork - The connection is disrupted and the network connection is unavailable.
// Disconnected - Connection has no active subscriptions and is disconnecting.

export const usePubSubState = () => {
  const [state, setState] = useState<ConnectionState>();

  useEffect(() => {
    const result = Hub.listen('api', (data: any) => {
      const { payload } = data;
      if (payload.event === CONNECTION_STATE_CHANGE) {
        const connectionState = payload.data.connectionState as ConnectionState;
        console.log(connectionState);
        setState(connectionState);
      }
    });

    return () => {
      // result of Hub.listen is a method to stop listening
      result();
    };
  }, []);

  return state;
};
