import * as Sentry from '@sentry/browser';

export const CaptureSentryException = (error: any | string, detail?: any) => {
  console.error(`Sentry Error:`, error);
  if (detail) {
    console.warn(`Sentry Detail:`, detail);
    Sentry.setContext('Error Detail', detail);
  }
  Sentry.captureException(error);
};

export const CaptureSentryMessage = (
  message: string,
  detail?: any,
  type: 'warning' | 'debug' | 'error' = 'warning'
) => {
  console.warn(`Sentry Message: ${message}`);
  if (detail) {
    console.warn(`Sentry Detail:`, detail);
    Sentry.setContext('Message Detail', detail);
  }
  Sentry.captureMessage(message, type);
};
