import EmptyPageWithTitle from '@/pages/page404/EmptyPageWithTitle.tsx';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import { PageLoading } from '@/ui/PageLoading';
import useCaseContent from '@/features/caseCluster/hooks/useCaseClusterContent';
import { ParsedAssetViewsPerCase } from '../utils/parseAssetViewsPerCase';
import { ParsedSlideStatsPerCase } from '../utils/parseSlideStatsPerCase';
import { getMeetingDetails } from '../utils/getMeetingDetails';
import {
  calculateConsensusRate,
  ConsensusReturnType,
  getDefaultConsensusReturn,
} from '../utils/calculateConsensusRate';
import { useAnswersByMeetingID } from '@/features/meeting/answer/hooks/useAnswersByMeetingID';
import { useParams } from 'react-router-dom';
import { MEETING_DEFAULT_LOCATION } from '@/features/meeting/helpers/constants';
import useMeetingNPSScores from '../hooks/useMeetingNPSScores';
import useMeetingSlideStats from '../hooks/useMeetingSlideStats';
import useMeetingAssetStats from '../hooks/useMeetingAssetStats';
import useMeetingEngagementScore, {
  CaseLLXScoreDetailsRow,
} from '../hooks/useMeetingEngagementScore';
import { useUserListByMeeting } from '@/services/hooks/useUserListByMeeting';
import getUniquePresentedCases from '@/utils/helpers/getAmountOfCasesPresented';
import useMeetingStatsData from '../hooks/useMeetingStatsData';

type Props = {
  children: React.ReactNode;
};

type MeetingStatsViewContextType = {
  currentCase: string;
  setCurrentCase: Dispatch<SetStateAction<string>>;
  caseIds: string[];
  caseTitles: Record<string, string>;
  parsedAssetViews: ParsedAssetViewsPerCase | undefined;
  parsedSlideStats: ParsedSlideStatsPerCase | undefined;
  consensusRate: number;
  consensusRatePerCase: Record<string, number>;
  totalDuration: number;
  longestCaseDuration: number;
  durationPerCaseInSeconds: Record<string, number> | undefined;
  longestSlidePerCaseInSeconds: Record<string, number> | undefined;
  engagementScore: number;
  npsScore: number;
  caseIdToLLXRating: Map<string, number>;
  caseIdToLLXRatingDetails: Map<string, CaseLLXScoreDetailsRow[]>;
  meetingDate: number;
  meetingLocation: string;
  meetingHostName: string;
  uniqueCasesPresented: string[];
};

export const MeetingStatsViewContext = createContext(
  {} as MeetingStatsViewContextType
);

export default function MeetingStatsViewContextProvider({ children }: Props) {
  const [currentCase, setCurrentCase] = useState<string>('');
  const { meetingID } = useParams();
  const { npsAverage } = useMeetingNPSScores(meetingID, true);
  const userList = useUserListByMeeting(meetingID, true);

  /*
  Note: we are not using the meetingList because we would need to call useMeetingSubscription to keep
  it up to date and we don't want that because it also listens for userMeetingJoins
  It is easier to just fetch the meeting details here
  */
  const meetingDetails = useQuery({
    queryKey: ['getMeetingDetails'],
    refetchOnWindowFocus: false,
    enabled: !!meetingID,
    queryFn: async () => {
      if (!meetingID) throw new Error('No meeting in url');
      return await getMeetingDetails(meetingID);
    },
  });

  const meetingStatsData = useMeetingStatsData(meetingID, true);

  const { caseMap, isCaseLoading } = useCaseContent(
    meetingDetails.data?.caseCluster?.cases ?? []
  );

  const caseTitles = useMemo(() => {
    if (!caseMap) return {};
    return Object.keys(caseMap).reduce((acc, curr) => {
      acc[curr] = caseMap[curr].displayName;
      return acc;
    }, {} as Record<string, string>);
  }, [caseMap]);
  const caseIds = useMemo(() => {
    if (!caseMap) return [];
    return Object.keys(caseMap);
  }, [caseMap]);

  const uniqueCasesPresented = getUniquePresentedCases(
    meetingStatsData?.casePresentationHistory
  );

  const { parsedSlideStats } = useMeetingSlideStats(
    meetingID,
    meetingDetails,
    isCaseLoading,
    caseMap,
    caseIds
  );

  const { parsedAssetViews } = useMeetingAssetStats(
    meetingID,
    meetingDetails,
    isCaseLoading,
    caseMap,
    caseIds
  );

  //Note: this is in seconds
  const durationPerCaseInSeconds = useMemo(() => {
    if (caseIds.length === 0 || !parsedSlideStats) return undefined;
    // return caseIds.map((c) =>
    //   parsedSlideStats[c].reduce((acc, cur) => acc + cur.time, 0)
    // );
    return caseIds.reduce((acc, curr) => {
      const duration = parsedSlideStats[curr].reduce(
        (acc1, curr1) => acc1 + curr1.time,
        0
      );
      acc[curr] = duration;
      return acc;
    }, {} as Record<string, number>);
  }, [parsedSlideStats, caseIds]);
  const longestSlidePerCaseInSeconds = useMemo(() => {
    if (caseIds.length === 0 || !parsedSlideStats) return undefined;
    return caseIds.reduce((acc, curr) => {
      const maxSlide = parsedSlideStats[curr].reduce((max, current) => {
        return current.time > max ? current.time : max;
      }, 0);
      acc[curr] = maxSlide;
      return acc;
    }, {} as Record<string, number>);
  }, [caseIds, parsedSlideStats]);

  const { answerList } = useAnswersByMeetingID(meetingID);
  const { consensusRate, consensusRatePerCase, consensusResultsPerCase } =
    useMemo<ConsensusReturnType>(() => {
      if (caseIds.length === 0 || answerList.length === 0)
        return getDefaultConsensusReturn(caseIds);
      return calculateConsensusRate(caseIds, answerList);
    }, [caseIds, answerList]);
  const totalDuration = durationPerCaseInSeconds
    ? Math.round(
        Object.values(durationPerCaseInSeconds).reduce(
          (acc, cur) => acc + cur,
          0
        ) / 60
      )
    : 0;
  const longestCaseDuration = durationPerCaseInSeconds
    ? Math.round(Math.max(...Object.values(durationPerCaseInSeconds)) / 60)
    : 0;

  const { engagementScore, caseIdToLLXRating, caseIdToLLXRatingDetails } =
    useMeetingEngagementScore(
      caseIds,
      parsedSlideStats,
      parsedAssetViews,
      caseMap,
      isCaseLoading,
      consensusResultsPerCase,
      userList,
      uniqueCasesPresented
    );

  const meetingDate = useMemo(() => {
    if (!meetingDetails.data?.eventDate) return 0;
    return new Date(meetingDetails.data.eventDate).getTime();
  }, [meetingDetails.data?.eventDate]);

  const meetingHostName = meetingDetails.data?.hostFullName ?? '';

  if (!meetingID) {
    console.error('No meeting in url');
    return (
      <EmptyPageWithTitle
        title="Sorry..."
        subtitle="The content was not found."
      />
    );
  }

  if (meetingDetails.isError) {
    return (
      <EmptyPageWithTitle
        title="Sorry..."
        subtitle="The content was not found."
      />
    );
  }

  if (meetingDetails.isLoading || isCaseLoading) {
    console.log('MeetingStatsViewContext Loading...');
    return <PageLoading />;
  }

  return (
    <MeetingStatsViewContext.Provider
      value={{
        currentCase,
        setCurrentCase,
        caseIds,
        caseTitles,
        parsedAssetViews: parsedAssetViews,
        parsedSlideStats: parsedSlideStats,
        consensusRate,
        consensusRatePerCase,
        totalDuration,
        longestCaseDuration,
        durationPerCaseInSeconds,
        longestSlidePerCaseInSeconds,
        engagementScore,
        npsScore: npsAverage,
        caseIdToLLXRating,
        caseIdToLLXRatingDetails,
        meetingDate,
        meetingLocation:
          meetingDetails.data?.eventLocation ?? MEETING_DEFAULT_LOCATION,
        meetingHostName,
        uniqueCasesPresented,
      }}
    >
      {children}
    </MeetingStatsViewContext.Provider>
  );
}
