import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { ReactNode, useEffect, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  allowZoom?: boolean;
  content: ReactNode;
};

export const SimpleContentModal = ({
  content,
  isOpen = true,
  allowZoom = true,
  onClose,
}: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(isOpen);

  // update on props change also
  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  return (
    <Modal
      isCentered
      scrollBehavior="inside"
      motionPreset="scale"
      isOpen={modalOpen}
      onClose={() => {
        setModalOpen(false);
        onClose?.();
      }}
    >
      <ModalOverlay bgColor="blackAlpha.800" />
      <ModalContent
        bgColor="black"
        borderRadius="20px"
        maxW="90vw"
        width="85vw"
        maxH="90vh"
        height="85vh"
        boxShadow="dark-lg"
      >
        <ModalBody>
          <Center py="15px" px="15px" width="100%" height="100%">
            <TransformWrapper disabled={!allowZoom} centerOnInit={true}>
              <TransformComponent
                wrapperStyle={{ width: '100%', height: '100%' }}
              >
                {content}
              </TransformComponent>
            </TransformWrapper>
          </Center>
        </ModalBody>
        <ModalCloseButton color="white" />
      </ModalContent>
    </Modal>
  );
};
