import LiveMeetingFooter from '@/components/LiveMeetingFooter';
import { ROUTES } from '@/routes/Routes';
import { useVideoCall } from '@/features/call/useVideoCall';
import { MeetingSelectors } from '@/store/slices/meeting';
import { trackEvent } from '@/features/tracking/trackingHelpers';
import CaseExplorer from '@/pages/caseCluster/CaseExplorer';
import CaseDisclaimerView from '@/pages/caseCluster/disclaimer/CaseDisclaimerView';
import { useUserMeetingPing } from '@/services/hooks/useUserMeetingPing';
import APP_CONSTANTS from '@/utils/constants/app.constants';
import { CustomSlideIndex, MeetingErrorType, TABS } from '@/utils/types/enums';
import useBrowserLeaveCallback from '@/utils/helpers/hooks/useBrowserLeaveCallback';
import { Button, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/store/StoreHooks';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import CaseSelectorView from './CaseSelectorView';
import LiveMeetingSider from './Sider/LiveMeetingSider';
import { IsLocalHost } from '@/utils/helpers/isLocalHost';
import { leaveAMeeting } from '@/store/thunk/meeting';
import Result from '@/ui/Result/Result';
import PageLayout from '@/ui/Layout/PageLayout';
import LiveMeetingHeader from './LiveMeetingHeader';
import { PageLoading } from '@/ui/PageLoading';
import { useMeetingStatsCreationContext } from '@/features/meeting-stats/context/MeetingStatsCreationContext';
import { useUserAuthenticationContext } from '@/features/userAuth/context/UserAuthenticationContext';
import { useCurrentMeetingDataContext } from '@/features/meeting/context/CurrentMeetingDataContext';
import { isEmpty } from 'lodash';
import { CaptureSentryMessage } from '@/utils/helpers/CaptureSentryException';
import { reloadApp } from '@/utils/reloadApp';
import useMeetingParticipantsList from '@/features/meeting/hooks/useMeetingParticipantsList';
import useChatMessages from '@/features/call/useChatMessages';

/* --------------------- comp ------------------------ */

function LiveMeetingView() {
  const [currentTab, setCurrentTab] = useState(TABS.USERS);
  const { user: currentUser } = useUserAuthenticationContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const {
    // trackAnswer,
    trackAssetOpen,
    trackAssetClose,
    trackMeetingLeave,
    trackCaseClusterStop,
  } = useMeetingStatsCreationContext();

  const {
    meeting: currentMeeting,
    caseCluster,
    caseMap,
    currentCase,
    currentCaseIndex,
    isCaseLoading,
    isUserHost: isHost,
    meetingInteractions,
    isPreview,
  } = useCurrentMeetingDataContext();

  const error = useAppSelector(MeetingSelectors.getError);
  const participants = useMeetingParticipantsList(currentMeeting?.id);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  const useChatMessageUtils = useChatMessages(currentMeeting?.id);

  const userID = currentUser?.id;
  const { leaveCall: leaveVideoCall } = useVideoCall();

  const isNPSScorePage: boolean =
    currentMeeting?.currentSlide === CustomSlideIndex.NPS_SCORE || false;

  // --------------------- handlers ------------------------

  const changeTab = (newTab: TABS) => {
    // setSiderCollapsed(!siderCollapsed);
    if (currentTab !== newTab) {
      setCurrentTab(newTab);
    }
    setSiderCollapsed(false);
  };

  // handle meeting restart
  const handleMeetingRestart = () => {
    if (!currentMeeting) {
      CaptureSentryMessage('handleMeetingRestart: no current meeting');
      return;
    }
    trackEvent('meeting', 'restart');
    meetingInteractions.restartMeeting(currentMeeting.id);
  };

  const handleMeetingLeave = () => {
    leaveVideoCall();
    trackMeetingLeave();
    if (currentMeeting && currentUser) {
      dispatch(leaveAMeeting(currentUser.id, currentMeeting.id));
      // setLeaveMeeting(true);
      navigate(ROUTES.HOME, { replace: true });
    }
  };

  const closeMeetingSider = () => {
    setSiderCollapsed(true);
    // setCurrentTab(TABS.USERS);
  };

  const forceRefreshLiveMeeting = () => {
    CaptureSentryMessage('Force refresh Live Meeting', {
      currentMeeting,
      caseCluster,
      caseMap,
      currentCase,
      currentCaseIndex,
      isCaseLoading,
      isHost,
    });
    reloadApp();
  };

  // --------------------- effect ------------------------

  // ping user meeting
  useUserMeetingPing(currentUser, currentMeeting);

  // Join the meeting from parameter ID
  useEffect(() => {
    if (!currentUser) return;
    if (!location.search) {
      navigate(ROUTES.HOME, { replace: true });
      return;
    }
    const meetingID = new URLSearchParams(location.search).get('meeting');
    if (!currentMeeting || currentMeeting.id !== meetingID) {
      if (meetingID) {
        dispatch(
          meetingInteractions.joinAMeeting(currentUser.id, meetingID, false)
        );
      }
    }
    /*
    //Note here we are checking when the user's id changes and not just the user because otherwise we would be calling joinAMeeting everytime the user changes
    */
  }, [currentUser?.id, currentMeeting?.id]);

  useEffect(() => {
    if (!currentMeeting?.id || !currentUser?.id) return;
    meetingInteractions.postJoinAMeeting(currentUser?.id);
  }, [currentMeeting?.id, currentUser?.id]);

  // reset disclaimer each time we change the case
  useEffect(() => {
    setShowDisclaimer(true);

    if (currentCase && !isNPSScorePage) {
      // in case of hard reload, we do not show diclaimer again
      if (localStorage.getItem('reloaded') === 'true') {
        setShowDisclaimer(false);
        localStorage.removeItem('reloaded');
        return;
      }

      setTimeout(
        () => {
          setShowDisclaimer(false);
        },
        IsLocalHost() ? 2000 : APP_CONSTANTS.DISCLAIMER_TIMEOUT
      );
    }
  }, [currentCase]);

  // -> track leaving meeting on Browser leave
  useBrowserLeaveCallback(() => {
    leaveVideoCall();
    //NOTE: This does not work properly (but we have the ping system instead)
    // trackMeetingLeave();
  });

  // --------------------- RENDER ------------------------

  // --> Redirect to home if no meeting
  if (!new URLSearchParams(location.search).get('meeting')) {
    return <Navigate replace to={ROUTES.HOME} />;
  }

  // --> Render Loading
  if (isCaseLoading || !currentMeeting || !caseMap || isEmpty(caseMap)) {
    return <PageLoading />;
  }

  // --> Render ERROR with meeting
  if (error && error.toString().includes(MeetingErrorType.error)) {
    return (
      <>
        <Result
          type="warning"
          title={t('home.noMeetingFound')}
          extra={
            <Button
              variant="blue"
              key="console"
              onClick={() => navigate('/', { replace: true })}
            >
              {t('common.goBack')}{' '}
            </Button>
          }
        />
      </>
    );
  }

  // --------------------- RENDER ------------------------

  return (
    <PageLayout showHeader={false} showFooter={false} bgColor="white">
      {/* HEADER */}
      <LiveMeetingHeader
        currentMeeting={currentMeeting}
        currentCaseIndex={currentCaseIndex}
        currentUser={currentUser}
        isHost={isHost}
        onRestartMeeting={handleMeetingRestart}
      />

      {/* CONTENT */}
      <Flex flex="auto" direction="row" overflow="hidden">
        <>
          {userID && currentCase?.id && !isCaseLoading && (
            <>
              {currentCase.disclaimer && !!showDisclaimer && !isNPSScorePage ? (
                <CaseDisclaimerView
                  disclaimer={currentCase.disclaimer}
                  assetPath={`${APP_CONSTANTS.PATIENT_CASES_REPO_PATH}${currentCase.path}`}
                />
              ) : (
                <CaseExplorer
                  patientCaseIndex={currentCaseIndex}
                  patientCase={currentCase}
                  meeting={currentMeeting}
                  isHost={isHost}
                  // trackAnswer={trackAnswer}
                  trackAssetOpen={isPreview ? undefined : trackAssetOpen}
                  trackAssetClose={isPreview ? undefined : trackAssetClose}
                  meetingRightSideBarProps={{
                    onParticipantsClick: () => {
                      if (currentTab === TABS.USERS && !siderCollapsed) {
                        closeMeetingSider();
                        return;
                      }
                      changeTab(TABS.USERS);
                    },
                    onChatClick: () => {
                      if (currentTab === TABS.CHAT && !siderCollapsed) {
                        closeMeetingSider();
                        return;
                      }
                      changeTab(TABS.CHAT);
                      useChatMessageUtils.markAllMessagesAsRead();
                    },
                    onVideoClick: () => {
                      alert('video click');
                    },
                    amtUnreadMessages: useChatMessageUtils.unreadMessages,
                  }}
                />
              )}
            </>
          )}

          {!currentCase && caseCluster && (
            <CaseSelectorView
              isHost={isHost}
              caseIDList={caseCluster.cases}
              caseMap={caseMap}
              onCaseSelected={(caseID) => {
                if (isHost) {
                  meetingInteractions.updateCurrentCase(
                    currentMeeting.id,
                    caseID
                  );
                }
              }}
            />
          )}
        </>

        <LiveMeetingSider
          currentTab={currentTab}
          siderCollapsed={siderCollapsed}
          closeMeetingSider={closeMeetingSider}
          useChatMessageUtils={useChatMessageUtils}
        />
      </Flex>

      {/* MENU BAR / FOOTER */}

      <LiveMeetingFooter
        isHost={isHost}
        isAllowedToNavigate={
          (!currentCase?.disclaimer || !showDisclaimer) && !isNPSScorePage
        }
        leaveWarningTitle={
          isHost && !isNPSScorePage
            ? t('meeting.leave.warn.host.title')
            : t('meeting.leave.warn.title')
        }
        onLeaveMeeting={() => {
          // CASE GO TO NPS
          if (
            isHost &&
            participants.length > 1 &&
            currentMeeting.currentSlide !== CustomSlideIndex.NPS_SCORE
          ) {
            trackCaseClusterStop();
            meetingInteractions.showNPSScoreView(
              currentMeeting,
              caseCluster?.cases?.[0] || '0'
            );
          } else {
            handleMeetingLeave();
          }
        }}
        onRefresh={forceRefreshLiveMeeting}
      />
    </PageLayout>
  );
}

export default LiveMeetingView;
